import React, { useState } from 'react'
import { Button, Forbidden, Loader, Page, toastMessage } from '../../components'
import mainStyles from '../../assets/css/App.module.scss'
import { FormComponent, FormUtil } from '../../shared'
import { EmailUtil } from '../../shared/email'
import styles from '../Account/Account.module.scss'

export const EmailFix = () => {
	const [search, setSearch] = useState('')
	const [account, setAccount] = useState(null)
	const [loading, setLoading] = useState(false)
	const [saving, setSaving] = useState(false)

	const emailUtil = new EmailUtil()

	const submit = async () => {
		setLoading(true)

		if (!search.length || !FormUtil.isEmail(search)) {
			toastMessage('Please, type a valid email.', 'warning')
			setLoading(false)
			return
		}

		const { status, data } = await emailUtil.getEmailFix(
			encodeURIComponent(search)
		)

		if (status !== 200) {
			toastMessage('Email not found', 'error')
			setLoading(false)
			return
		}

		setAccount({
			id: data.id,
			fix: data.fix,
			valid: data.valid
		})

		setLoading(false)
	}

	const save = async () => {
		setSaving(true)

		if (account.fix && !FormUtil.isEmail(account.fix)) {
			toastMessage('Please, type a valid email.', 'warning')
			setSaving(false)
			return
		}

		setSaving(true)

		// Send request.
		const { status } = await emailUtil.saveEmailFix({
			id: account.id,
			email: search,
			fix: account.fix || null
		})

		if (status !== 200) {
			toastMessage('Failed to save email', 'error')
			setSaving(false)
			return
		}

		toastMessage('Fix successfully applied', 'success')

		resetStates()
	}

	const resetStates = () => {
		setSearch('')
		setAccount(null)
		setLoading(false)
		setSaving(false)
	}

	const mailForm = () => {
		if (!account) {
			return
		}

		const opt = {
			fixedMail: {
				label: 'Email Fix',
				props: {
					type: 'email',
					value: account.fix || '',
					placeholder: 'Type the fixed email...',
					onChange: ({ target }) => {
						setAccount({
							...account,
							fix: target.value
						})
					}
				}
			},
			save: {
				label: 'Save',
				props: {
					type: 'button',
					onClick: save.bind(this)
				}
			}
		}

		const form = new FormComponent(opt)

		return (
			<>
				{form.renderInput('fixedMail')}
				{form.renderButton('save', { loading: saving })}
			</>
		)
	}

	const opt = {
		search: {
			label: 'Original Email',
			props: {
				type: 'email',
				placeholder: 'Type the full email...',
				onChange: ({ target }) => {
					setAccount(null)
					setSearch(target.value)
				},
				value: search,
				disabled: account !== null
			}
		},
		submit: {
			label: 'Search',
			props: {
				type: 'button',
				onClick: submit.bind(this)
			}
		}
	}

	const renderValidLabel = () => {
		let text = account?.valid
			? 'This email is valid and exists'
			: 'This email was found as invalid or nonexistent'

		return (
			<p
				className={
					account?.valid ? styles.validLabel : styles.accountExpiredLabel
				}
			>
				{text}
			</p>
		)
	}

	const form = new FormComponent(opt)

	return (
		<>
			<Page
				render={({ authData }) => {
					if (!authData.account.permissions?.includes('emailFix')) {
						return <Forbidden />
					}

					return (
						<div className={mainStyles.container}>
							<div className={mainStyles.wrapper}>
								<h3>
									Email Fix
									{account && (
										<span>
											<Button
												onClick={() => resetStates()}
												size="small"
												to="/email/fix"
											>
												Search again
											</Button>
										</span>
									)}
								</h3>
								<div className={styles.headingWrapper}>
									<div className={styles.leftSide}>
										{form.renderInput('search')}
										{account && mailForm()}
										{!account &&
											form.renderButton('submit', {
												loading: loading
											})}
									</div>
									<div className={styles.rightSide}>
										{account && renderValidLabel()}
									</div>
								</div>
								<Loader display={loading || saving} />
							</div>
						</div>
					)
				}}
			></Page>
		</>
	)
}
