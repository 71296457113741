import { RequestUtil } from './'

export class EmailUtil {
	getEmailFix(email) {
		return RequestUtil.get(`/email/${email}`, {})
	}

	saveEmailFix(data) {
		return RequestUtil.post('/email/fix', data)
	}
}
