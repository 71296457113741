import configs from "../config.json"
import Http from "./http";

export class PoliAccountUtil {

    constructor(id = null) {
        this.http = new Http(configs.poliBaseUrl, {
            'Auth-Token': configs.poliAuthToken
        });

        if (id) {
            this.accountId = id;
        }
    }

    /**
     * Get account id
     * 
     * @returns {Number}
     */
    get id() {
        return this.accountId;
    }

    /**
     * Create new Poli account.
     * 
     * @param {Object} data - Account data
     * @returns {Object} The created account
     */
    async create(data) {
        try {
            const res = await this.http.post('/user', data);

            return res.data || {};
        } catch (e) {
            return {};
        }
    }

    /**
     * Get list of Poli accounts.
     * 
     * @param {Object} params - Request params
     * @param {Object} settings - Request settings
     * @returns {Object} List of accounts
     */
    async list(params = {}) {
        try {
            const res = await this.http.get('/users', params);

            return res.data || {};
        } catch (e) {
            return {};
        }        
    }

    /**
     * Get a Poli Account by ID.
     * 
     * @param {string} id - Account ID
     * @returns 
     */
    async get(id) {
        try {
            const res = await this.http.get(`/users/${id}`);

            return res.data || {};
        } catch (e) {
            return false;
        }
    }
}

export default PoliAccountUtil;