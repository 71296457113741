import React from 'react'
import { FormUtil, ObjectUtil, StringUtil, NumberUtil } from '../../shared'
import styles from './Input.module.scss'

export class Input extends React.Component {
	constructor(props) {
		super()

		this.state = {
			maskPhone: '',
			isFieldBlurred: false,
			value: props.initialValue || '',
			error: null,
			isLabelUp: false,
			isFieldFocus: false,
			selectedCountryCodeIndex: null
		}
		this._handleError = this._handleError.bind(this)
		this._handleInputChange = this._handleInputChange.bind(this)
		this._handleIsFieldBlurred = this._handleIsFieldBlurred.bind(this)
		this._handleMaskPhone = this._handleMaskPhone.bind(this)
		this._handleValue = this._handleValue.bind(this)
		this._onBlur = this._onBlur.bind(this)
		this._onClick = this._onClick.bind(this)
		this._handleKeyPress = this._handleKeyPress.bind(this)
		this._inputRef = React.createRef()
	}

	_handleValue(value, cb) {
		this.setState({ value }, cb)
	}

	_handleError(error) {
		this.setState({ error })
	}

	_handleMaskPhone(maskPhone) {
		this.setState({ maskPhone })
	}

	_handleIsFieldBlurred(isFieldBlurred) {
		this.setState({ isFieldBlurred })
	}

	_handleIsFieldFocus(isFieldFocus) {
		this.setState({ isFieldFocus })
	}

	_handleIsLabelUp(isLabelUp) {
		const { value, error } = this.state
		const { isFormSubmitted } = this.props

		if (!value && isFormSubmitted && error) this.setState({ isLabelUp })
		if (!isLabelUp && (value || (isFormSubmitted && error))) return

		this.setState({ isLabelUp })
	}

	_handleKeyPress(e) {
		const {
			target: { value }
		} = e

		if (e.key === 'Enter') this._validate(value)

		if (this.props.onKeyPress)
			this.props.onKeyPress(e, this.state.rawValue, this)
	}

	_onBlur(e) {
		const { isFieldBlurred } = this.state
		const { target } = e
		const { value } = target
		const { onBlur } = this.props
		const isValid = this._validate(value)

		if (!isFieldBlurred) {
			this._handleIsFieldBlurred(true)
		}

		if (isValid && ObjectUtil.isFunction(onBlur)) {
			onBlur(value)
		}

		target.blur()
		this._handleIsFieldFocus(false)
		this._handleIsLabelUp(false)
	}

	_onClick(e) {
		e.preventDefault()

		this._handleIsLabelUp(true)
		this._handleIsFieldFocus(true)
	}

	_validate(value = null) {
		const { onFieldValidate, name = '' } = this.props
		let isFieldValid = false
		let errorMessage = null

		if (this.state.error !== null) {
			this._handleError(errorMessage)
		}

		try {
			this._validateField(value)
			isFieldValid = true
		} catch (ex) {
			errorMessage = ex.message
			this._handleError(errorMessage)
		}

		if (ObjectUtil.isFunction(onFieldValidate)) {
			onFieldValidate(isFieldValid, name, errorMessage)
		}

		return isFieldValid
	}

	_validateField(fieldValue) {
		const { messageOnError = '', category = '' } = this.props

		FormUtil.commonFieldsValidations(this.props, fieldValue)

		if (category === 'email' && fieldValue && !FormUtil.isEmail(fieldValue)) {
			throw new Error('Provide a valid email')
		}

		if (category === 'tel' && fieldValue && !FormUtil.isPhone(fieldValue)) {
			throw new Error(messageOnError)
		}

		if (
			category === 'zipCode' &&
			fieldValue &&
			!FormUtil.isZipCode(fieldValue)
		) {
			throw new Error(messageOnError)
		}

		if (
			category === 'currency' &&
			fieldValue &&
			!FormUtil.isCurrency(fieldValue)
		) {
			throw new Error(messageOnError)
		}

		if (
			category === 'mobileNumber' &&
			fieldValue &&
			!FormUtil.isMobileNumber(fieldValue)
		) {
			throw new Error(messageOnError)
		}

		if (category === 'number' && fieldValue && !FormUtil.isNumber(fieldValue)) {
			throw new Error(messageOnError)
		}

		if (category === 'date' && fieldValue && !FormUtil.isDate(fieldValue)) {
			throw new Error(messageOnError)
		}
	}

	_handleInputChange(event) {
		let { value, name } = event.target

		this._onInputChange(value, name)
	}

	_onInputChange(value) {
		const { isFieldFocus } = this.state
		const { category } = this.props
		let rawValue

		if (category === 'tel') {
			value = FormUtil.toPhone(value)
		} else if (category === 'zipCode') {
			value = FormUtil.toZipCode(value)
		} else if (category === 'confirmationCode') {
			value = FormUtil.toConfirmationCode(value)
		} else if (category === 'mobileNumber') {
			value = FormUtil.toMobileNumber(
				value,
				this.getCurrentCountryCodeData().value
			)
			rawValue = value.replace(/[^\d]/g, '')
			this.setState({
				rawValue: rawValue
			})
		} else if (category === 'cpf') {
			value = FormUtil.toCpf(value)
		} else if (category === 'currency') {
			value = FormUtil.toCurrency(value, this.props.currency.currency)
			rawValue = FormUtil.reverseCurrencyFormat(
				value,
				this.props.currency.currency
			)
			this.setState({
				rawValue: rawValue
			})
		}

		if (category !== 'currency' && category !== 'mobileNumber') rawValue = value

		if (this.props.onChange) {
			this.props.onChange(rawValue, value, this)
		}

		this._handleValue(value, () =>
			this._handleIsLabelUp(isFieldFocus || !!value)
		)
	}

	getCurrentCountryCodeData() {
		return this.state.selectedCountryCodeIndex !== null
			? Object.entries(this.props.countryCodeList)[
					this.state.selectedCountryCodeIndex
			  ][1]
			: null
	}

	_handleCountryCodeChange() {
		this._inputRef.current.focus()

		if (this.props.onCountryCodeChange)
			this.props.onCountryCodeChange(this.getCurrentCountryCodeData())
	}

	_renderLabel() {
		let error = FormUtil.getFieldError(this)
		let data = Object.assign({}, this.props)
		let { id = '', label = '' } = data
		const { isLabelUp } = this.state

		return (
			<label
				htmlFor={id}
				className={`${styles.lbl} ${error ? styles.error : ''} ${
					isLabelUp ? styles.lblUp : ''
				} clear`}
			>
				{label}
			</label>
		)
	}

	_parseSelectedCountryCodeIndex() {
		let selectedCountryCodeIndex = null

		if (
			this.props.category === 'mobileNumber' &&
			this.props.countryCodeList &&
			this.props.countryCodeList.length
		) {
			if (this.state.selectedCountryCodeIndex !== null)
				selectedCountryCodeIndex = this.state.selectedCountryCodeIndex

			if (selectedCountryCodeIndex === null) {
				for (let [key, option] of Object.entries(this.props.countryCodeList)) {
					if (option.selected === true) {
						selectedCountryCodeIndex = parseInt(key)
						break
					}
				}
			}

			if (selectedCountryCodeIndex === null) selectedCountryCodeIndex = 0

			return selectedCountryCodeIndex
		}

		return null
	}

	_renderInput() {
		let error = FormUtil.getFieldError(this)
		let data = Object.assign({}, this.props)
		let { type = 'text', isLoading } = data
		let countryCodeListElem = null
		let countryCodeListSelectElem = null
		let selectedCountryCodeObj = null
		let currencyElem = null
		let value = this.state.value
		const attrs = { ...data }

		FormUtil.clearDataInput(data)

		delete attrs['initialValue']
		delete attrs['countryCodeList']
		delete attrs['currency']
		delete attrs['category']
		delete attrs['onChange']
		delete attrs['onKeyPress']
		delete attrs['requiredTagging']
		delete attrs['onCountryCodeChange']

		const className = FormUtil.isHidden(type)
			? ''
			: `${styles.input} ${error ? styles.error : ''} ${
					isLoading ? styles.loading : ''
			  }`

		if (
			data.category === 'mobileNumber' &&
			data.countryCodeList &&
			data.countryCodeList.length &&
			this.state.selectedCountryCodeIndex !== null
		) {
			selectedCountryCodeObj = this.getCurrentCountryCodeData()
			value = FormUtil.toMobileNumber(
				this.state.value,
				selectedCountryCodeObj.value
			)

			countryCodeListSelectElem = (
				<select
					tabIndex="-1"
					onChange={e => {
						this.setState(
							{
								selectedCountryCodeIndex: e.target.selectedIndex
							},
							() => {
								this._handleCountryCodeChange()
							}
						)
					}}
					defaultValue={parseInt(selectedCountryCodeObj.value)}
				>
					{data.countryCodeList.map((item, key) => {
						return (
							<option key={key} value={parseInt(item.value)}>
								{item.description
									? item.description
									: item.label
									? item.label
									: item.value}
							</option>
						)
					})}
				</select>
			)

			countryCodeListElem = (
				<ul>
					<li>
						{selectedCountryCodeObj.image ? (
							<img
								src={selectedCountryCodeObj.image}
								alt={
									selectedCountryCodeObj.label
										? selectedCountryCodeObj.label
										: selectedCountryCodeObj.value
								}
							/>
						) : null}
						+
						{selectedCountryCodeObj.label
							? selectedCountryCodeObj.label
							: selectedCountryCodeObj.value}
					</li>
				</ul>
			)
		}

		if (data.category === 'currency' && data.currency) {
			currencyElem = (
				<div className={styles.currencyWrapper}>
					<img src={data.currency.image} alt={data.currency.currency} />
					{data.currency.currency}
				</div>
			)
		}

		return (
			<div className={styles.innerWrapper}>
				{countryCodeListSelectElem}
				{countryCodeListElem}
				<input
					ref={this._inputRef}
					type={type}
					className={className}
					onClick={this._onClick}
					onFocus={this._onClick}
					onChange={this._handleInputChange}
					onBlur={this._onBlur}
					value={value}
					onKeyPress={this._handleKeyPress}
					{...attrs}
				/>
				{currencyElem}
			</div>
		)
	}

	_renderMessage() {
		const { isFieldFocus } = this.state
		const { activeMessage = '', inactiveMessage = '' } = this.props
		const error = FormUtil.getFieldError(this)
		let message

		if (error) {
			message = error
		} else if (isFieldFocus) {
			message = activeMessage
		} else {
			message = inactiveMessage
		}

		return (
			<span className={`${styles.message} ${error ? styles.error : ''}`}>
				{message}
			</span>
		)
	}

	_renderChildren() {
		const { value } = this.state
		const { children = [], name, submitEventName } = this.props
		return React.Children.map(children, child =>
			React.cloneElement(child, {
				value,
				submitEventName,
				handleInput: v => this._onInputChange(v, name)
			})
		)
	}

	UNSAFE_componentWillMount() {
		const { defaultValue: value, name } = this.props
		const isStringOrNumber =
			StringUtil.isString(value) || NumberUtil.isNumber(value)

		if (isStringOrNumber) {
			this._onInputChange(value, name)
		}
	}

	componentDidMount() {
		const { onComponentMount, name } = this.props
		const { value } = this.state

		this._validate(value)

		if (ObjectUtil.isFunction(onComponentMount)) {
			onComponentMount(name, value)
		}

		this.setState({
			selectedCountryCodeIndex: this._parseSelectedCountryCodeIndex()
		})
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const fieldNameError = 'error'
		const value = FormUtil.parseValueFromProps(this.props, nextProps)
		const error = FormUtil.parseAttributeFromProps(
			this.props,
			nextProps,
			fieldNameError
		)
		const { name, onComponentMount, onFieldValidate } = this.props

		if (value !== null) {
			this._validate(value)
			this._onInputChange(value, name)
			onComponentMount(name, value)
		}

		if (error !== undefined) {
			this._handleError(error)
			onComponentMount(fieldNameError, error)
			onFieldValidate(true, name, error)
		}
	}

	UNSAFE_componentWillUnmount() {
		const { onComponentUnmount, name } = this.props

		if (ObjectUtil.isFunction(onComponentUnmount)) {
			onComponentUnmount(name)
		}
	}

	getValue() {
		return this.state.value
	}

	render() {
		const data = Object.assign({}, this.props)
		const { type = {} } = data
		let classes = [styles.inputWrapper]

		if (this.state.error) classes.push(styles.error)

		if (this.props.required) classes.push(styles.required)

		if (data.classes) classes = [...classes, this.props.classes]

		if (data.requiredTagging === false) classes.push(styles.noTagging)

		if (FormUtil.isHidden(type)) return this._renderInput()

		if (this.state.isFieldFocus) classes.push(styles.focus)

		classes.push(styles[data.category])

		return (
			<div>
				<div className={classes.join(' ')}>
					{this._renderLabel()}
					{this._renderInput()}
					{this._renderChildren()}
				</div>
				{this._renderMessage()}
			</div>
		)
	}
}
