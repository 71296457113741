import React, { useEffect, useState } from 'react'
import { isPhoneValid, getPhoneNumberFragments } from '../../../shared/form'
import { Button, toastMessage } from '../../../components'
import styles from '../Links.module.scss'
import { useLeadState } from '../../../contexts/lead'
import { useLinkState } from '../../../contexts/link'
import { replaceKeys } from '../../../shared/object'
import { DeviceUtil } from '../../../shared/device'
import { copyStringToClipboard } from '../utils'
import { useShortLinkState } from '../../../contexts/short-link'
import { WhatsAppNumberInput } from '../WhatsAppNumberInput'

const deviceUtil = new DeviceUtil()

export const PersonalizeLinkForm = ({ modalControls, source, pageState }) => {
	const leadState = useLeadState()
	const linkState = useLinkState()

	const shortLinkState = useShortLinkState()
	const [isLoading, setIsLoading] = useState(false)
	const [shortLink, setShortLink] = useState()
	const url = linkState.data.link.urls.find(
		url => url.nodeId === source.nodeId && url.trafficSourceId === null
	)
	const node = linkState.data.link.nodes.find(node => node.id === source.nodeId)

	function copyLink() {
		copyStringToClipboard(
			shortLink,
			source.mustBePersonalized
				? 'Personalized link copied!'
				: 'Group link copied!'
		)
		modalControls.closeModal()
	}

	function onInputKeyUp(e) {
		if (e.keyCode === 13 && isPhoneValid(linkState.data?.mobileNumber))
			personalizeLink()
	}

	async function personalizeLink() {
		setIsLoading(true)
		const link = new URL(url.url)
		const linkParams = new URLSearchParams(link.search)

		linkParams.set('channel', source.channel)

		if (source.params) {
			for (const [key, value] of Object.entries(source.params)) {
				linkParams.set(
					key,
					node.type === 'app-launcher' ? JSON.stringify(value) : value
				)
			}
		}

		if (source.mustBePersonalized) {
			linkParams.set(
				'source',
				`${pageState.authData.account.id}-${source.source}`
			)

			const fragments = getPhoneNumberFragments(linkState.data.mobileNumber)
			const { data: leadInfo } = await leadState.instance
				.getInfo({
					mobileNumber: fragments.international
				})
				.catch(() => null)

			if (leadInfo) {
				const trackingParamNamesReplacementMap = {
					name: 'n',
					email: 'e',
					hid: 'flux_hid'
				}

				const userInfo = replaceKeys(trackingParamNamesReplacementMap, leadInfo)

				for (const [, key] of Object.entries(
					trackingParamNamesReplacementMap
				)) {
					if (userInfo[key] !== null) {
						linkParams.set(key, userInfo[key])
					}
				}

				linkParams.set('mn', fragments.international)
			}
		} else {
			linkParams.set('source', source.source)
		}

		const shortLinkData = await shortLinkState.instance
			.shorten({
				url: `${link.protocol}//${link.host}${
					link.pathname
				}?${linkParams.toString()}`,
				domain: node.domain
			})
			.catch(e => {
				console.log('Error shortening link', e)
				return false
			})

		if (shortLinkData) {
			setShortLink(shortLinkData.shortLink)
		} else {
			toastMessage('Fail trying to create short link', 'error')
		}
	}

	useEffect(() => {
		if (!shortLink || deviceUtil.isMobile()) return
		copyLink()
	}, [shortLink])

	useEffect(() => {
		if (!url || source.mustBePersonalized) return
		setTimeout(personalizeLink, 800)
	}, [])

	return (
		<>
			<h4>
				{source.mustBePersonalized
					? 'WhatsApp Personalized Link'
					: 'WhatsApp Group Link'}
			</h4>
			<p className={styles.selectedSource}>{source.source}</p>

			{url && !source.mustBePersonalized && !shortLink ? (
				<p>Processing, please wait...</p>
			) : null}

			{url ? (
				<>
					{source.mustBePersonalized ? (
						<>
							<WhatsAppNumberInput focusOnLoad onKeyUp={onInputKeyUp} />
							<p style={{ fontSize: 12, marginTop: -20, marginBottom: 20 }}>
								<strong>International numbers</strong>
								<br />
								<span className={styles.mandatory}>
									For international numbers it's mandatory to use the country
									code preceded by a{' '}
									<span className={styles.phoneNumber}>
										<strong>+</strong>
									</span>{' '}
									sign.
								</span>{' '}
								Example:{' '}
								<span
									className={[styles.phoneNumber, styles.mandatory].join(' ')}
								>
									+356
								</span>{' '}
								<span className={styles.phoneNumber}>XXXX-XXXX</span>
								<br />
								<br />
								<strong>Brazilian numbers</strong>
								<br />
								The country code (+55) is optional for Brazilian numbers. So you
								can enter just{' '}
								<span className={styles.phoneNumber}>area code</span> +{' '}
								<span className={styles.phoneNumber}>mobile number</span>. Valid
								examples:{' '}
								<span className={[styles.phoneNumber, styles.info].join(' ')}>
									(XX)
								</span>{' '}
								<span className={[styles.phoneNumber, styles.info].join(' ')}>
									00000-0000
								</span>{' '}
								or{' '}
								<span className={[styles.phoneNumber, styles.info].join(' ')}>
									+55
								</span>{' '}
								<span className={[styles.phoneNumber, styles.info].join(' ')}>
									(XX)
								</span>{' '}
								<span className={[styles.phoneNumber, styles.info].join(' ')}>
									00000-0000
								</span>
							</p>
						</>
					) : null}

					{!shortLink && source.mustBePersonalized ? (
						<Button
							disabled={
								isLoading ||
								!isPhoneValid(linkState.data?.mobileNumber) ||
								!source.mustBePersonalized
							}
							loading={isLoading}
							onClick={personalizeLink}
						>
							{deviceUtil.isMobile() ? 'Get Link' : 'Copy link'}
						</Button>
					) : null}

					{shortLink ? (
						<>
							<button onClick={copyLink} className={styles.shortLink}>
								{shortLink}
							</button>
							<p style={{ fontSize: 15 }}>☝️ Click the link above to copy it</p>
						</>
					) : null}
				</>
			) : null}

			{!url ? (
				<p className={styles.error}>No organic tracking link found.</p>
			) : null}
		</>
	)
}
