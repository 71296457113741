import React, { useEffect } from 'react'
import { CrmSegments } from './CrmSegments'
import { useState } from 'react'
import { Forbidden, Loader, toastMessage } from '../../components'
import { CrmEvents } from './CrmEvents'
import mainStyles from '../../assets/css/App.module.scss'
import styles from '../Crm/Crm.module.scss'
import { LeadUtil } from '../../shared/lead'
import { FormUtil } from '../../shared'

function StatusTd(props) {
	const classes = [styles.statusLight]

	const status = [
		{ text: 'waiting', class: styles.inactive },
		{ text: 'processing', class: styles.processing },
		{ text: 'failed', class: styles.failed }
	]

	const found = status.find(item => item.text === props.status)
	if (found) {
		classes.push(found.class)
	}

	return <span className={classes.join(' ')}>{props.children}</span>
}

export const CrmBatch = ({ forbidden }) => {
	const [selectedSegment, setSelectedSegment] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [batchList, setBatchList] = useState(null)

	const leadUtil = new LeadUtil()

	const onChangeSegment = segment => {
		setSelectedSegment(segment)
	}

	const fetchBatches = () => {
		leadUtil
			.getBatches()
			.then(({ status, data }) => {
				if (status !== 200) {
					toastMessage('Something went wrong...', 'error')
					return
				}

				setBatchList(data.results)
			})
			.catch(() => {
				toastMessage('Something went wrong...', 'error')
			})
	}

	const onEventTrigger = () => {
		fetchBatches()

		setSelectedSegment(null)
	}

	useEffect(() => {
		fetchBatches()
	}, [])

	if (forbidden) {
		return <Forbidden />
	}

	return (
		<>
			<CrmSegments
				segmentQueryParams={{ pattern: 'crm_auto_%_%_%' }}
				onChangeSegment={onChangeSegment}
				selectedSegment={selectedSegment}
				setIsLoading={setIsLoading}
			/>
			{selectedSegment && (
				<CrmEvents
					queryParams={{ pattern: 'crm_%_lead' }}
					subject={selectedSegment.label}
					isBatch={true}
					onEventTriggerCallback={onEventTrigger}
				/>
			)}

			{batchList && batchList?.length ? (
				<table style={{ tableLayout: 'fixed', marginTop: '3rem' }}>
					<thead>
						<tr>
							<td>Segment</td>
							<td>Metadata</td>
							<td>Response</td>
							<td style={{ width: '7rem' }}>Created At</td>
							<td style={{ width: '7rem' }}>Updated At</td>
						</tr>
					</thead>
					<tbody className={mainStyles.smallFont}>
						{batchList?.map(item => {
							return (
								<tr key={item.lead_events_batch_id}>
									<td className={mainStyles.overflow}>
										<StatusTd status={item.status}></StatusTd>
										{item.segment_name}
									</td>
									<td className={mainStyles.overflow}>{item.metadata}</td>
									<td className={mainStyles.noBreak}>
										{item.response_metadata ?? (
											<div style={{ textAlign: 'center' }}>&mdash;</div>
										)}
									</td>
									<td className={mainStyles.noBreak}>
										{item.created_at ? (
											FormUtil.formatDate(new Date(item.created_at))
										) : (
											<div style={{ textAlign: 'center' }}>&mdash;</div>
										)}
									</td>
									<td className={mainStyles.noBreak}>
										{item.updated_at ? (
											FormUtil.formatDate(new Date(item.updated_at))
										) : (
											<div style={{ textAlign: 'center' }}>&mdash;</div>
										)}
									</td>
								</tr>
							)
						})}
						<tr />
					</tbody>
				</table>
			) : null}
			<Loader display={isLoading}></Loader>
		</>
	)
}
