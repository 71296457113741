import { useReportDispatch, useReportState } from '../../contexts/report'
import { FormComponent } from '../../shared'
import { useLinkState } from '../../contexts/link'

export const TrafficSourceInput = () => {
	const reportDispatch = useReportDispatch()
	const reportState = useReportState()
	const linkState = useLinkState()

	const allowedTokens = ['fbads', 'gads', 'ytads']

	const options = linkState.data.link.trafficSources
		?.filter(item => allowedTokens.includes(item.token))
		.map(ts => ({
			label: ts.name,
			value: ts.token
		}))

	const form = new FormComponent({
		source: {
			label: 'Traffic Source',
			props: {
				type: 'select',
				value:
					options.find(
						option =>
							option.value === reportState.data.selectedTrafficSourceToken
					) ?? null,
				onChange: ({ value }) => {
					if (value !== reportState.data.selectedTrafficSourceToken) {
						reportDispatch({
							type: 'update',
							payload: {
								data: {
									selectedTrafficSourceToken: value,
									selectedTrafficSourceAccount: null,
									selectedFunnel: null,
									selectedProduct: null,
									campaignFilter: null
								}
							}
						})
					}
				}
			},
			options
		}
	})

	return form.renderInput('source')
}
