import React from 'react'
import { useLinkDispatch, useLinkState } from '../../contexts/link'
import { FormComponent } from '../../shared'
import { cloneDeep, merge } from 'lodash'

export const TrafficSourceTrackingFieldInputs = () => {
	const linkState = useLinkState()
	const linkDispatch = useLinkDispatch()

	if (!linkState.data?.trafficSource?.trackingFields) return null

	return Object.keys(linkState.data.trafficSource.trackingFields).map(key => {
		const trackingField = linkState.data.trafficSource.trackingFields[key]

		if (trackingField.hidden) return null

		const form = new FormComponent({
			[key]: {
				label: `${trackingField.label}=`,
				required: true,
				valid: () => true,
				props: {
					placeholder: trackingField.placeholder ?? '',
					onBlur: e => trackingField.onBlur?.bind(e),
					onChange: e => {
						linkDispatch({
							type: 'update',
							payload: {
								data: merge(cloneDeep(linkState.data), {
									trackingFields: {
										[linkState.data.trafficSource.value]: {
											[key]: e.target.value
										}
									}
								})
							}
						})
					},
					value:
						linkState.data.trackingFields?.[
							linkState.data.trafficSource.value
						]?.[key] ?? linkState.data.trafficSource.trackingFields[key].value,
					type: 'text'
				}
			}
		})

		return <div key={key}>{form.renderInput(key)}</div>
	})
}
