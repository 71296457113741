import React from 'react'
import { isEqual } from 'lodash'
import { LeadUtil } from '../shared/lead'

const LeadStateContext = React.createContext()
const LeadDispatchContext = React.createContext()
const leadService = new LeadUtil()

function initState(dispatch, state) {
	if (state?.isInitialized) return

	dispatch({
		type: 'init',
		payload: {
			isInitialized: true,
			instance: leadService
		}
	})
}

function leadReducer(state, action) {
	const newState =
		action.type === 'init'
			? action.payload
			: {
					...state,
					data: {
						...state.data,
						...action.payload.data
					}
			  }

	if (isEqual(state, newState)) return state

	switch (action.type) {
		case 'init':
		case 'update':
			return newState

		default:
			throw new Error(`Unhandled action type: ${action.type}`)
	}
}

function LeadProvider({ children }) {
	const [state, dispatch] = React.useReducer(leadReducer, {})
	return (
		<LeadStateContext.Provider value={state}>
			<LeadDispatchContext.Provider value={dispatch}>
				{children}
			</LeadDispatchContext.Provider>
		</LeadStateContext.Provider>
	)
}

function useLeadState() {
	const context = React.useContext(LeadStateContext)
	if (context === undefined) {
		throw new Error('useLeadState must be used within a LeadProvider')
	}
	return context
}

function useLeadDispatch() {
	const context = React.useContext(LeadDispatchContext)
	if (context === undefined) {
		throw new Error('useLeadDispatch must be used within a LeadProvider')
	}
	return context
}

export { LeadProvider, useLeadState, useLeadDispatch, initState }
