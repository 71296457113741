import React, { useState } from 'react'
import { Button } from '../../../components'
import { useLinkDispatch, useLinkState } from '../../../contexts/link'
import { toastMessage } from '../../../components'
import { FormComponent } from '../../../shared'
import { isValidCheckoutUrl, isValidSource } from '../utils'
import { OrganicSourceInput } from '../OrganicSourceInput'
import { CheckoutUrlInput } from '../CheckoutUrlInput'
import { AppLaunchTargetInput } from '../AppLaunchTargetInput'
import { AppLaunchActionInput } from '../AppLaunchActionInput'

export const SourceForm = ({ modalControls, funnel }) => {
	const linkState = useLinkState()
	const linkDispatch = useLinkDispatch()
	const [node, setNode] = useState()
	const [accounts, setAccounts] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const productName = linkState.data.link.products.find(
		product => product.id === funnel.productId
	).name
	const accountOptions = linkState.data.link.accounts.map(account => ({
		label: account.name,
		value: account.id
	}))
	const nodeOptions = linkState.data.link.nodes
		.filter(node => node.funnelId === funnel.id && node.status === 'active')
		.map(node => ({
			label: node.name,
			value: node.id
		}))
	const form = new FormComponent({
		node: {
			label: 'Node',
			props: {
				type: 'select',
				value: nodeOptions.find(n => n.value === node?.id),
				onChange: option => {
					setNode(
						linkState.data.link.nodes.find(node => node.id === option.value)
					)
				}
			},
			options: nodeOptions
		},
		accounts: {
			label: 'Account availability',
			props: {
				type: 'select',
				isMulti: true,
				value: accountOptions.filter(accountOption =>
					accounts.map(a => a.id).includes(accountOption.value)
				),
				onChange: options => {
					setAccounts(
						linkState.data.link.accounts.filter(account =>
							options.map(option => option.value).includes(account.id)
						)
					)
				}
			},
			options: accountOptions
		},
		groupOnly: {
			label:
				'⚠️ Tick if this source is ONLY for group message (tracking will be affected)',
			props: {
				onChange: () => {},
				type: 'checkbox',
				value: true
			}
		}
	})
	const isFormValid = !!(
		((node?.type === 'checkout' &&
			isValidCheckoutUrl(linkState.data.checkoutUrl)) ||
			(node?.type === 'app-launcher' &&
				((linkState.data.appLaunchAction === 'open-review' &&
					linkState.data.reviewId) ||
					(linkState.data.appLaunchAction === 'open-program' &&
						linkState.data.programId))) ||
			['launch-only', 'open-daily-challenge'].includes(
				linkState.data.appLaunchAction
			) ||
			node?.type === 'regular') &&
		isValidSource(linkState.data.organicSource) &&
		accounts?.length
	)

	function createSource() {
		let params = null

		setIsLoading(true)

		if (node.type === 'checkout') {
			params = { url: linkState.data.checkoutUrl }
		} else if (node.type === 'app-launcher') {
			if (linkState.data.appLaunchAction === 'open-review') {
				params = {
					actions: {
						navigate: {
							screen: 'PreReview',
							params: {
								reviewId: linkState.data.reviewId
							}
						}
					}
				}
			} else if (linkState.data.appLaunchAction === 'open-program') {
				params = {
					actions: {
						navigate: {
							screen: 'ProgramDetails',
							params: {
								programId: linkState.data.programId
							}
						}
					}
				}
			} else if (linkState.data.appLaunchAction === 'open-daily-challenge') {
				params = {
					actions: {
						navigate: {
							screen: 'DailyChallenge'
						}
					}
				}
			}
		}

		const sourceData = {
			nodeId: node.id,
			channel: 'whatsapp',
			source: linkState.data.organicSource,
			accountIds: accounts.map(a => a.id),
			mustBePersonalized: !form.getRef('groupOnly').checked,
			params: params
		}

		linkState.instance
			.createSource(sourceData, linkDispatch, linkState)
			.then(() => {
				toastMessage('Source succesfully created', 'success')
				modalControls.closeModal()
			})
	}

	return (
		<>
			<h4>Create Source</h4>
			<h5>{`${productName} → ${funnel.name}`}</h5>
			{form.renderInput('node')}
			{node?.type === 'checkout' ? <CheckoutUrlInput /> : null}
			{node?.type === 'app-launcher' ? (
				<>
					<AppLaunchActionInput />
					<AppLaunchTargetInput />
				</>
			) : null}
			<OrganicSourceInput />
			{form.renderInput('accounts')}
			{form.renderInput('groupOnly')}
			<Button
				loading={isLoading}
				onClick={createSource}
				disabled={isLoading || !isFormValid}
			>
				Save
			</Button>
		</>
	)
}
