import React, { useState } from 'react'
import { Button, toastMessage } from '../../../components'
import styles from '../Links.module.scss'
import { useLinkState } from '../../../contexts/link'
import {
	copyStringToClipboard,
	isValidCheckoutUrl,
	isValidSource
} from '../utils'
import { OrganicSourceInput } from '../OrganicSourceInput'
import { useShortLinkState } from '../../../contexts/short-link'
import { AppLaunchActionInput } from '../AppLaunchActionInput'
import { AppLaunchTargetInput } from '../AppLaunchTargetInput'
import { CheckoutUrlInput } from '../CheckoutUrlInput'

export const BuildGetResponseLinkForm = ({ modalControls, node }) => {
	const linkState = useLinkState()
	const shortLinkState = useShortLinkState()
	const [isLoading, setIsLoading] = useState(false)
	const url = linkState.data.link.urls.find(
		url => url.nodeId === node.id && url.trafficSourceId === null
	)
	const funnel = linkState.data.link.funnels.find(
		funnel => funnel.id === node.funnelId
	)
	const product = linkState.data.link.products.find(
		product => funnel.productId === product.id
	)
	const isFormValid = !!(
		((node?.type === 'checkout' &&
			isValidCheckoutUrl(linkState.data.checkoutUrl)) ||
			(node?.type === 'app-launcher' &&
				((linkState.data.appLaunchAction === 'open-review' &&
					linkState.data.reviewId) ||
					(linkState.data.appLaunchAction === 'open-program' &&
						linkState.data.programId))) ||
			['launch-only', 'open-daily-challenge'].includes(
				linkState.data.appLaunchAction
			) ||
			node?.type === 'regular') &&
		isValidSource(linkState.data.organicSource)
	)

	async function personalizeLink() {
		setIsLoading(true)
		const link = new URL(url.url)
		const linkParams = new URLSearchParams(link.search)

		linkParams.set('channel', 'getresponse')
		linkParams.set('source', linkState.data.organicSource)

		if (node.type === 'checkout') {
			linkParams.set('url', linkState.data.checkoutUrl)
		} else if (node.type === 'app-launcher') {
			let params = null

			if (linkState.data.appLaunchAction === 'open-review') {
				params = {
					actions: {
						navigate: {
							screen: 'PreReview',
							params: {
								reviewId: linkState.data.reviewId
							}
						}
					}
				}
			} else if (linkState.data.appLaunchAction === 'open-program') {
				params = {
					actions: {
						navigate: {
							screen: 'ProgramDetails',
							params: {
								programId: linkState.data.programId
							}
						}
					}
				}
			} else if (linkState.data.appLaunchAction === 'open-daily-challenge') {
				params = {
					actions: {
						navigate: {
							screen: 'DailyChallenge'
						}
					}
				}
			}

			if (params) {
				for (const [key, value] of Object.entries(params)) {
					linkParams.set(key, JSON.stringify(value))
				}
			}
		}

		const shortLink = await shortLinkState.instance.shorten({
			url: `${link.protocol}//${link.host}${link.pathname
				}?${linkParams.toString()}`,
			domain: node.domain
		})

		if (shortLink) {
			copyStringToClipboard(
				`${shortLink.shortLink}?flux_hid={{CUSTOM \`hid\`}}&e={{CONTACT \`subscriber_email\`}}&n={{CUSTOM \`fullname\`}}&mn={{CUSTOM \`mobile\`}}`,
				'Personalized link copied!'
			)
			modalControls.closeModal()
		} else {
			toastMessage('Fail trying to create short link', 'error')
		}
	}

	return (
		<>
			<h4>GetResponse Personalized Link</h4>
			<h5>
				{product.name} → {funnel.name} → {node.name}
			</h5>
			{url ? (
				<>
					{node.type === 'checkout' ? <CheckoutUrlInput /> : null}
					{node?.type === 'app-launcher' ? (
						<>
							<AppLaunchActionInput />
							<AppLaunchTargetInput />
						</>
					) : null}
					<OrganicSourceInput />
					<Button
						disabled={isLoading || !isFormValid}
						loading={isLoading}
						onClick={personalizeLink}
					>
						Copy Link
					</Button>
				</>
			) : null}

			{!url ? (
				<p className={styles.error}>No organic tracking link found.</p>
			) : null}
		</>
	)
}
