import React from 'react'
import { isEqual } from 'lodash'
import { ProgramUtil } from '../shared/program'

const ProgramStateContext = React.createContext()
const ProgramDispatchContext = React.createContext()
const programService = new ProgramUtil()

function getData() {
	return programService.getData()
}

function initState(dispatch, state) {
	if (state?.isInitialized) return

	getData().then(data => {
		dispatch({
			type: 'init',
			payload: {
				isInitialized: true,
				instance: programService,
				data: data
			}
		})
	})
}

function updateState(dispatch) {
	getData().then(data => {
		dispatch({
			type: 'update',
			payload: {
				data: data
			}
		})
	})
}

function programReducer(state, action) {
	const newState =
		action.type === 'init'
			? action.payload
			: {
					...state,
					data: action.payload.data
			  }

	if (isEqual(state, newState)) return state

	switch (action.type) {
		case 'init':
		case 'update':
			return newState

		default:
			throw new Error(`Unhandled action type: ${action.type}`)
	}
}

function ProgramProvider({ children }) {
	const [state, dispatch] = React.useReducer(programReducer, {})
	return (
		<ProgramStateContext.Provider value={state}>
			<ProgramDispatchContext.Provider value={dispatch}>
				{children}
			</ProgramDispatchContext.Provider>
		</ProgramStateContext.Provider>
	)
}

function useProgramState() {
	const context = React.useContext(ProgramStateContext)
	if (context === undefined) {
		throw new Error('useProgramState must be used within a ProgramProvider')
	}
	return context
}

function useProgramDispatch() {
	const context = React.useContext(ProgramDispatchContext)
	if (context === undefined) {
		throw new Error('useProgramDispatch must be used within a ProgramProvider')
	}
	return context
}

export {
	ProgramProvider,
	useProgramState,
	useProgramDispatch,
	initState,
	updateState
}
