import { StorageUtil, StorageKeys } from './'

export class DeviceUtil {
	getUUID() {
		return StorageUtil.getItem(StorageKeys.UUID)
	}

	setUUID(uuid) {
		return StorageUtil.setItem(StorageKeys.UUID, uuid)
	}

	isMobile() {
		return (
			'ontouchstart' in document.documentElement && navigator.maxTouchPoints > 1
		)
	}
}
