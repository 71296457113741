import axios from "axios"
import { toastMessage } from "../components";

/**
 * HTTP client
 */
class Http {

    /**
     * Creates an instance of Http.
     * 
     * @param {string} baseUrl - Base url
     * @param {Object} auth - Authentication data
     */
    constructor(baseUrl, auth = {}) {
        this.baseUrl = baseUrl
        this.auth = auth
        this.instance = axios.create({
            baseURL: this.baseUrl,
        })

        this.instance.interceptors.response.use(
            response => response,
            error => {
                if (error.response && error.response.status >= 400) {
                    /** @type {string} */
                    const message = error.response.data.message || '';
                    if (message.split('=')[3]) {
                        toastMessage(message.split('=')[3]);
                    } else {
                        toastMessage('Oops, something went wrong...', 'error')
                    }
                } else {
                    return Promise.reject(error);
                }
            },
        );

        this.instance.interceptors.request.use(
            config => {
                if (this.auth) {
                    config.headers = {
                        ...config.headers,
                        ...this.auth,
                    }
                }

                return config;
            }
        )
    }

    /**
     * Do a GET request.
     * 
     * @param {string} endpoint - The api endpoint.
     * @param {Object} query - The query params. 
     * @returns 
     */
    async get(endpoint, query = {}) {
        const res = await this.instance.get(`${endpoint}`, {
            params: query,
        });

        return res;
    }

    /**
     * Do a POST request.
     * 
     * @param {string} endpoint - The api endpoint.
     * @param {Object} data - The body.
     * @returns 
     */
    async post(endpoint, data) {
        const res = await this.instance.post(`${endpoint}`, data);

        return res;
    }
}

export default Http;