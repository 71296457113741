import React, { useEffect, useState } from 'react'
import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import { Redirect } from 'react-router-dom'
import { Header, Loader } from '../'
import { StorageUtil, StorageKeys } from '../../shared'

export const Page = ({ checkAuth, preFetch, postFetch, render, isLoaded }) => {
	const CancelToken = axios.CancelToken
	const source = CancelToken.source()
	const settings = {
		request: {
			source: source
		}
	}
	const [isMounted, setIsMounted] = useState(false)
	const [authData, setAuthData] = useState(false)
	const [fetchedData, setFetchedData] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	function getStateCopy() {
		const data = {
			authData: cloneDeep(authData)
		}

		if (fetchedData) data.fetchedData = cloneDeep(fetchedData)
		return data
	}

	async function runPreFetch() {
		if (isMounted !== false) {
			setIsLoading(true)
			const data = await preFetch(getStateCopy(), settings)
			setFetchedData(data)
			setIsLoading(false)
		}
	}

	function renderPage() {
		return (
			<React.Fragment key="page">
				{render(getStateCopy(), settings)}
			</React.Fragment>
		)
	}

	useEffect(() => {
		if (!isMounted || fetchedData === false) return
		if (postFetch) {
			postFetch(getStateCopy(), settings)
		}
	}, [fetchedData, isMounted])

	useEffect(() => {
		if (checkAuth !== false) {
			const storedAuthData = StorageUtil.getItem(StorageKeys.AUTH_DATA)

			if (storedAuthData) {
				// renew auth token expiration time
				StorageUtil.setItem(StorageKeys.AUTH_DATA, storedAuthData, true)
			}
			setAuthData(storedAuthData)
		}

		setIsMounted(true)

		return () => {
			setIsMounted(false)
			settings.request.source.cancel('Operation canceled by the user.')
		}
	}, [])

	if (!render) {
		throw new Error('render prop must be implemented on child component')
	}

	const result = [
		<Loader
			key="loader"
			display={
				isLoaded === false ||
				(checkAuth !== false && authData === false) ||
				(preFetch && fetchedData === false)
			}
		/>,
		<Header key="header" authData={authData} />
	]

	if (checkAuth !== false && authData === null) {
		result.push(<Redirect key="redirect" to="/login" />)
	} else if (
		preFetch &&
		fetchedData === false &&
		!isLoading &&
		(checkAuth === false || (checkAuth !== false && authData))
	) {
		runPreFetch()
	} else if (checkAuth === false || (checkAuth !== false && authData)) {
		result.push(renderPage())
	}

	return result
}
