import React, { useState } from 'react'
import { Button, Modal } from '../../../components'
import { useLinkDispatch, useLinkState } from '../../../contexts/link'
import styles from '../Links.module.scss'
import removeIcon from '../../../assets/images/icons/remove-icon-color.png'
import { SourceForm } from './SourceForm'
import { PersonalizeLinkForm } from './PersonalizeLinkForm'
import regularNodeIcon from '../../../assets/images/icons/regular-node-icon-inverse.png'
import checkoutNodeIcon from '../../../assets/images/icons/checkout-node-icon-inverse.png'

const Accounts = ({ accountIds, pageState }) => {
	const linkState = useLinkState()

	if (!accountIds?.length) return null

	const accountNames = linkState.data.link.accounts
		.filter(account => accountIds.includes(account.id))
		.map(account => account.name.split(' ')[0])

	if (!pageState.authData.account.permissions?.includes('linkManagement'))
		return null
	return <p className={styles.account}>{accountNames.join(', ')}</p>
}

const Sources = ({ sources, pageState, modalControls }) => {
	const linkState = useLinkState()
	const linkDispatch = useLinkDispatch()

	function deleteSource(e, sourceId) {
		const sourceName = linkState.data.link.sources.find(
			source => source.id === sourceId
		).source

		if (window.confirm(`Delete permanently ${sourceName}?`)) {
			linkState.instance.deleteSource(sourceId, linkDispatch, linkState)
		}

		e.stopPropagation()
	}

	function getSourceLink(source) {
		modalControls.setModalContent(
			<PersonalizeLinkForm
				pageState={pageState}
				modalControls={modalControls}
				source={source}
			/>
		)
		modalControls.openModal()
	}

	if (!sources?.length) return null

	return (
		<div>
			{sources.map(source => {
				const sourceStyles = [styles.sourceDataContainer]

				if (!source.mustBePersonalized) sourceStyles.push(styles.group)

				return (
					<div key={`source-${source.id}`}>
						<div
							className={sourceStyles.join(' ')}
							onClick={getSourceLink.bind(null, source)}
						>
							<p className={styles.source}>{source.source}</p>
							<Accounts accountIds={source.accountIds} pageState={pageState} />
							{pageState.authData.account.permissions?.includes(
								'linkManagement'
							) ? (
								<img
									alt="Delete source"
									onClick={e => deleteSource(e, source.id)}
									src={removeIcon}
									className={[styles.icon, styles.removeIcon].join(' ')}
								/>
							) : null}
						</div>
					</div>
				)
			})}
		</div>
	)
}

const Nodes = ({ nodes, pageState, modalControls }) => {
	const linkState = useLinkState()

	if (!nodes?.length)
		return <p className={styles.noData}>No nodes available for this funnel.</p>

	return nodes.map(node => {
		let sources = linkState.data.link.sources?.filter(
			source => source.nodeId === node.id && source.channel === 'whatsapp'
		)

		if (!pageState.authData.account.permissions?.includes('linkManagement')) {
			sources = sources.filter(source =>
				source.accountIds.includes(pageState.authData.account.id)
			)
		}

		if (!sources?.length) return null

		return (
			<div className={styles.nodeContainer} key={`node-${node.id}`}>
				<p className={styles.nodeName}>
					<img
						alt=""
						src={node.type === 'checkout' ? checkoutNodeIcon : regularNodeIcon}
					/>
					{node.name}
				</p>
				<Sources
					sources={sources}
					pageState={pageState}
					modalControls={modalControls}
				/>
			</div>
		)
	})
}

const Funnels = ({ funnels, pageState, modalControls }) => {
	const linkState = useLinkState()

	function createSource(funnel) {
		modalControls.setModalContent(
			<SourceForm modalControls={modalControls} funnel={funnel} />
		)
		modalControls.openModal()
	}

	if (!funnels?.length)
		return (
			<p className={styles.noData}>No funnels available for this product.</p>
		)

	return funnels.map(funnel => {
		const nodes = linkState.data.link.nodes?.filter(
			node => node.funnelId === funnel.id && node.status === 'active'
		)

		return (
			<div key={`funnel-${funnel.id}`} className={styles.funnelContainer}>
				<h4>
					{funnel.name}
					{pageState.authData.account.permissions?.includes(
						'linkManagement'
					) ? (
						<span>
							<Button size="mini" onClick={createSource.bind(null, funnel)}>
								+ Source
							</Button>
						</span>
					) : null}
				</h4>
				<Nodes
					nodes={nodes}
					pageState={pageState}
					modalControls={modalControls}
				/>
			</div>
		)
	})
}

export const SourceMap = ({ pageState }) => {
	const linkState = useLinkState()
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [modalContent, setModalContent] = useState(null)

	function closeModal() {
		setIsModalOpen(false)
	}

	function openModal() {
		setIsModalOpen(true)
	}

	return (
		<>
			{linkState.data.link.products.map(product => {
				let funnels = linkState.data.link.funnels?.filter(
					funnel =>
						funnel.productId === product.id && funnel.status === 'active'
				)

				if (
					!pageState.authData.account.permissions?.includes('linkManagement')
				) {
					funnels = funnels.filter(
						funnel =>
							!!linkState.data.link.nodes.filter(
								node =>
									node.funnelId === funnel.id &&
									node.status === 'active' &&
									!!linkState.data.link.sources?.filter(
										source =>
											source.nodeId === node.id &&
											source.channel === 'whatsapp' &&
											source.accountIds.includes(pageState.authData.account.id)
									)?.length
							)?.length
					)
				}

				if (!funnels?.length) return null

				return (
					<div
						key={`product-${product.id}`}
						className={styles.productContainer}
					>
						<h4>{product.name}</h4>
						<Funnels
							funnels={funnels}
							pageState={pageState}
							modalControls={{
								setModalContent: setModalContent,
								closeModal: closeModal,
								openModal: openModal
							}}
						/>
					</div>
				)
			})}
			<Modal isOpen={isModalOpen} onClose={closeModal}>
				{modalContent}
			</Modal>
		</>
	)
}
