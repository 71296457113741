import React from 'react'
import { Page } from '../../components'
import mainStyles from '../../assets/css/App.module.scss'

export const Welcome = () => {
	function render(pageState) {
		return (
			<div className={mainStyles.container}>
				<div className={mainStyles.wrapper}>
					<h3>
						Hi there, {pageState.authData.account.fullname.split(' ')[0]} 👋
					</h3>
					<p>Welcome to the Idiomus Manager platform.</p>
				</div>
			</div>
		)
	}
	return <Page render={render} />
}
