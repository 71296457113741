import crmStyles from './Crm.module.scss'
import React, { useMemo } from 'react'
import { CrmEvents } from './CrmEvents'
import { useLinkDispatch, useLinkState } from '../../contexts/link'
import { Link } from 'react-router-dom'
import { CgLink, CgSmartphone } from 'react-icons/cg'
import { toastMessage } from '../../components'

export const ContactInfoModal = ({ mobileNumber, name, email, label }) => {
	const linkDispatch = useLinkDispatch()
	const linkState = useLinkState()

	const createLink = mobilePhone => {
		linkDispatch({
			type: 'update',
			payload: {
				data: {
					...linkState,
					mobileNumber: mobilePhone
				}
			}
		})
	}

	return useMemo(() => {
		const mobilePhone = mobileNumber?.replace(/[^A-Z0-9]+/gi, '')

		if (!mobileNumber) {
			toastMessage('No mobile number available', 'warning')
		}

		return (
			<>
				<div>
					<h5>{label}</h5>
					<hr />
					<h3 className={crmStyles.contactName}>{name}</h3>
					<a href={'mailto:' + email || ''} className={crmStyles.contactEmail}>
						{email}
					</a>
					<h4 className={crmStyles.mobileNumber}>{mobileNumber}</h4>
				</div>
				{mobileNumber && (
					<div className={crmStyles.links}>
						<a
							target={'_blank'}
							rel="noreferrer"
							href={'https://wa.me/' + mobilePhone}
							className={crmStyles.modalButton}
						>
							<CgSmartphone size={24} />
							Whatsapp
						</a>
						<Link
							to={'/app/links'}
							onClick={() => createLink(mobileNumber)}
							className={crmStyles.modalButton}
						>
							<CgLink size={24} />
							Links
						</Link>
					</div>
				)}
				<CrmEvents subject={email} />
			</>
		)
	}, [mobileNumber, name, email, label])
}
