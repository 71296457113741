import React, { useEffect } from 'react'
import { toastMessage } from '../../../components'
import styles from '../Links.module.scss'
import { useLinkState } from '../../../contexts/link'
import { copyStringToClipboard } from '../utils'
import { useShortLinkState } from '../../../contexts/short-link'

export const BuildOrganicLinkForm = ({ modalControls, node }) => {
	const linkState = useLinkState()
	const shortLinkState = useShortLinkState()
	const url = linkState.data.link.urls.find(
		url => url.nodeId === node.id && url.trafficSourceId === null
	)
	const funnel = linkState.data.link.funnels.find(
		funnel => funnel.id === node.funnelId
	)
	const product = linkState.data.link.products.find(
		product => funnel.productId === product.id
	)
	const isFormValid = !!(node.type === 'regular' && url)

	async function buildLink() {
		const shortLink = await shortLinkState.instance.shorten({
			...url,
			domain: node.domain
		})

		if (shortLink) {
			copyStringToClipboard(shortLink.shortLink, 'Organic link copied!')
			modalControls.closeModal()
		} else {
			toastMessage('Fail trying to create short link', 'error')
		}
	}

	useEffect(() => {
		if (isFormValid) {
			buildLink()
		}
	}, [])

	return (
		<>
			<h4>Organic Link</h4>
			<h5>
				{product.name} → {funnel.name} → {node.name}
			</h5>
			{url && ['checkout', 'app-launcher'].includes(node.type) ? (
				<p className={styles.error}>
					Sorry, it's not allowed to get organic links for checkout and app
					launcher nodes.
				</p>
			) : null}
			{url && node.type === 'regular' ? (
				<p>Processing, please wait...</p>
			) : null}
			{!url ? (
				<p className={styles.error}>No organic tracking link found.</p>
			) : null}
		</>
	)
}
