import React, { useRef } from 'react'
import { useReportDispatch, useReportState } from '../../contexts/report'
import DatePicker from 'react-datepicker'
import { addDays } from 'date-fns'
import './DateRangeInput.css'
import styles from './Reports.module.scss'

export const DateRangeInput = () => {
	const reportState = useReportState()
	const ref = useRef()
	const reportDispatch = useReportDispatch()
	const [startDate, endDate] = reportState.data?.selectedDateRange || [
		null,
		null
	]

	function handleSetDayRange(range) {
		const today = new Date()
		let dates = []

		switch (range) {
			case 'previous14Days':
				dates.push(addDays(today, -14))
				dates.push(addDays(today, -1))
				break

			case 'previous7Days':
				dates.push(addDays(today, -7))
				dates.push(addDays(today, -1))
				break

			case 'previous3Days':
				dates.push(addDays(today, -3))
				dates.push(addDays(today, -1))
				break

			case 'yesterday':
				dates.push(addDays(today, -1))
				dates.push(addDays(today, -1))
				break

			case 'today':
				dates.push(today)
				dates.push(today)
				break

			default:
				break
		}

		reportDispatch({
			type: 'update',
			payload: {
				data: {
					selectedDateRange: dates,
					shouldDisplayCharts: false
				}
			}
		})
	}

	function handleOnChange(data) {
		reportDispatch({
			type: 'update',
			payload: {
				data: {
					selectedDateRange: data,
					shouldDisplayCharts: false
				}
			}
		})

		if (data[0] && data[1]) setTimeout(() => ref.current.setOpen(false), 250)
	}

	return (
		<div className={styles.dateRangeInputContainer}>
			<label>
				<p>Date range</p>
				<DatePicker
					ref={ref}
					selectsRange={true}
					startDate={startDate}
					endDate={endDate}
					onChange={handleOnChange}
					dateFormat="dd/MM/yyyy"
					closeOnScroll
					shouldCloseOnSelect
					show
				/>
			</label>
			<button
				className={styles.datePreset}
				onClick={handleSetDayRange.bind(null, 'previous14Days')}
			>
				14d
			</button>
			<button
				className={styles.datePreset}
				onClick={handleSetDayRange.bind(null, 'previous7Days')}
			>
				7d
			</button>
			<button
				className={styles.datePreset}
				onClick={handleSetDayRange.bind(null, 'previous3Days')}
			>
				3d
			</button>
			<button
				className={styles.datePreset}
				onClick={handleSetDayRange.bind(null, 'yesterday')}
			>
				Yesterday
			</button>
			<button
				className={styles.datePreset}
				onClick={handleSetDayRange.bind(null, 'today')}
			>
				Today
			</button>
		</div>
	)
}
