import { RequestUtil } from './'

export class TagUtil {
	constructor(tagId) {
		if (tagId) {
			this._id = tagId
		}
	}

	setId(id) {
		this._id = id
	}

	getId() {
		return this._id
	}

	create(data) {
		return RequestUtil.post('/tag', data)
	}

	getData(params, settings) {
		return RequestUtil.get('/tag/list', params, settings).then(
			response => response.data
		)
	}
}
