import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Forbidden, Loader, Page, toastMessage } from '../../components'
import mainStyles from '../../assets/css/App.module.scss'
import { AccountUtil, FormComponent } from '../../shared'
import { startCase } from 'lodash'

import styles from './Permissions.module.scss'
import { permissions } from './Permissions.fixture'

export const Permissions = () => {
	const [state, setState] = useState({ accounts: [] })
	const [selectedAccount, setSelectedAccount] = useState(null)
	const [userInput, setUserInput] = useState({ permissions: [] })
	const [isLoading, setIsLoading] = useState(false)
	const formRef = useRef({})

	const accountUtil = new AccountUtil()

	const accountTypes = {
		admin: {
			label: 'Admin',
			value: 'admin'
		},
		premium: {
			label: 'Premium',
			value: 'premium'
		}
	}

	const _validateExpiresAt = value => {
		let valid = true

		if (!value.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/)) {
			valid = false
			toastMessage('Invalid expiration date. Format: YYYY-MM-DD')
		}

		let date = new Date(value)

		if (date < new Date()) {
			valid = false
			toastMessage('Invalid expiration date. Date must be in the future.')
		}

		return valid
	}

	const preFetch = async () => {
		const data = await accountUtil.getData({
			orderBy: 'name',
			type: 'admin',
			admin: 1
		})

		await setState({
			accounts: data
		})

		setIsLoading(false)
	}

	const form = useMemo(() => {
		const users = state.accounts?.map(account => ({
			label: account.name,
			value: account.id
		}))
		const options = {
			account: {
				label: 'Account',
				options: [...(users || [])],
				props: {
					value:
						selectedAccount !== null
							? {
									label: state.accounts?.find(
										account => account.id === selectedAccount
									)?.name,
									value: selectedAccount
								}
							: null,
					type: 'select',
					onChange: ({ value }) => {
						setSelectedAccount(value)
					}
				}
			}
		}

		return new FormComponent(options)
	}, [state.accounts, selectedAccount])

	useEffect(() => {
		setIsLoading(true)
		const user = state.accounts.find(account => account.id === selectedAccount)

		if (!user) {
			setUserInput({
				permissions: []
			})
			setIsLoading(false)
			return
		}

		setUserInput({
			...user,
			expiresAt: new Date(user.expiresAt).toISOString().split('T')[0],
			permissions: user.permissions
		})

		setIsLoading(false)
	}, [selectedAccount])

	const submitForm = async () => {
		setIsLoading(true)

		const currentForm = formRef.current

		if (!currentForm?.valid()) {
			setIsLoading(false)
			return
		}

		if (userInput.type === 'admin' && !userInput.permissions?.length) {
			toastMessage(
				`No permissions were set for ${state.accounts.find(a => a.id === selectedAccount)?.name.split(' ')[0]}`,
				'error'
			)
			setIsLoading(false)
			return
		}

		const data = {
			accountId: userInput.id,
			type: userInput.type,
			permissions: userInput.permissions,
			expiresAt: userInput.expiresAt
		}

		const res = await accountUtil.savePermissions(data)

		if ([400, 403].includes(res.status)) {
			toastMessage('Something went wrong', 'error')
			setIsLoading(false)
			return
		}

		toastMessage('Success')

		const userIndex = state.accounts.findIndex(
			account => account.id === userInput.id
		)
		if (userIndex === -1) {
			toastMessage('Something went wrong.', 'error')
			setIsLoading(false)
			return
		}

		let currState = state.accounts

		if (userInput.type !== 'admin') {
			currState = currState.filter(account => account.id !== userInput.id)
		} else {
			currState[userIndex] = {
				...currState[userIndex],
				permissions: data.permissions,
				expiresAt: data.expiresAt,
				type: data.type
			}
		}

		setState({
			accounts: currState
		})

		setSelectedAccount(null)

		setIsLoading(false)
	}

	const renderUser = () => {
		if (!userInput.id) {
			return <></>
		}

		const options = {
			expiresAt: {
				label: 'Expires At',
				required: [true, 'Enter expiration date.'],
				valid: value => {
					return _validateExpiresAt(value)
				},
				props: {
					type: 'date',
					value: userInput.expiresAt,
					key: 'expiresAt',
					onChange: ({ target }) => {
						setUserInput({
							...userInput,
							expiresAt: target.value
						})
					}
				}
			},
			accountType: {
				label: 'Account Type',
				props: {
					type: 'select',
					value: {
						label: accountTypes[userInput?.type].label,
						value: userInput?.type
					},
					onChange: ({ value }) => {
						setUserInput({
							...userInput,
							type: value
						})
					},
					key: 'accountType'
				},
				options: Object.values(accountTypes).map(account => account)
			},
			permissions: {
				label: 'Permissions',
				props: {
					type: 'select',
					isMulti: true,
					onChange: values => {
						const permissions = values.map(({ value }) => value)

						setUserInput({
							...userInput,
							permissions
						})
					},
					value: userInput.permissions?.map(permission => ({
						label: startCase(permission),
						value: permission
					})) ?? []
				},
				options: permissions
			},
			save: {
				label: 'Save',
				props: {
					type: 'button',
					onClick: submitForm.bind(formRef)
				}
			}
		}

		const permissionsForm = new FormComponent(options)
		formRef.current = permissionsForm

		return (
			<div className={styles.headingWrapper + ' permissions-form'}>
				<div>
					{permissionsForm.renderInput('expiresAt')}
					{permissionsForm.renderInput('accountType')}
					{permissionsForm.renderInput('permissions')}
				</div>
				{permissionsForm.renderButton('save', {
					loading: isLoading
				})}
			</div>
		)
	}

	return (
		<Page
			preFetch={preFetch}
			render={({ authData }) => {
				if (authData.account.permissions?.indexOf('permissionManagement') < 0) {
					return <Forbidden />
				}

				return (
					<>
						<div className={mainStyles.container}>
							<div className={mainStyles.wrapper}>
								<Loader display={isLoading} />
								<h3>
									{selectedAccount !== null
										? `Editing Account Permissions #${selectedAccount}`
										: 'Account Permissions'}
								</h3>
								{form.renderInput('account')}
								{selectedAccount !== null && renderUser()}
							</div>
						</div>
					</>
				)
			}}
		/>
	)
}
