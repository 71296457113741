import { FormComponent } from '../../shared'
import { useLinkState } from '../../contexts/link'
import { useReportDispatch, useReportState } from '../../contexts/report'

export const FunnelInput = ({ trafficSourceToken }) => {
	const linkState = useLinkState()
	const reportState = useReportState()
	const reportDispatch = useReportDispatch()
	const funnelOptions = []
	const trafficSource = linkState.data.link.trafficSources.find(
		ts => ts.token === trafficSourceToken
	)
	linkState.data.link.products.forEach(product => {
		const options = linkState.data.link.funnels
			.filter(
				funnel =>
					funnel.productId === product.id &&
					funnel.status === 'active' &&
					linkState.data.link.nodes.find(
						node =>
							node.funnelId === funnel.id &&
							linkState.data.link.urls.find(
								url =>
									url.nodeId === node.id &&
									url.trafficSourceId === trafficSource.id
							)
					)
			)
			.map(funnel => ({
				label: funnel.name,
				value: funnel.id
			}))

		if (options.length) {
			funnelOptions.push({
				label: product.name,
				options: options
			})
		}
	})
	const selectedProduct = funnelOptions.find(optionData =>
		optionData.options.find(
			funnelOption =>
				funnelOption.value === reportState.data?.selectedFunnel?.id
		)
	)

	const form = new FormComponent({
		funnel: {
			label: 'Funnel',
			props: {
				type: 'select',
				value:
					selectedProduct?.options.find(
						option => option.value === reportState.data.selectedFunnel?.id
					) ?? null,
				onChange: option => {
					const selectedFunnel = linkState.data.link.funnels.find(
						funnel => funnel.id === option.value
					)
					reportDispatch({
						type: 'update',
						payload: {
							data: {
								selectedFunnel: selectedFunnel,
								selectedProduct: linkState.data.link.products.find(
									product => product.id === selectedFunnel.productId
								),
								campaignFilter: null
							}
						}
					})
				}
			},
			options: funnelOptions
		}
	})

	return form.renderInput('funnel')
}
