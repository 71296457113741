import React from 'react'
import styles from './ReviewValidator.module.scss'

export const validationRegexes = {
	redundancyRegex: {
		numericForm:
			/((us\$|usd|r\$|brl|\$)\s?)\d+(?:[.,]\d+)*(\s?(thousand|k|million|mi|mm|billion|bi|bb|trillion|tri))?(\s(dollars|dollar|usd|brazilian real|real|brazilian reais|reais|brl))/gi,
		writtenForm:
			/\b(?:R\$|US\$|USD|BRL|\$)\s?((?:a|one|two|three|four|five|six|seven|eight|nine|ten|eleven|twelve|thirteen|fourteen|fifteen|sixteen|seventeen|eighteen|nineteen|twenty|thirty|forty|fifty|sixty|seventy|eighty|ninety|hundred)\s)+(?:hundred|thousand|million|billion|trillion)(\s(dollars|dollar|USD|brazilian real|real|brazilian reais|reais|BRL))/gi,
		message:
			'Redundancy on a currency text (e.g. US$ 10 dollars). See the rules for currency text.'
	},
	currencyRegex:
		/((US\$|USD|R\$|BRL|\$)\s?)\d+(?:[.,]\d+)*(\s?(thousand|k|million|mi|mm|billion|bi|bb|trillion|tri))?(\s(dollars|dollar|brazilian real|real|brazilian reais|reais))?|((US\$|USD|R\$|BRL|\$)\s?)?\d+(?:[.,]\d+)*(\s?(thousand|k|million|mi|mm|billion|bi|bb|trillion|tri))?(\s?(dollars|dollar|USD|brazilian real|real|brazilian reais|reais|BRL))/gi,
	uppercaseRegex: {
		regex: /(US\$|USD|R\$|BRL)/,
		message:
			'Adjust the case for currency text. Symbols like US$, R$, USD and BRL should be UPPERCASE.'
	},
	checkRegexes: {
		regex: [
			/((US\$|R\$|\$)\s?)\d{1,3}(?:,\d{3})*(?:\.\d{2})?(?=\W|$)((\s(thousand|million|billion|trillion))|(\s?(k|mi|mm|bi|bb|tri)(?=\W|$)))?/gi, // symbolAmount
			/((USD|BRL)\s)\d{1,3}(?:,\d{3})*(?:\.\d{2})?(?=\W|$)((\s(thousand|million|billion|trillion))|(\s?(k|mi|mm|bi|bb|tri)(?=\W)))?/gi, // codeAmount
			/\d{1,3}(?:,\d{3})*(?:\.\d{2})?(?=\D|$)((\s(thousand|million|billion|trillion))|(\s?(k|mi|mm|bi|bb|tri)))?(\s(USD|BRL))/gi, // amountCode
			/\d{1,3}(?:,\d{3})*(?:\.\d{2})?(?=\D|$)((\s(thousand|million|billion|trillion))|(\s?(k|mi|mm|bi|bb|tri)))?(\s(dollars|dollar|brazilian real|real|brazilian reais|reais))/gi // amountCurrency
		],
		message:
			'Error in currency format. See the rules for currency text clarification.'
	},
	decimalRegexes: {
		currency:
			/((US\$|USD|R\$|BRL|\$)\s?)\d+(?:[.,]\s\d+)+(\s?(thousand|k|million|mi|mm|billion|bi|bb|trillion|tri))?(\s(dollars|dollar|brazilian real|real|brazilian reais|reais))?|((US\$|USD|R\$|BRL|\$)\s?)?\d+(?:[.,]\s\d+)+(\s?(thousand|k|million|mi|mm|billion|bi|bb|trillion|tri))?(\s?(dollars|dollar|USD|brazilian real|real|brazilian reais|reais|BRL))/gi,
		percentage: /\d+[,.]\s\d+\s?%/gi,
		lineMessage:
			'Check for incorrect spaces in numbers with decimals (e.g. $150. 95 / 10. 45%).',
		toastMessage:
			'There might be incorrect spaces in numbers with decimals (e.g. $150. 95, 10. 45%).'
	}
}

export function renderCurrencyWarning() {
	return (
		<div className={styles.currencyWarning}>
			<h5>Rules for Currency Text in EN Version</h5>
			<ul>
				<li>Currency can be written with numbers, text or a mix of both.</li>
				<li>Numbers need to be in American format (e.g. 10,500.90)</li>
				<li>
					It must have either a symbol (US$, R$, $, BRL, USD, etc) or text
					(dollars, Brazilian reais, reais, etc), but not both.
				</li>
				<li>Symbols with $ sign must come in front of value.</li>
			</ul>
			<p>Examples:</p>
			<ul>
				<li>Correct: US$ 10 million, $ 182.67, 10 billion USD, 10,000 reais</li>
				<li>Incorrect: USD 10 million dollars, R$ 10000</li>
			</ul>
		</div>
	)
}
