import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import styles from './Toast.module.scss'

export const toastMessage = (message, type = 'info') => {
	const progressBarStyles = [styles.progressBar]

	if (type !== 'info') progressBarStyles.push(styles[type])

	const toastOptions = {
		className: styles.toast,
		bodyClassName: styles.body,
		progressClassName: progressBarStyles.join(' '),
		closeButton: styles.closeButton,
		autoClose: 7000
	}

	toast(message, toastOptions)
}

export const ToastWrapper = () => {
	return <ToastContainer />
}
