import { cloneDeep } from 'lodash'
import { RequestUtil } from './'

export class LinkUtil {
	createSource(data, linkDispatch, linkState) {
		return RequestUtil.post('/link/source', data).then(response => {
			const stateData = cloneDeep(linkState.data)

			stateData.link.sources.push({
				id: response.data.sourceId,
				nodeId: data.nodeId,
				source: data.source,
				channel: data.channel,
				params: data.params,
				accountIds: data.accountIds,
				mustBePersonalized: data.mustBePersonalized
			})

			linkDispatch({
				type: 'update',
				payload: {
					data: stateData
				}
			})
		})
	}

	deleteSource(sourceId, linkDispatch, linkState) {
		return RequestUtil.delete('/link/source', {
			sourceId
		}).then(() => {
			const stateData = cloneDeep(linkState.data)
			stateData.link.sources = stateData.link.sources.filter(
				source => source.id !== sourceId
			)

			linkDispatch({
				type: 'update',
				payload: {
					data: stateData
				}
			})
		})
	}

	getSources() {
		return RequestUtil.get('/link/source').then(response => response.data)
	}

	getProducts() {
		return RequestUtil.get('/link/product').then(response => response.data)
	}

	getFunnels() {
		return RequestUtil.get('/link/funnel').then(response => response.data)
	}

	getNodes() {
		return RequestUtil.get('/link/node').then(response => response.data)
	}

	getUrls() {
		return RequestUtil.get('/link/url').then(response => response.data)
	}

	getTrafficSources() {
		return RequestUtil.get('/link/traffic-source').then(
			response => response.data
		)
	}

	getAccounts() {
		return RequestUtil.get('/link/account').then(response => response.data)
	}

	getTrafficSourceAccounts(trafficSourceToken) {
		return RequestUtil.get(`/traffic-source/${trafficSourceToken}/accounts`)
	}
}
