import { CrmSegments } from './CrmSegments'
import crmStyles from './Crm.module.scss'
import Paginator from '../../components/Paginator/Paginator'
import {
	Button,
	Forbidden,
	Loader,
	Modal,
	toastMessage
} from '../../components'
import { ContactInfoModal } from './ContactInfoModal'
import React, { useEffect, useMemo, useState } from 'react'
import { useLeadDispatch, useLeadState } from '../../contexts/lead'
import { LeadUtil } from '../../shared/lead'
import mainStyles from '../../assets/css/App.module.scss'

export const CrmList = ({ forbidden }) => {
	const [selectedSegment, setSelectedSegment] = useState(null)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [contacts, setContacts] = useState(null)
	const [counts, setCounts] = useState(null)
	const [currentPage, setCurrentPage] = useState(1)
	const [currentContact, setCurrentContact] = useState(null)
	const [selectedContact, setSelectedContact] = useState(null)
	const leadDispatch = useLeadDispatch()
	const leadState = useLeadState()

	const perPage = 30

	const leadUtil = new LeadUtil()

	const closeModal = () => {
		setIsModalOpen(false)
		setCurrentContact(null)

		leadDispatch({
			type: 'update',
			payload: {
				data: {
					...leadState.data,
					email: null
				}
			}
		})
	}

	function openModal(email) {
		leadDispatch({
			type: 'update',
			payload: {
				data: {
					...leadState.data,
					email
				}
			}
		})

		setCurrentContact(email)
	}

	function handlePageChange(page) {
		// Update the lead state.
		leadDispatch({
			type: 'update',
			payload: {
				data: {
					...leadState.data,
					currentPage: page,
					email: null
				}
			}
		})

		setCurrentContact(null)
		setCurrentPage(page)

		window.scrollTo(0, 0)
	}

	const onChangeSegment = value => {
		leadDispatch({
			type: 'update',
			payload: {
				data: {
					segment: value,
					currentPage: 1,
					email: null
				}
			}
		})

		setSelectedSegment(value)
		setCurrentPage(1)
	}

	useEffect(() => {
		if (currentContact) {
			setIsLoading(true)

			leadUtil
				.getInfo({ email: currentContact })
				.then(({ status, data }) => {
					if (status === 200) {
						setSelectedContact(data)
						setIsModalOpen(true)
					} else {
						toastMessage('Error getting contact info', 'error')
					}

					setIsLoading(false)
				})
				.catch(() => {
					setIsModalOpen(false)
					setIsLoading(false)
				})
		}
	}, [currentContact])

	const contactsOptions = useMemo(() => {
		if (contacts === null) return

		return (
			<table>
				<tbody className={mainStyles.smallFont}>
					{Object.values(contacts).map(contact => {
						return (
							<tr key={contact.contactId}>
								<td>
									<span
										onClick={() => openModal(contact.email)}
										className={crmStyles.link}
									>
										{contact.name}
									</span>
								</td>
								<td>
									<span
										onClick={() => openModal(contact.email)}
										className={crmStyles.link}
									>
										{contact.email}
									</span>
								</td>
							</tr>
						)
					})}
					<tr />
				</tbody>
			</table>
		)
	}, [contacts])

	function fetchContacts() {
		setIsLoading(true)

		leadUtil
			.getSegmentLeads(selectedSegment?.value, {
				perPage,
				page: currentPage
			})
			.then(({ status, data }) => {
				if (data.success === false || status !== 200) {
					if (data.result.body) {
						toastMessage(data.result.body.message, 'error')
					} else {
						toastMessage('Error getting leads', 'error')
					}

					return
				}

				if (Object.keys(data?.body)?.length === 0) {
					toastMessage('The segment has no contacts', 'info')
				}

				setContacts(data.body)
				setCounts(data.headers.totalcount)
				setIsLoading(false)
			})
			.catch(() => {
				setIsLoading(false)
				setContacts(null)
				setCounts(null)
			})
	}

	useEffect(() => {
		if (selectedSegment) {
			fetchContacts()
		}
	}, [selectedSegment, currentPage])

	useEffect(() => {
		setIsLoading(true)

		if (leadState?.data) {
			setCurrentPage(leadState.data.currentPage)
			setSelectedSegment(leadState.data.segment)

			if (leadState?.data?.email) {
				setCurrentContact(leadState.data.email)
			}
		}
	}, [])

	if (forbidden) {
		return <Forbidden />
	}

	return (
		<div>
			<CrmSegments
				setIsLoading={setIsLoading}
				onChangeSegment={onChangeSegment}
				selectedSegment={selectedSegment}
			/>
			{contacts && (
				<div className={crmStyles.label}>
					<h4 className={crmStyles.sub}>{selectedSegment?.label}</h4>
					<small className={crmStyles.disabled}>{counts} contacts</small>
					<div style={{ float: 'right' }}>
						<Button
							size="small"
							to="#"
							onClick={() => {
								fetchContacts()
								setCurrentPage(1)
							}}
						>
							Refresh
						</Button>
					</div>
				</div>
			)}
			{selectedSegment && contactsOptions}
			{contacts && counts !== 0 && (
				<Paginator
					itemsPerPage={perPage}
					length={counts}
					currentPage={currentPage}
					paginate={handlePageChange}
				/>
			)}
			<Modal isOpen={isModalOpen} onClose={() => closeModal()}>
				<ContactInfoModal {...selectedContact} label={selectedSegment?.label} />
			</Modal>
			<Loader display={isLoading}></Loader>
		</div>
	)
}
