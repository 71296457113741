import React from 'react'
import { LinkProvider } from './contexts/link'
import { AccountProvider } from './contexts/account'
import { LeadProvider } from './contexts/lead'
import { ShortLinkProvider } from './contexts/short-link'
import { ProgramProvider } from './contexts/program'
import { ReviewProvider } from './contexts/review'
import { ReportProvider } from './contexts/report'

export const Stacks = ({ children }) => {
	return (
		<AccountProvider>
			<ShortLinkProvider>
				<LeadProvider>
					<LinkProvider>
						<ReportProvider>
							<ReviewProvider>
								<ProgramProvider>{children}</ProgramProvider>
							</ReviewProvider>
						</ReportProvider>
					</LinkProvider>
				</LeadProvider>
			</ShortLinkProvider>
		</AccountProvider>
	)
}
