import { RequestUtil } from './'

export class KnowledgeAreaUtil {
	constructor(knowledgeAreaId) {
		if (knowledgeAreaId) {
			this._id = knowledgeAreaId
		}
	}

	setId(id) {
		this._id = id
	}

	getId() {
		return this._id
	}

	create(data) {
		return RequestUtil.post('/knowledge-area', data)
	}

	getData(params, settings) {
		return RequestUtil.get('/knowledge-area/list', params, settings).then(
			response => response.data
		)
	}
}
