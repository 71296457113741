import React from 'react'
import { Page, toastMessage, Forbidden } from '../../components'
import { FormComponent, FormUtil, StringUtil } from '../../shared'
import history from '../../history'
import mainStyles from '../../assets/css/App.module.scss'
import formStyles from '../../assets/css/partials/Form.module.scss'
import styles from './Account.module.scss'
import config from '../../config.json'
import { AsYouType } from 'libphonenumber-js/max'
import {
	DEFAULT_COUNTRY,
	getPhoneNumberFragments,
	isPhoneValid
} from '../../shared/form'
import PoliAccountUtil from '../../shared/poli'

export class PoliAccount extends React.PureComponent {
	state = {
		inputs: {
			name: '',
			email: '',
			mobileNumber: '',
			type: '',
			password: '',
			expiresAt: '',
			createdAt: null
		},
		initialEmail: null,
		isSaving: false
	}

	constructor(props) {
		super(props)
		this._changeHandler = this._changeHandler.bind(this)
	}

	_changeHandler = (id, value, callback) => {
		const inputs = { ...this.state.inputs, [id]: value }

		this.setState(
			{
				inputs: inputs
			},
			callback
		)
	}

	_renderDisabledTextInput = ({ label, value }) => {
		return (
			<div className={formStyles.inputWrapper}>
				<label>
					<p>{label}</p>
					<div>{value}</div>
				</label>
			</div>
		)
	}

	_cleanUpText = text => {
		return text.trim().replace(/[ ]+/g, ' ') // remove duplicated spaces
	}

	_handleSave() {
		if (!this._getIsSaving() && this.form.valid()) {
			const accountId = this.getAccountId()
			const isEditing = !!accountId

			this._setIsSaving(true)

			let mobileNumber = null
			let mobileCountryCode = null

			if (this.state.inputs.mobileNumber?.length) {
				const fragments = getPhoneNumberFragments(
					this.state.inputs.mobileNumber
				)
				mobileNumber = fragments.international
				mobileCountryCode = fragments.parsedNumber.country || null
			}

			const data = {
				action: 'updateOrCreate',
				event: isEditing ? 'updated' : 'added',
				id: accountId,
				fullname: this.state.inputs.name,
				email: this.state.inputs.email,
				mobileNumber: mobileNumber,
				mobileCountryCode: mobileCountryCode,
				type: this.state.inputs.type,
				expiresAt: this.state.inputs.expiresAt
			}

			if (this.state.inputs.password.length)
				data['password'] = this.state.inputs.password

			this._account
				.create(data)
				.then(response => {
					if (response.success) {
						let message = 'Account saved!'
						toastMessage(message)
						this._setIsSaving(false, () => {
							history.push('/poli/account/list')
						})
					} else {
						this._setIsSaving(false)
					}
				})
				.catch(error => {
					this._setIsSaving(false)
					toastMessage('Save account failed.')
					console.log(error, data)
				})
		}
	}

	_preFetch = async _data => {
		const isEditing = !!this.getAccountId()
		const returnData = {}

		this._account = new PoliAccountUtil(this.getAccountId())

		if (isEditing) {
			const accountData = await this._account
				.get(this.getAccountId())
				.then(({ user }) => user)
				.catch(_error => {
					toastMessage(
						'Oops, something went wrong getting account data!',
						'error'
					)
				})

			returnData.accountData = accountData
		}

		if (isEditing && !returnData.accountData) {
			history.goBack()
			return
		}

		return returnData
	}

	_postFetch = ({ fetchedData, authData }) => {
		const state = { ...this.state }
		const isEditing = !!this.getAccountId()
		const inputs = { ...state.inputs }
		let initialEmail

		if (
			!authData.account.permissions?.includes('poliAccountList') ||
			(!isEditing && !authData.account.permissions?.includes('poliAccountAdd'))
		) {
			return
		}

		if (isEditing) {
			inputs.name = fetchedData.accountData.name
			inputs.email = fetchedData.accountData.email
			inputs.type = fetchedData.accountData.type
			inputs.mobileNumber = fetchedData.accountData.mobile_number
			inputs.expiresAt = FormUtil.formatDate(
				new Date(fetchedData.accountData.expires_at)
			)
			inputs.createdAt = FormUtil.formatDate(
				new Date(fetchedData.accountData.created_at)
			)

			this._currentAccountData = fetchedData.accountData

			initialEmail = inputs.email
		} else {
			inputs.type = 'premium'
			initialEmail = null
		}

		this.setState({
			displayTextFields: !isEditing,
			inputs: inputs,
			initialEmail: initialEmail
		})
	}

	getAccountId = () => {
		const { route } = this.props
		const id = route.match.params.id || this._id

		return !isNaN(id) ? parseInt(id) : null
	}

	_getForm = () => {
		if (this.form) return this.form

		const isEditing = !!this.getAccountId()
		const _options = {
			name: {
				label: 'Name',
				required: true,
				valid: (value, input) => {
					return this._validateName(value, input)
				},
				props: {
					type: 'text',
					value: this.state.inputs.name,
					onBlur: e => {
						this._changeHandler('name', this._cleanUpText(e.target.value))
					},
					onChange: e => {
						this._changeHandler('name', this._capitalize(e.target.value))
					},
					maxLength: 128
				}
			},
			email: {
				label: 'E-mail',
				required: true,
				valid: email => {
					if (!FormUtil.isEmail(email)) {
						toastMessage('Invalid e-mail address.')
						return false
					}
					return true
				},
				props: {
					type: 'email',
					value: this.state.inputs.email,
					onChange: e => {
						this._changeEmailHandler('email', e.target.value)
					}
				}
			},
			mobileNumber: {
				label: 'Phone',
				isEmpty: () => {
					return StringUtil.isEmpty(this.state.inputs.mobileNumber)
				},
				valid: () => {
					if (
						this.state.inputs.mobileNumber?.length &&
						!isPhoneValid(this.state.inputs.mobileNumber)
					) {
						toastMessage('Invalid mobile number.')
						return false
					}
					return true
				},
				props: {
					type: 'text',
					onChange: e => {
						const asYouType = new AsYouType(DEFAULT_COUNTRY)
						this._changeHandler('mobileNumber', asYouType.input(e.target.value))
					},
					placeholder: ''
				}
			},
			password: {
				label: 'Password',
				required: !isEditing,
				valid: value => {
					return this._validatePassword(value)
				},
				props: {
					type: 'text',
					maxLength: 8
				}
			},
			expiresAt: {
				label: 'Expires At',
				required: [true, 'Enter expiration date.'],
				props: {
					type: 'date'
				},
				onchange: e => {
					this._changeHandler('expiresAt', e.target.value)
				},
				valid: value => {
					return this._validateExpiresAt(value)
				}
			},
			save: {
				label: 'Save',
				props: {
					type: 'button',
					onClick: this._handleSave.bind(this)
				}
			}
		}

		return new FormComponent(_options, {
			pageRef: this,
			prefix: 'inputs',
			changeHandler: this._changeHandler
		})
	}

	_getIsSaving = () => {
		return this.state.isSaving
	}

	_setIsSaving = (status, callback) => {
		this.setState(
			{
				isSaving: status
			},
			callback
		)
	}

	_changeEmailHandler = (id, value) => {
		const inputs = { ...this.state.inputs, [id]: value }

		this.setState({ inputs: inputs })
	}

	_validateExpiresAt = value => {
		let valid = true

		if (!value.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/)) {
			valid = false
			toastMessage('Invalid expiration date. Format: YYYY-MM-DD')
		}

		let date = new Date(value)

		if (date < new Date()) {
			valid = false
			toastMessage('Invalid expiration date. Date must be in the future.')
		}

		return valid
	}

	_validatePassword = value => {
		let valid = true
		if (value.length < config.passwordField.minLength) {
			toastMessage(
				`Enter a password with at least ${config.passwordField.minLength} chars.`
			)
			valid = false
		} else if (!new RegExp(config.passwordField.regex).test(value)) {
			toastMessage('Invalid password. Only digits and letters allowed.')
			valid = false
		}
		return valid
	}

	_validateName = (value, _input) => {
		let valid = true

		if (value.split(' ').length < 2) {
			valid = false
			toastMessage('Name needs at least 2 words.')
		}

		return valid
	}

	_capitalize = text => {
		let n = 0,
			len = text.length
		return text
			.split(' ')
			.map((word, index) => {
				n += word.length
				return !index || n === len || word.length > 3
					? word.charAt(0).toUpperCase() + word.substring(1)
					: word
			})
			.join(' ')
	}

	render = () => {
		return (
			<Page
				preFetch={this._preFetch}
				postFetch={this._postFetch}
				render={({ authData }) => {
					const isEditing = !!this.getAccountId()
					const isExpired = new Date(this.state.inputs.expiresAt) < new Date()

					if (
						!authData.account.permissions?.includes('poliAccountList') ||
						(!isEditing &&
							!authData.account.permissions?.includes('poliAccountAdd'))
					) {
						return <Forbidden />
					}

					this.form = this._getForm()

					return (
						<>
							<div className={mainStyles.container}>
								<div className={mainStyles.wrapper}>
									<h3>
										{isEditing
											? `Edit Poli Account #${this.getAccountId()}: ${
													this.state.inputs.name
												}`
											: this.state.inputs.name
												? `Creating: ${this.state.inputs.name}`
												: 'Create a New Poli Account'}
									</h3>

									<div className={styles.headingWrapper}>
										<div className={styles.leftSide}>
											{this.form.renderInput('name')}
											{this.form.renderInput('email')}
											{this.form.renderInput('mobileNumber')}
											{this.form.renderInput('password')}
											{this.form.renderInput('expiresAt')}
										</div>

										<div className={styles.rightSide}>
											{isEditing
												? this._renderDisabledTextInput({
														label: 'Created At',
														value: this.state.inputs.createdAt
													})
												: null}
											{isEditing && isExpired ? (
												<p className={styles.accountExpiredLabel}>
													This account is expired and the user is not able to
													log in!
												</p>
											) : null}
										</div>
									</div>

									{((!isEditing &&
										authData.account.permissions?.includes('poliAccountAdd')) ||
										(isEditing &&
											authData.account.permissions?.includes(
												'poliAccountEdit'
											))) &&
										this.form.renderButton('save', {
											loading: this.state.isSaving
										})}
								</div>
							</div>
						</>
					)
				}}
			/>
		)
	}
}
