import React from 'react'
import { Page, toastMessage, Button, Forbidden } from '../../components'
import { FormComponent } from '../../shared'
import {
	ReviewUtil,
	RequestUtil,
	CategoryUtil,
	TagUtil,
	AuthorUtil,
	FormUtil
} from '../../shared'
import config from '../../config.json'
import history from '../../history'
import {
	validationRegexes,
	renderCurrencyWarning
} from '../ReviewValidator/utils'
import mainStyles from '../../assets/css/App.module.scss'
import formStyles from '../../assets/css/partials/Form.module.scss'
import styles from './Review.module.scss'

export class Review extends React.PureComponent {
	state = {
		authorData: [],
		categoryData: [],
		voiceData: [
			{ value: 'en-US-Wavenet-D', label: 'David' },
			{ value: 'en-US-Wavenet-H', label: 'Helen' },
			{ value: 'en-US-Wavenet-B', label: 'Bob' },
			{ value: 'en-US-Wavenet-C', label: 'Charlotte' },
			{ value: 'pqHfZKP75CvOlQylNhV4', label: 'Bill' },
			{ value: 'nPczCjzI2devNBz1zQrb', label: 'Brian' },
			{ value: 'iP95p4xoKVk53GoZ742B', label: 'Chris' },
			{ value: 'TX3LPaxmHKxFdv7VOQHJ', label: 'Liam' },
			{ value: 'XrExE9yKIg1WjnnlVkGX', label: 'Matilda' },
			{ value: 'flq6f7yk4E4fJM5XTYuZ', label: 'Michael' },
			{ value: '21m00Tcm4TlvDq8ikWAM', label: 'Rachel' }
		],
		proficiencyLevelData: [
			{ value: 'beginner', label: 'Beginner' },
			{ value: 'intermediate', label: 'Intermediate' },
			{ value: 'advanced', label: 'Advanced' }
		],
		tagData: [],
		sinopsysChars: 0,
		inputs: {
			title: '',
			subtitle: '',
			summary: '',
			category: '',
			author: '',
			tags: [],
			textEn: '',
			textPt: '',
			cover: [],
			voice: '',
			proficiencyLevel: ''
		},
		textEnTrackCount: 1,
		textPtTrackCount: 1,
		displayTextFields: false,
		isSaving: false,
		textPtHighlightedLines: [],
		textEnHighlightedLines: [],
		hasCurrencyMistake: false
	}

	_currentReviewData = {}
	_allowedTags = ['headline']

	_getLanguageIndex = languageKey => {
		return `text${languageKey.charAt(0).toUpperCase() + languageKey.slice(1)}`
	}

	_getTrackCount = languageKey => {
		return this.state.inputs[this._getLanguageIndex(languageKey)].split(/\n\n/)
			.length
	}

	_getParagraphCount = text => {
		return text.split(/\n\n/).length
	}

	_getIsSaving = () => {
		return this.state.isSaving
	}

	_setIsSaving = (status, callback) => {
		this.setState(
			{
				isSaving: status
			},
			callback
		)
	}

	_handleSave = () => {
		if (!this._getIsSaving() && this.form.valid()) {
			const [hasWarning, toastMessages] = this._checkWarning()
			if (hasWarning) {
				if (
					!confirm(
						'Warning!\nThere might be errors in the ENGLISH text. Check the highlighted lines for details.\n\nIf you are sure there are no errors, click OK to proceed.'
					)
				) {
					toastMessages.forEach(message => toastMessage(...message))
					return
				}
			}

			const data = {
				// bodyChanged: true, // FIXME: remove that after implementing checksum on backend
				coverHash: this._coverHash || null,
				id: this.getReviewId(),
				author: {
					id: this.state.inputs.author.value
				},
				category: {
					id: this.state.inputs.category.value
				},
				voice: {
					id: this.state.inputs.voice.value
				},
				proficiencyLevel: this.state.inputs.proficiencyLevel.value,
				reviewTag: this.state.inputs.tags.map(item => ({
					tag: {
						id: item.value
					}
				})),
				title: this.state.inputs.title,
				subtitle: this.state.inputs.subtitle,
				summary: this.state.inputs.summary,
				pageSize: 3, // FIXME: allow to edit page size from UI
				pagesToDownload: 2, // FIXME: allow to edit pages to download from UI
				body: {
					en: this.state.inputs.textEn,
					pt: this.state.inputs.textPt
				}
			}

			this._setIsSaving(true)
			this._review
				.save(data)
				.then(response => {
					if ([200, 201].includes(response.status)) {
						toastMessage('Book saved!')
						this._setIsSaving(false, () => {
							history.push('/review/list')
						})
					} else if (response.status === 409) {
						toastMessage('Title is in use by another review')
						console.log(response, data)
						this._setIsSaving(false)
					} else {
						toastMessage('Oops, something went wrong...', 'error')
						console.log(response, data)
						this._setIsSaving(false)
					}
				})
				.catch(error => {
					this._setIsSaving(false)
					toastMessage('Save review failed.')
					console.log(error, data)
				})
		}
	}

	_cleanUpText = text => {
		return text
			.replace(/\n\s*\n\s*\n/g, '\n\n') // remove triplicated line breaks
			.replace(/[ ]+/g, ' ') // remove duplicated spaces
	}

	_changeHandler = (id, value, callback) => {
		const inputs = { ...this.state.inputs }

		inputs[id] = value
		this.setState(
			{
				inputs: inputs
			},
			callback
		)
	}

	_validateEnText = () => {
		let valid = true
		const textEnTrackCount = this._getTrackCount('en')

		if (textEnTrackCount < 3) {
			valid = false
			toastMessage('English text must have at least 3 tracks.')
		} else {
			const checkStructureResult = this._checkTextStructure(
				this.state.inputs.textEn,
				this.state.inputs.textPt,
				'en'
			)

			if (checkStructureResult.length) {
				valid = false
				toastMessage(
					'English text has structural issues. See highlighted lines to get more info.'
				)

				this.setState({
					textEnHighlightedLines: checkStructureResult
				})
			} else {
				this.setState({
					textEnHighlightedLines: []
				})
			}
		}

		return valid
	}

	_checkTrackCount = () => {
		const textEnTrackCount = this._getTrackCount('en')
		const textPtTrackCount = this._getTrackCount('pt')

		return textEnTrackCount === textPtTrackCount
	}

	_getTracksArray = languageKey => {
		return this.state.inputs[this._getLanguageIndex(languageKey)].split(/\n\n/)
	}

	_getInvalidTags = text => {
		const invalidTags =
			text.match(/<([^a-z/]+[a-z]+|[a-z]+[^a-z]|[^a-z/]+[a-z]+[^a-z]+)>/gm) ||
			[]

		return invalidTags
	}

	_getOpeningTags = (text, tag = '[a-z]+') => {
		const regex = new RegExp(`<${tag}>`, 'gm')
		return text.match(regex) || []
	}

	_getClosingTags = (text, tag = '[a-z]+') => {
		const regex = new RegExp(`</${tag}>`, 'gm')
		return text.match(regex) || []
	}

	_checkTextStructure = (text, pairedText, lang) => {
		let errors = []
		let invalidTags = false
		let closingTags
		let openingTags
		let unallowedTagsFound
		let originalTagArray
		let pairedTagArray
		let message
		let openingAndClosingTagsMerged
		const lines = text.split(/\n/)

		lines.forEach((lineText, lineIndex) => {
			invalidTags = this._getInvalidTags(lineText)

			if (invalidTags.length) {
				errors.push({
					message: `Invalid tags found: ${invalidTags.join(', ')}.`,
					line: lineIndex + 1
				})
			}
		})

		if (!errors.length) {
			lines.forEach((lineText, lineIndex) => {
				unallowedTagsFound = []

				openingTags = this._getOpeningTags(lineText)
				closingTags = this._getClosingTags(lineText)
				openingAndClosingTagsMerged = [...openingTags, ...closingTags]

				openingAndClosingTagsMerged.forEach(tag => {
					if (!this._allowedTags.includes(tag.replace(/[<>/]/g, ''))) {
						unallowedTagsFound.push(tag)
					}
				})

				if (unallowedTagsFound.length) {
					errors.push({
						message: `Unallowed tags found: ${unallowedTagsFound.join(', ')}.`,
						line: lineIndex + 1
					})
				}
			})

			if (!errors.length) {
				lines.forEach((lineText, lineIndex) => {
					openingTags = this._getOpeningTags(lineText)
					closingTags = this._getClosingTags(lineText)

					if (closingTags.length !== openingTags.length) {
						errors.push({
							message: `Opening and closing tags count doesn't match: ${openingTags.length} opening, ${closingTags.length} closing.`,
							line: lineIndex + 1
						})
					} else {
						const reverseClosingTags = closingTags.reverse()

						openingTags.some((tag, tagIndex) => {
							if (tag !== reverseClosingTags[tagIndex].replace(/<\//, '<')) {
								errors.push({
									message: `Tag ${tag} is NOT closed or it is being closed on the wrong order.`,
									line: lineIndex + 1
								})

								return true
							}

							return false
						})
					}
				})

				if (!errors.length) {
					const pairedTextLines = pairedText.split(/\n/)

					lines.forEach((lineText, lineIndex) => {
						if (pairedTextLines[lineIndex]) {
							originalTagArray = this._getOpeningTags(lineText)
							pairedTagArray = this._getOpeningTags(pairedTextLines[lineIndex])

							if (originalTagArray.length !== pairedTagArray.length) {
								if (pairedTagArray.length) {
									message = `Mismatch: compared to the other version, this line should contain exactly ${
										pairedTagArray.length
									} tag${
										pairedTagArray.length > 1 ? 's' : ''
									}, but it contains ${originalTagArray.length}.`
								} else {
									message = `Mismatch: compared to the other version, this line shouldn't contain any tags, but it contains ${originalTagArray.length}.`
								}

								errors.push({
									message: message,
									line: lineIndex + 1
								})
							}
						}
					})
				}
			}
		}

		if (!errors.length && lang === 'en') {
			let hasErrors = false
			lines.forEach((lineText, lineIndex) => {
				const [hasMistakes, message] = this._checkCurrencyText(lineText)
				if (hasMistakes) {
					errors.push({
						message,
						line: lineIndex + 1
					})
					hasErrors = true
				}
			})
			this.setState({
				hasCurrencyMistake: hasErrors
			})
			if (hasErrors) {
				toastMessage(
					'There are errors with currency formats. Check the marked lines and see the rules for currency text.'
				)
			}
		}

		return errors
	}

	_checkWarning = () => {
		let hasWarning = false
		let errors = []
		let toastMessages = []

		// check for mispelled number with decimals
		const { decimalRegexes } = validationRegexes
		const lines = this.state.inputs.textEn.split(/\n/)
		lines.forEach((lineText, lineIndex) => {
			if (
				lineText.match(decimalRegexes.currency) ||
				lineText.match(decimalRegexes.percentage)
			) {
				hasWarning = true
				errors.push({
					message: decimalRegexes.lineMessage,
					line: lineIndex + 1,
					type: 'warning'
				})
			}
		})
		if (hasWarning) {
			this.setState({
				textEnHighlightedLines: errors
			})
			toastMessages.push([decimalRegexes.toastMessage, 'warning'])
		}

		return [hasWarning, toastMessages]
	}

	_checkLineCount = () => {
		return (
			this.state.inputs.textEn.split(/\n/).length ===
			this.state.inputs.textPt.split(/\n/).length
		)
	}

	_validatePtText = () => {
		let valid = true

		if (!this._checkLineCount()) {
			valid = false
			toastMessage('Both texts must have the exact same line counting.')
		} else if (!this._checkTrackCount()) {
			valid = false
			toastMessage(
				'Portuguese text must have the exact same track count as the English version.'
			)
		} else {
			const checkStructureResult = this._checkTextStructure(
				this.state.inputs.textPt,
				this.state.inputs.textEn,
				'pt'
			)

			if (checkStructureResult.length) {
				valid = false
				toastMessage(
					"Portuguese text should mirror English version's structure. See highlighted lines to get more info."
				)

				this.setState({
					textPtHighlightedLines: checkStructureResult
				})
			} else {
				this.setState({
					textPtHighlightedLines: []
				})
			}
		}

		return valid
	}

	_validateTitle = value => {
		let valid = true

		if (value.length < 2) {
			valid = false
			toastMessage('Title is too short. Use at least 2 chars.')
		}

		return valid
	}

	_validateSubtitle = value => {
		let valid = true

		if (value.length < 9) {
			valid = false
			toastMessage('Subtitle is too short. Use at least 9 chars.')
		}

		return valid
	}

	_validateSummary = value => {
		let valid = true

		if (value.length < 140) {
			valid = false
			toastMessage('Sinopsys is too short. Use at least 140 chars.')
		} else if (value.split(' ').length > 150) {
			valid = false
			toastMessage('Sinopsys should have a maximum of 150 words.')
		} else if (this._getParagraphCount(value) > 3) {
			valid = false
			toastMessage('Sinopsys should have a maximum of 3 paragraphs.')
		}

		return valid
	}

	_updateTrackCount = () => {
		this.setState({
			textEnTrackCount: this._getTrackCount('en'),
			textPtTrackCount: this._getTrackCount('pt')
		})
	}

	_cleanUpTracks = text => {
		const tracksArray = text.split(/\n\n/)

		return tracksArray.map(item => item.trim()).join('\n\n')
	}

	getReviewId = () => {
		const { route } = this.props
		const id = route.match.params.id || this._id

		return !isNaN(id) ? parseInt(id) : null
	}

	_capitalize = text => {
		return text
			.split(' ')
			.map((word, index) =>
				!index || word.length > 2
					? word.charAt(0).toUpperCase() + word.substring(1)
					: word
			)
			.join(' ')
	}

	_checkCurrencyText = text => {
		// check for redundant currency text
		const { redundancyRegex, currencyRegex, uppercaseRegex, checkRegexes } =
			validationRegexes

		if (
			text.match(redundancyRegex.numericForm) ||
			text.match(redundancyRegex.writtenForm)
		) {
			return [true, redundancyRegex.message]
		}

		const matches = text.match(currencyRegex)
		if (!matches) return [false, ''] // there are no currency terms found

		// check for case errors
		const insensitiveRegex = new RegExp(uppercaseRegex.regex, 'gi')
		const sensitiveRegex = new RegExp(uppercaseRegex.regex, 'g')
		for (let i = 0; i < matches.length; i++) {
			const match = matches[i]
			const insensitiveMatch = match.match(insensitiveRegex)
			const sensitiveMatch = match.match(sensitiveRegex)
			if (insensitiveMatch && !sensitiveMatch) {
				// returns upon first mistake found
				return [true, uppercaseRegex.message]
			}
		}

		for (let i = 0; i < matches.length; i = i + 1) {
			let isWrongFormat = true
			const textMatch = matches[i]
			for (let j = 0; j < checkRegexes.regex.length; j = j + 1) {
				if (textMatch.match(checkRegexes.regex[j])) {
					isWrongFormat = false
					break
				}
			}
			if (isWrongFormat) {
				// returns upon first mistake found
				return [true, checkRegexes.message]
			}
		}
		return [false, '']
	}

	_getForm = () => {
		const inputs = {
			title: {
				label: 'Title',
				required: true,
				valid: input => {
					return this._validateTitle(input)
				},
				props: {
					type: 'text',
					value: this.state.inputs.title,
					onBlur: e => {
						this._changeHandler('title', this._cleanUpTracks(e.target.value))
					},
					onChange: e => {
						this._changeHandler(
							'title',
							this._capitalize(this._cleanUpText(e.target.value))
						)
					}
				}
			},
			subtitle: {
				label: 'Subtitle',
				required: true,
				valid: input => {
					return this._validateSubtitle(input)
				},
				props: {
					type: 'text',
					value: this.state.inputs.subtitle,
					onBlur: e => {
						this._changeHandler('subtitle', this._cleanUpTracks(e.target.value))
					},
					onChange: e => {
						this._changeHandler(
							'subtitle',
							this._capitalize(this._cleanUpText(e.target.value))
						)
					}
				}
			},
			summary: {
				label: 'Sinopsys',
				renderLabel: label => {
					return (
						<div>
							<p style={{ float: 'left' }}>{label}</p>
							<p style={{ float: 'right' }}>{this.state.sinopsysChars} chars</p>
						</div>
					)
				},
				required: true,
				valid: input => {
					return this._validateSummary(input)
				},
				props: {
					type: 'textarea',
					value: this.state.inputs.summary,
					onBlur: e => {
						this._changeHandler('summary', this._cleanUpTracks(e.target.value))
					},
					onChange: e => {
						this._changeHandler(
							'summary',
							this._cleanUpText(e.target.value),
							() => {
								this.setState({
									sinopsysChars: this.state.inputs.summary.length
								})
							}
						)
					}
				}
			},
			author: {
				label: 'Author',
				required: [true, 'Choose an author.'],
				props: {
					type: 'creatableSelect',
					isLoading: this.state.isCreatingAuthor,
					isDisabled: this.state.isCreatingAuthor,
					onCreateOption: name => {
						name = this._capitalize(name)
						this.setState(
							{
								isCreatingAuthor: true
							},
							() => {
								const author = new AuthorUtil()

								author
									.create({
										name: name
									})
									.then(result => {
										if (result.status === 201) {
											toastMessage(`New author created: ${name}`)

											const state = { ...this.state }
											const value = {
												value: result.data.id,
												label: name
											}
											const authorData = [...state.authorData, value]

											this.setState(
												{
													authorData: authorData
												},
												() => {
													this._changeHandler('author', value)
												}
											)
										} else if (result.status === 409) {
											toastMessage(`Author ${name} already exists!`)
										} else {
											toastMessage(`Couldn't create author ${name}`)
										}
									})
									.catch(error => {
										toastMessage(`Error creating author ${name}`)
										console.log(error)
									})
									.finally(() => {
										this.setState({
											isCreatingAuthor: false
										})
									})
							}
						)
					},
					value: this.state.inputs.author,
					onChange: value => {
						this._changeHandler('author', value)
					}
				},
				options: this.state.authorData
			},
			category: {
				label: 'Category',
				required: [true, 'Choose a category.'],
				props: {
					type: 'select',
					value: this.state.inputs.category,
					onChange: value => {
						this._changeHandler('category', value)
					}
				},
				options: this.state.categoryData
			},
			tags: {
				label: 'Tags',
				required: [true, 'Choose at least one tag.'],
				props: {
					type: 'select', // type: 'creatableSelect' may lead to several issues if creating tags unduly
					isLoading: this.state.isCreatingTags,
					isDisabled: this.state.isCreatingTags,
					onCreateOption: name => {
						name = this._capitalize(name)
						this.setState(
							{
								isCreatingTags: true
							},
							() => {
								const tag = new TagUtil()

								tag
									.create({
										name: name
									})
									.then(result => {
										if (result.status === 201) {
											toastMessage(`New tag created: ${name}`)

											const state = { ...this.state }
											const value = {
												value: result.data.id,
												label: name
											}
											const tagData = [...state.tagData, value]

											this.setState(
												{
													tagData: tagData
												},
												() => {
													const newValue = [...this.state.inputs.tags, value]
													this._changeHandler('tags', newValue)
												}
											)
										} else if (result.status === 409) {
											toastMessage(`Tag ${name} already exists!`)
										} else {
											toastMessage(`Couldn't create tag ${name}`)
										}
									})
									.catch(error => {
										toastMessage(`Error creating tag ${name}`)
										console.log(error)
									})
									.finally(() => {
										this.setState({
											isCreatingTags: false
										})
									})
							}
						)
					},
					isMulti: true,
					value: this.state.inputs.tags,
					onChange: value => {
						this._changeHandler('tags', value)
					}
				},
				options: this.state.tagData
			},
			textEn: {
				label: `ENGLISH text${
					this.state.textEnTrackCount > 1
						? ` - (${this.state.textEnTrackCount}) tracks`
						: ''
				}`,
				required: true,
				valid: () => {
					return this._validateEnText()
				},
				props: {
					type: 'numeredTextarea',
					minHeight: 200,
					value: this.state.inputs.textEn,
					highlightedLines: this.state.textEnHighlightedLines,
					onBlur: ref => {
						this._changeHandler('textEn', this._cleanUpTracks(ref.value))
					},
					onChange: ref => {
						this._changeHandler('textEn', this._cleanUpText(ref.value), () => {
							this._updateTrackCount()
						})
					}
				}
			},
			textPt: {
				label: `PORTUGUESE text${
					this.state.textPtTrackCount > 1
						? ` - (${this.state.textPtTrackCount}) tracks`
						: ''
				}`,
				required: true,
				valid: () => {
					return this._validatePtText()
				},
				props: {
					type: 'numeredTextarea',
					minHeight: 200,
					value: this.state.inputs.textPt,
					highlightedLines: this.state.textPtHighlightedLines,
					onBlur: ref => {
						this._changeHandler('textPt', this._cleanUpTracks(ref.value))
					},
					onChange: ref => {
						this._changeHandler('textPt', this._cleanUpText(ref.value), () => {
							this._updateTrackCount()
						})
					}
				}
			},
			voice: {
				label: 'Voice',
				required: [true, 'Choose a voice'],
				props: {
					type: 'select',
					value: this.state.inputs.voice,
					onChange: value => {
						this._changeHandler('voice', value)
					}
				},
				options: this.state.voiceData
			},
			proficiencyLevel: {
				label: 'Proficiency level',
				required: [true, 'Choose a proficiency level'],
				props: {
					type: 'select',
					value: this.state.inputs.proficiencyLevel,
					onChange: value => {
						this._changeHandler('proficiencyLevel', value)
					}
				},
				options: this.state.proficiencyLevelData
			},
			cover: {
				label: 'Cover',
				required: [true, 'Review cover is missing.'],
				props: {
					type: 'file',
					files: this.state.inputs.cover,
					server: {
						load: {
							url: `${config.apiBaseUrl}/review/cover?manager=true&reviewId=`,
							method: 'GET',
							withCredentials: false,
							headers: this._coverGetHeaders,
							timeout: 20000
						},
						process: {
							url: config.filePondBaseUrl,
							onload: response => {
								if (response) this._coverHash = response
							},
							method: 'POST',
							timeout: 20000
						}
					},
					allowFilesSync: false,
					allowMultiple: false,
					maxFiles: 1,
					onupdatefiles: files => {
						const state = { ...this.state }

						this.setState({
							inputs: {
								...state.inputs,
								cover: [...files]
							}
						})
					},
					allowReplace: true,
					instantUpload: true,
					allowRevert: true,
					allowFileTypeValidation: true,
					acceptedFileTypes: ['image/png', 'image/jpeg'],
					allowImageResize: true,
					imageResizeMode: 'contain',
					imageResizeTargetWidth: config.coverSize,
					imageResizeTargetHeight: config.coverSize,
					imageResizeUpscale: true,
					allowImageTransform: true,
					imageTransformOutputMimeType: 'image/jpeg',
					imageTransformOutputQuality: 100,
					imageTransformOutputQualityMode: 'optional',
					allowImageCrop: true,
					imageCropAspectRatio: '1:1',
					allowFileSizeValidation: true,
					maxFileSize: '5MB',
					maxTotalFileSize: '5MB'
				}
			},
			save: {
				label: 'Save',
				props: {
					type: 'button',
					onClick: this._handleSave
				}
			}
		}

		return new FormComponent(inputs)
	}

	_preFetch = async (data, settings) => {
		const isEditing = !!this.getReviewId()
		const author = new AuthorUtil()
		const category = new CategoryUtil()
		const tag = new TagUtil()
		const authorData = await author
			.getData({}, settings.request)
			.catch(() =>
				toastMessage('Oops, something went wrong getting author data!', 'error')
			)
		const categoryData = await category
			.getData({}, settings.request)
			.catch(() =>
				toastMessage(
					'Oops, something went wrong getting category data!',
					'error'
				)
			)
		const tagData = await tag
			.getData({}, settings.request)
			.catch(() =>
				toastMessage('Oops, something went wrong getting tag data!', 'error')
			)
		const returnData = {
			authorData: authorData.map(item => ({
				value: item.id,
				label: item.name
			})),
			categoryData: categoryData.map(item => ({
				value: item.id,
				label: item.name
			})),
			tagData: tagData.map(item => ({
				value: item.id,
				label: item.name
			}))
		}

		this._coverGetHeaders = await RequestUtil.getHeaders(
			RequestUtil.GET,
			'/review/cover',
			false
		)
		this._review = new ReviewUtil(this.getReviewId())

		if (isEditing) {
			const reviewData = await this._review
				.getInfo({}, settings.request)
				.then(result => result.data)
				.catch(() =>
					toastMessage('Oops, something went wrong getting book data!', 'error')
				)

			returnData.reviewData = reviewData
		}

		return returnData
	}

	_postFetch = data => {
		const { fetchedData } = data
		const state = { ...this.state }
		const isEditing = !!this.getReviewId()
		const newState = {}

		newState.authorData = fetchedData.authorData
		newState.categoryData = fetchedData.categoryData
		newState.tagData = fetchedData.tagData
		newState.displayTextFields = !isEditing

		if (isEditing) {
			newState.inputs = { ...state.inputs }
			newState.inputs.title = fetchedData.reviewData.title
			newState.inputs.subtitle = fetchedData.reviewData.subtitle
			newState.inputs.summary = fetchedData.reviewData.summary
			newState.sinopsysChars = fetchedData.reviewData.summary.length
			newState.inputs.author = newState.authorData.find(
				item => item.value === fetchedData.reviewData.author.id
			)
			newState.inputs.category = newState.categoryData.find(
				item => item.value === fetchedData.reviewData.category.id
			)
			newState.inputs.voice = this.state.voiceData.find(
				item => item.value === fetchedData.reviewData.version.voice
			)
			newState.inputs.proficiencyLevel = this.state.proficiencyLevelData.find(
				item => item.value === fetchedData.reviewData.version.proficiencyLevel
			)
			newState.inputs.textEn = fetchedData.reviewData.version.body.en
			newState.inputs.textPt = fetchedData.reviewData.version.body.pt
			this._currentReviewData = fetchedData.reviewData

			newState.inputs.cover = [
				{
					source: this.getReviewId().toString(),
					options: {
						type: 'local'
					}
				}
			]

			const reviewTags = fetchedData.reviewData.reviewTag.map(
				item => item.tag.id
			)
			newState.inputs.tags = newState.tagData.filter(item =>
				reviewTags.includes(item.value)
			)
		}

		this.setState(
			{
				...newState
			},
			() => {
				this._updateTrackCount()
			}
		)
	}

	_toggleTextsWrapperVisibility = () => {
		this.setState({
			displayTextFields: !this.state.displayTextFields
		})
	}

	_renderDisabledTextInput = ({ label, value }) => {
		return (
			<div className={formStyles.inputWrapper}>
				<label>
					<p>{label}</p>
					<div>{value}</div>
				</label>
			</div>
		)
	}

	render = () => {
		return (
			<Page
				preFetch={this._preFetch}
				postFetch={this._postFetch}
				render={({ authData }) => {
					const textFieldsWrapperClasses = [styles.textFieldsWrapper]
					const textFieldsToggleButtonClasses = []
					const isEditing = !!this.getReviewId()

					if (
						!authData.account.permissions?.includes('reviewList') ||
						(!isEditing &&
							!authData.account.permissions?.includes('reviewManagement'))
					) {
						return <Forbidden />
					}

					if (this.state.displayTextFields) {
						textFieldsWrapperClasses.push(styles.visible)
						textFieldsToggleButtonClasses.push(formStyles.active)
					}

					this.form = this._getForm()

					return (
						<>
							<div className={mainStyles.container}>
								<div className={mainStyles.wrapper}>
									<h3>
										{isEditing
											? `Edit Book #${this.getReviewId()}: ${
													this.state.inputs.title
											  }`
											: this.state.inputs.title
											? `Creating: ${this.state.inputs.title}`
											: 'Create a New Book'}
									</h3>

									<div className={styles.headingWrapper}>
										<div className={styles.leftSide}>
											{this.form.renderInput('title')}
											{this.form.renderInput('subtitle')}
											{this.form.renderInput('summary')}
											{this.form.renderInput('author')}
											{this.form.renderInput('category')}
											{this.form.renderInput('tags')}
											{this.form.renderInput('voice')}
											{this.form.renderInput('proficiencyLevel')}
											{isEditing ? (
												<div className={formStyles.inputWrapper}>
													{!this.state.displayTextFields ? (
														<Button
															classes={textFieldsToggleButtonClasses}
															size="small"
															onClick={this._toggleTextsWrapperVisibility}
														>
															Display Book Texts...
														</Button>
													) : null}
												</div>
											) : null}

											<div className={textFieldsWrapperClasses.join(' ')}>
												{this.state.hasCurrencyMistake &&
													renderCurrencyWarning()}
												{this.form.renderInput('textEn')}
												{this.form.renderInput('textPt')}
											</div>
										</div>

										<div className={styles.rightSide}>
											{this.form.renderInput('cover')}
											{isEditing
												? this._renderDisabledTextInput({
														label: 'Created At',
														value: FormUtil.formatDate(
															new Date(this._currentReviewData.createdAt)
														)
												  })
												: null}
											{isEditing
												? this._renderDisabledTextInput({
														label: 'Updated At',
														value: FormUtil.formatDate(
															new Date(this._currentReviewData.updatedAt)
														)
												  })
												: null}
										</div>
									</div>

									{authData.account.permissions?.includes('reviewManagement') &&
										this.form.renderButton('save', {
											loading: this.state.isSaving
										})}
								</div>
							</div>
						</>
					)
				}}
			/>
		)
	}
}
