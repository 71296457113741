import { startCase } from 'lodash'

const permissionNames = [
	'permissionManagement',
	'programList',
	'programManagement',
	'reviewList',
	'reviewManagement',
	'accountList',
	'accountEdit',
	'accountAdd',
	'accountBulkImport',
	'linkList',
	'linkManagement',
	'reports',
	'poliAccountList',
	'poliAccountEdit',
	'poliAccountAdd',
	'reviser',
	'emailFix',
	'crmLeads',
	'crmEventsTriggering'
]

const permissions = permissionNames.map(permission => ({
	label: startCase(permission),
	value: permission
}))

export { permissions }
