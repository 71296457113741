export default {
	red: '#cf3d44',
	orange: '#fdae30',
	green: '#11b072',
	blue: '#1a7992',
	purple: '#6f1a92',
	pink: '#c20990',
	cyan: '#19c4be',
	yellow: '#e9e100'
}
