import React from 'react'
import { Page, toastMessage, Forbidden } from '../../components'
import { FormComponent, FormUtil, AccountUtil, StringUtil } from '../../shared'
import history from '../../history'
import mainStyles from '../../assets/css/App.module.scss'
import formStyles from '../../assets/css/partials/Form.module.scss'
import styles from './Account.module.scss'
import config from '../../config.json'
import axios from 'axios'
import { AsYouType } from 'libphonenumber-js/max'
import {
	DEFAULT_COUNTRY,
	getPhoneNumberFragments,
	isPhoneValid
} from '../../shared/form'
import { AddOptionsBar } from './AddOptionsBar'
import { capitalize } from 'lodash'

export class Account extends React.PureComponent {
	state = {
		inputs: {
			name: '',
			email: '',
			mobileNumber: '',
			type: '',
			password: '',
			expiresAt: '',
			createdAt: null,
			addToGetResponse: null
		},
		initialEmail: null,
		isSaving: false
	}

	constructor(props) {
		super(props)
		this._changeHandler = this._changeHandler.bind(this)
	}

	_currentAccountData = {}

	_getIsSaving = () => {
		return this.state.isSaving
	}

	_setIsSaving = (status, callback) => {
		this.setState(
			{
				isSaving: status
			},
			callback
		)
	}

	getIsIdiomusEmail(email) {
		return email.match(/@idiomus\.com$/)
	}

	async _handleSave(authData) {
		if (!this._getIsSaving() && this.form.valid()) {
			let emailStatus = { available: false, type: '' }
			let errors = false
			const accountId = this.getAccountId()
			const isEditing = !!accountId

			this._setIsSaving(true)

			if (
				this.state.inputs.type.value === 'admin' &&
				!this.getIsIdiomusEmail(this.state.inputs.email)
			) {
				errors = true
				toastMessage('Admin accounts must be on idiomus.com', 'error')
				this._setIsSaving(false)
				return
			}

			if (
				this.getIsIdiomusEmail(this.state.inputs.email) &&
				this.form.getVisibleFlag('addToGetResponse') &&
				this.state.inputs.addToGetResponse
			) {
				errors = true
				toastMessage('Idiomus accounts cannot be added to GetResponse', 'error')
				this._setIsSaving(false)
				return
			}

			await this._account
				.verifyEmail(
					{ email: this.state.inputs.email },
					{ source: axios.CancelToken.source() }
				)
				.then(response => {
					if ([200].includes(response.status)) {
						emailStatus = { ...response.data }
					} else {
						toastMessage('Oops, something went wrong...', 'error')
						console.log(response)
						errors = true
					}
				})
				.catch(error => {
					toastMessage('Error accessing the server.', 'error')
					console.log(error)
					errors = true
				})

			if (errors) {
				this._setIsSaving(false)
				return
			}

			if (emailStatus.available !== true) {
				let confirmMsg
				switch (emailStatus.type) {
					case 'freemium':
						if (isEditing)
							confirmMsg =
								'This e-mail address is in use by a Freemium account.\n' +
								'Delete the Freemium account and continue?'
						else
							confirmMsg =
								'This e-mail address is in use by a Freemium account.\n' +
								'Upgrade this Freemium account to Premium, using the data entered?'
						if (!window.confirm(confirmMsg)) {
							this.form.setErrorFlag('email')
							this._setIsSaving(false)
							return
						}
						break
					case 'free':
						toastMessage(
							'This e-mail address belongs to a deactivated account.'
						)
						this.form.setErrorFlag('email')
						return
					case 'premium':
					default:
						toastMessage(
							'This e-mail address is in use by another Premium account.'
						)
						this.form.setErrorFlag('email')
						this._setIsSaving(false)
						return
				}
			}

			let mobileNumber = null
			let mobileCountryCode = null

			if (this.state.inputs.mobileNumber?.length) {
				const fragments = getPhoneNumberFragments(
					this.state.inputs.mobileNumber
				)
				mobileNumber = fragments.international
				mobileCountryCode = fragments.country || null
			}

			let expiresAt = new Date(this.state.inputs.expiresAt)
			expiresAt.setUTCHours(20, 59, 0, 0)

			const data = {
				id: accountId,
				name: this.state.inputs.name,
				email: this.state.inputs.email,
				mobileNumber: mobileNumber,
				mobileCountryCode: mobileCountryCode,
				type: this.state.inputs.type.value,
				expiresAt: expiresAt.toJSON(),
				addToGetResponse: this.form.getVisibleFlag('addToGetResponse')
					? !!this.state.inputs.addToGetResponse
					: null
			}

			if (
				isEditing &&
				this.getIsIdiomusEmail(this.state.inputs.email) &&
				authData.account.permissions?.includes('permissionManagement')
			) {
				const permissionRes = await this._account.savePermissions({
					accountId: accountId,
					type: this.state.inputs.type.value
				})

				if (permissionRes.status !== 200) {
					toastMessage('Error saving account type')
					this._setIsSaving(false)
					return
				}
			}

			if (this.state.inputs.password.length)
				data['password'] = this.state.inputs.password

			this._account
				.save(data)
				.then(response => {
					if ([200, 201].includes(response.status)) {
						const { status } = response.data
						let message = status !== undefined ? status : 'Account saved!'
						toastMessage(message)
						this._setIsSaving(false, () => {
							history.push('/account/list')
						})
					} else if (response.status === 400) {
						const { type, field, data } = response.data.error
						const fieldName = this.form.getLabel(field)

						if (type === 'INVALID_FIELD') {
							if (field === 'email') {
								toastMessage(
									"E-mail address seems to be incorrect. If you're sure it's correct, please contact an admin."
								)
							} else {
								toastMessage(fieldName + ' is invalid.')
							}
						} else if (type === 'EMPTY_FIELD') {
							toastMessage(fieldName + ' is required.')
						}

						this._setIsSaving(false)
						this.form.setErrorFlag(field)
						console.warn(type, field, data, response)
					} else if (response.status === 409) {
						const { type, field, data } = response.data.error
						this._setIsSaving(false)
						toastMessage('E-mail already exists')
						this.form.setErrorFlag('email')
						console.warn(type, field, data, response)
					} else {
						this._setIsSaving(false)
						toastMessage('Oops, something went wrong...', 'error')
						console.log(response, data)
					}
				})
				.catch(error => {
					this._setIsSaving(false)
					toastMessage('Save account failed.')
					console.log(error, data)
				})
		}
	}

	_cleanUpText = text => {
		return text.trim().replace(/[ ]+/g, ' ') // remove duplicated spaces
	}

	_changeHandler = (id, value, callback) => {
		const inputs = { ...this.state.inputs, [id]: value }

		this.setState(
			{
				inputs: inputs
			},
			callback
		)
	}

	_changeEmailHandler = (id, value) => {
		const inputs = { ...this.state.inputs, [id]: value }

		this.form.setVisibleFlag(
			'addToGetResponse',
			value !== this.state.initialEmail
		)

		this.setState({ inputs: inputs })
	}

	_validatePassword = value => {
		let valid = true
		if (value.length < config.passwordField.minLength) {
			toastMessage(
				`Enter a password with at least ${config.passwordField.minLength} chars.`
			)
			valid = false
		} else if (!new RegExp(config.passwordField.regex).test(value)) {
			toastMessage('Invalid password. Only digits and letters allowed.')
			valid = false
		}
		return valid
	}

	_validateName = (value, _input) => {
		let valid = true

		if (value.split(' ').length < 2) {
			valid = false
			toastMessage('Name needs at least 2 words.')
		}

		return valid
	}

	getAccountId = () => {
		const { route } = this.props
		const id = route.match.params.id || this._id

		return !isNaN(id) ? parseInt(id) : null
	}

	_capitalize = text => {
		let n = 0,
			len = text.length
		return text
			.split(' ')
			.map((word, index) => {
				n += word.length
				return !index || n === len || word.length > 3
					? word.charAt(0).toUpperCase() + word.substring(1)
					: word
			})
			.join(' ')
	}

	_getForm = authData => {
		if (this.form) return this.form

		const isEditing = !!this.getAccountId()
		const _options = {
			name: {
				label: 'Name',
				required: true,
				valid: (value, input) => {
					return this._validateName(value, input)
				},
				props: {
					type: 'text',
					value: this.state.inputs.name,
					onBlur: e => {
						this._changeHandler('name', this._cleanUpText(e.target.value))
					},
					onChange: e => {
						this._changeHandler('name', this._capitalize(e.target.value))
					},
					maxLength: 128
				}
			},
			email: {
				label: 'E-mail',
				required: true,
				valid: email => {
					if (!FormUtil.isEmail(email)) {
						toastMessage('Invalid e-mail address.')
						return false
					}
					return true
				},
				props: {
					type: 'email',
					value: this.state.inputs.email,
					onChange: e => {
						this._changeEmailHandler('email', e.target.value)
					}
				}
			},
			mobileNumber: {
				label: 'Phone',
				isEmpty: () => {
					return StringUtil.isEmpty(this.state.inputs.mobileNumber)
				},
				valid: () => {
					if (
						this.state.inputs.mobileNumber?.length &&
						!isPhoneValid(this.state.inputs.mobileNumber)
					) {
						toastMessage('Invalid mobile number.')
						return false
					}
					return true
				},
				props: {
					type: 'text',
					onChange: e => {
						const asYouType = new AsYouType(DEFAULT_COUNTRY)
						this._changeHandler('mobileNumber', asYouType.input(e.target.value))
					},
					placeholder: ''
				}
			},
			password: {
				label: 'Password',
				required: !isEditing,
				valid: value => {
					return this._validatePassword(value)
				},
				props: {
					type: 'text',
					maxLength: 8
				}
			},
			expiresAt: {
				label: 'Expires At',
				required: [true, 'Enter expiration date.'],
				props: {
					type: 'date'
				}
			},
			addToGetResponse: {
				label: 'Add to GetResponse',
				visibleClass: styles.visible,
				invisibleClass: styles.textFieldsWrapper,
				props: {
					type: 'checkbox',
					value: 1
				}
			},
			type: {
				label: 'Account Type',
				visibleClass: styles.visible,
				invisibleClass: styles.textFieldsWrapper,
				props: {
					type: 'select',
					onChange: e => {
						this._changeHandler('type', {
							label: capitalize(e.value),
							value: e.value
						})
					},
					value: {
						label: capitalize(this.state.inputs.type),
						value: this.state.inputs.type
					}
				},
				options: [
					{
						label: 'Premium',
						value: 'premium'
					}
				]
			},
			save: {
				label: 'Save',
				props: {
					type: 'button',
					onClick: this._handleSave.bind(this, authData)
				}
			}
		}

		if (
			isEditing &&
			authData.account.permissions?.includes('permissionManagement')
		) {
			_options.type.options.push({
				label: 'Admin',
				value: 'admin'
			})
		}

		return new FormComponent(_options, {
			pageRef: this,
			prefix: 'inputs',
			changeHandler: this._changeHandler
		})
	}

	_preFetch = async (_data, settings) => {
		const isEditing = !!this.getAccountId()
		const returnData = {}

		this._account = new AccountUtil(this.getAccountId())

		if (isEditing) {
			const accountData = await this._account
				.getInfo({}, settings.request)
				.then(result => result.data)
				.catch(_error =>
					toastMessage(
						'Oops, something went wrong getting account data!',
						'error'
					)
				)

			returnData.accountData = accountData
		}

		return returnData
	}

	_postFetch = ({ fetchedData, authData }) => {
		const state = { ...this.state }
		const isEditing = !!this.getAccountId()
		const inputs = { ...state.inputs }
		let initialEmail

		if (
			!authData.account.permissions?.includes('accountList') ||
			(!isEditing && !authData.account.permissions?.includes('accountAdd'))
		) {
			return
		}

		if (isEditing) {
			inputs.name = fetchedData.accountData.name
			inputs.email = fetchedData.accountData.email
			inputs.type = {
				label: capitalize(fetchedData.accountData.type),
				value: fetchedData.accountData.type
			}
			inputs.mobileNumber = fetchedData.accountData.mobileNumber
			inputs.expiresAt = FormUtil.formatDate(
				new Date(fetchedData.accountData.expiresAt)
			)
			inputs.createdAt = new Date(fetchedData.accountData.createdAt)

			this._currentAccountData = fetchedData.accountData

			initialEmail = inputs.email
			inputs.addToGetResponse = false
		} else {
			inputs.type = { label: 'Premium', value: 'premium' }
			initialEmail = null
			inputs.addToGetResponse = true
		}

		this.form.setVisibleFlag('addToGetResponse', inputs.email !== initialEmail)
		this.form.setVisibleFlag(
			'type',
			authData.account.permissions?.includes('permissionManagement')
		)

		this.setState({
			displayTextFields: !isEditing,
			inputs: inputs,
			initialEmail: initialEmail
		})
	}

	_toggleTextsWrapperVisibility = () => {
		this.setState({
			displayTextFields: !this.state.displayTextFields
		})
	}

	_renderDisabledTextInput = ({ label, value }) => {
		return (
			<div className={formStyles.inputWrapper}>
				<label>
					<p>{label}</p>
					<div>{value}</div>
				</label>
			</div>
		)
	}

	render = () => {
		return (
			<Page
				preFetch={this._preFetch}
				postFetch={this._postFetch}
				render={({ authData }) => {
					const isEditing = !!this.getAccountId()
					const isExpired = new Date(this.state.inputs.expiresAt) < new Date()

					if (
						!authData.account.permissions?.includes('accountList') ||
						(!isEditing &&
							!authData.account.permissions?.includes('accountAdd'))
					) {
						return <Forbidden />
					}

					this.form = this._getForm(authData)

					return (
						<>
							<div className={mainStyles.container}>
								<div className={mainStyles.wrapper}>
									<h3>
										{isEditing
											? `Edit App Account #${this.getAccountId()}: ${
													this.state.inputs.name
												}`
											: this.state.inputs.name
												? `Creating: ${this.state.inputs.name}`
												: 'Create a New App Account'}
									</h3>

									{!isEditing &&
									authData.account.permissions?.includes(
										'accountBulkImport'
									) ? (
										<AddOptionsBar />
									) : null}

									<div className={styles.headingWrapper}>
										<div className={styles.leftSide}>
											{this.form.renderInput('name')}
											{this.form.renderInput('email')}
											{this.form.renderInput('mobileNumber')}
											{this.form.renderInput('password')}
											{this.form.renderInput('expiresAt')}
											{this.form.renderInput('addToGetResponse')}
										</div>

										<div className={styles.rightSide}>
											{isEditing
												? this._renderDisabledTextInput({
														label: 'Created At',
														value: FormUtil.formatDateTime(
															this.state.inputs.createdAt
														)
													})
												: null}
											{this.form.renderInput('type')}
											{isEditing && isExpired ? (
												<p className={styles.accountExpiredLabel}>
													This account is expired and the user is not able to
													log in!
												</p>
											) : null}
										</div>
									</div>

									{((!isEditing &&
										authData.account.permissions?.includes('accountAdd')) ||
										(isEditing &&
											authData.account.permissions?.includes('accountEdit'))) &&
										this.form.renderButton('save', {
											loading: this.state.isSaving
										})}
								</div>
							</div>
						</>
					)
				}}
			/>
		)
	}
}
