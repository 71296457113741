import { useReportDispatch, useReportState } from '../../contexts/report'
import { FormComponent } from '../../shared'
import { useEffect, useState } from 'react'
import { LinkUtil } from '../../shared/link'
import { toastMessage } from '../../components'

export const AccountInput = ({ loadingHandler }) => {
	const reportDispatch = useReportDispatch()
	const { data: reportData } = useReportState()

	const [accounts, setAccounts] = useState([])

	const linkUtil = new LinkUtil()

	const fetchAccounts = () => {
		if (!reportData.selectedTrafficSourceToken) return

		loadingHandler(true)

		linkUtil
			.getTrafficSourceAccounts(reportData.selectedTrafficSourceToken)
			.then(({ status, data }) => {
				if (status !== 200) {
					toastMessage(
						data.message ?? 'Traffic source accounts not found',
						'error'
					)
					return
				}

				setAccounts(data)
			})
			.catch(() => {
				toastMessage('Something went wrong...', 'error')
				setAccounts([])
			})
			.finally(() => {
				loadingHandler(false)
			})
	}

	useEffect(() => {
		fetchAccounts()
	}, [reportData.selectedTrafficSourceToken])

	const options = accounts.map(account => ({
		label: account.name,
		value: account.account_identifier
	}))

	const form = new FormComponent({
		account: {
			label: 'Account',
			props: {
				type: 'select',
				value:
					options.find(
						account => account.value === reportData.selectedTrafficSourceAccount
					) ?? null,
				onChange: ({ value }) => {
					reportDispatch({
						type: 'update',
						payload: {
							data: {
								...reportData,
								selectedTrafficSourceAccount: value,
								selectedFunnel: null,
								selectedProduct: null,
								campaignFilter: null
							}
						}
					})
				}
			},
			options
		}
	})

	return form.renderInput('account')
}
