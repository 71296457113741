import { RequestUtil } from './'

export class ReviewUtil {
	constructor(reviewId) {
		if (reviewId) {
			this._id = reviewId
		}
	}

	setId(id) {
		this._id = id
	}

	getId() {
		return this._id
	}

	save(data) {
		return RequestUtil.post('/review', data)
	}

	getInfo(params, settings) {
		return RequestUtil.get(
			'/review',
			{
				reviewId: this._id
			},
			settings
		)
	}

	getData() {
		return RequestUtil.get('/review/list').then(response => response.data)
	}
}
