import { RequestUtil } from './'

export class LeadUtil {
	getInfo(params) {
		return RequestUtil.get('/lead/info', params).then(response => response)
	}

	getSegments(params) {
		return RequestUtil.get('/lead/segments', params)
	}

	getSegmentLeads(id, query) {
		return RequestUtil.get(`/lead/segment/${id}`, query)
	}

	getEvents(params) {
		return RequestUtil.get('/lead/segment/events', params)
	}

	triggerLeadEvent(data) {
		return RequestUtil.post('/email/create', data)
	}

	triggerEvent(data) {
		return RequestUtil.post('/lead/batch/segments', data)
	}

	getBatches() {
		return RequestUtil.get('/lead/batch/segments')
	}
}
