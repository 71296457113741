import { FormComponent } from '../../shared'
import { useLinkState, useLinkDispatch } from '../../contexts/link'

export const TrafficSourceInput = () => {
	const linkState = useLinkState()
	const linkDispatch = useLinkDispatch()
	const trafficSourceOptions = linkState.data.link.trafficSources.map(
		trafficSource => ({
			label: trafficSource.name,
			value: trafficSource.id,
			trackingFields: trafficSource.trackingFields
		})
	)
	const form = new FormComponent({
		trafficSource: {
			label: 'Traffic source',
			required: [true, 'Choose a traffic source.'],
			props: {
				type: 'select',
				value: linkState.data?.trafficSource || {},
				onChange: option => {
					linkDispatch({
						type: 'update',
						payload: {
							data: {
								trafficSource: option
							}
						}
					})
				}
			},
			options: trafficSourceOptions
		}
	})

	return form.renderInput('trafficSource')
}
