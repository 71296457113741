import React, { useState } from 'react'
import { Wrapper } from './Wrapper'
import { FunnelInput } from './FunnelInput'
import { useReportState } from '../../contexts/report'
import { useLinkState } from '../../contexts/link'
import { Loader } from '../../components'
import 'ka-table/style.scss'
import styles from './Reports.module.scss'
import { DateRangeInput } from './DateRangeInput'
import { TrafficSourcePerformanceReport } from './TrafficSourcePerformanceReport'
import { CampaignFilterInput } from './CampaignFilterInput'
import { TrafficSourceCampaignHistoryCharts } from './TrafficSourceCampaignHistoryCharts'
import { ToggleShouldDisplayChartsButton } from './ToggleShouldDisplayChartsButton'
import { AccountInput } from './AccountInput'
import { TrafficSourceInput } from './TrafficSourceInput'

export const TrafficSourcePerformance = () => {
	const reportState = useReportState()
	const linkState = useLinkState()
	const { data: reportData } = useReportState()
	const isLoaded = reportState?.isInitialized && linkState?.isInitialized

	const [isLoading, setIsLoading] = useState(false)

	const renderForm = () => {
		let form = []

		if (reportData.selectedTrafficSourceToken) {
			form.push(
				<AccountInput key={`account-input`} loadingHandler={setIsLoading} />
			)

			if (reportData.selectedTrafficSourceAccount) {
				form.push(
					<FunnelInput
						key={'funnel-input'}
						trafficSourceToken={reportData.selectedTrafficSourceToken}
					/>
				)
			}
		}

		return form
	}

	return (
		<Wrapper title="Traffic Source Report">
			{isLoaded ? (
				<>
					<div className={styles.settingsContainer}>
						<div>
							<TrafficSourceInput />
						</div>
						{renderForm()}
						<div>
							<DateRangeInput />
						</div>
						<div>
							<CampaignFilterInput />
						</div>
						<div className={styles.singleButton}>
							<ToggleShouldDisplayChartsButton />
						</div>
					</div>
					<TrafficSourceCampaignHistoryCharts />
					<TrafficSourcePerformanceReport />
					<Loader display={isLoading} />
				</>
			) : (
				<Loader display={true} />
			)}
		</Wrapper>
	)
}
