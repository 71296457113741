import React from 'react'
import { Link } from 'react-router-dom'
import formStyles from '../../assets/css/partials/Form.module.scss'
import styles from './Button.module.scss'

export const Button = ({
	classes,
	loading,
	disabled,
	children,
	to,
	onClick,
	size
}) => {
	let baseClasses = [styles.button]
	let button
	let buttonProps = {}

	function handleOnClick() {
		if (!disabled) onClick()
	}

	if (classes) {
		baseClasses = [...baseClasses, classes]
	}

	if (loading) {
		baseClasses.push(formStyles.loading)
		buttonProps = { ...buttonProps, disabled: true }
	}

	if (disabled) {
		buttonProps = { ...buttonProps, disabled: true }
	}

	if (onClick) {
		buttonProps = { ...buttonProps, onClick: handleOnClick }
	}

	if (!size) {
		baseClasses.push(formStyles.primaryButton)
	} else {
		baseClasses.push(formStyles[`${size}Button`])
	}

	button = (
		<button {...buttonProps} className={baseClasses.join(' ')}>
			{loading ? (
				<svg viewBox="0 0 50 50">
					<circle cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
				</svg>
			) : null}
			{loading ? null : children}
		</button>
	)

	return to && !disabled && !loading ? <Link to={to}>{button}</Link> : button
}
