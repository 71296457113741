import React from 'react'
import { Page, Forbidden } from '../../components'
import { LinkType } from './LinkType'
import { LinkTypeOptions } from './LinkTypeOptions'
import { useLinkState } from '../../contexts/link'
import { useLeadState } from '../../contexts/lead'
import { useShortLinkState } from '../../contexts/short-link'
import mainStyles from '../../assets/css/App.module.scss'
import { useProgramState } from '../../contexts/program'
import { useReviewState } from '../../contexts/review'

export const Links = () => {
	const linkState = useLinkState()
	const leadState = useLeadState()
	const shortLinkState = useShortLinkState()
	const programState = useProgramState()
	const reviewState = useReviewState()
	const isLoaded = !!(
		linkState?.isInitialized &&
		leadState?.isInitialized &&
		shortLinkState?.isInitialized &&
		programState?.isInitialized &&
		reviewState?.isInitialized
	)

	function render(pageState) {
		if (!pageState.authData.account.permissions?.includes('linkList')) {
			return <Forbidden />
		}

		return (
			<div className={mainStyles.container}>
				<div className={mainStyles.wrapper}>
					<h3>Links</h3>
					<LinkType />
					<LinkTypeOptions pageState={pageState} />
				</div>
			</div>
		)
	}

	return <Page render={render} isLoaded={isLoaded} />
}
