import { FormComponent } from '../../shared'
import { useLinkState, useLinkDispatch } from '../../contexts/link'
import { useProgramState } from '../../contexts/program'

export const ProgramInput = () => {
	const linkState = useLinkState()
	const linkDispatch = useLinkDispatch()
	const programState = useProgramState()
	const programOptions = programState.data.map(program => ({
		label: program.name,
		value: program.id
	}))
	const form = new FormComponent({
		programId: {
			label: 'Program',
			props: {
				type: 'select',
				value: programOptions.filter(
					programOption => programOption.value === linkState.data.programId
				),
				onChange: option => {
					linkDispatch({
						type: 'update',
						payload: {
							data: {
								programId: option.value
							}
						}
					})
				}
			},
			options: programOptions
		}
	})

	return form.renderInput('programId')
}
