import { RequestUtil } from './'

export class AuthorUtil {
	constructor(authorId) {
		if (authorId) {
			this._id = authorId
		}
	}

	setId(id) {
		this._id = id
	}

	getId() {
		return this._id
	}

	create(data) {
		return RequestUtil.post('/author', data)
	}

	getData(params, settings) {
		return RequestUtil.get('/author/list', params, settings).then(
			response => response.data
		)
	}
}
