import React from 'react'
import { Page, toastMessage } from '../../components'
import { FormComponent } from '../../shared'
import { validationRegexes, renderCurrencyWarning } from './utils'
import mainStyles from '../../assets/css/App.module.scss'
import styles from './ReviewValidator.module.scss'

export class ReviewValidator extends React.PureComponent {
	state = {
		inputs: {
			textEn: '',
			textPt: ''
		},
		textEnTrackCount: 1,
		textPtTrackCount: 1,
		textPtHighlightedLines: [],
		textEnHighlightedLines: [],
		hasCurrencyMistake: false
	}

	_allowedTags = ['headline']

	_getLanguageIndex = languageKey => {
		return `text${languageKey.charAt(0).toUpperCase() + languageKey.slice(1)}`
	}

	_getTrackCount = languageKey => {
		return this.state.inputs[this._getLanguageIndex(languageKey)].split(/\n\n/)
			.length
	}

	_handleValidateReview = () => {
		if (this.form.valid()) {
			const [hasWarning, toastMessages] = this._checkWarning()
			if (hasWarning) {
				if (
					!confirm(
						'Warning!\nThere might be errors in the ENGLISH text. Check the highlighted lines for details.\n\nIf you are sure there are no errors, click OK to proceed.'
					)
				) {
					toastMessages.forEach(message => toastMessage(...message))
					return
				}
			}
			this.setState({
				textEnHighlightedLines: []
			})
			toastMessage('Congrats! No syntax issues were found.')
		}
	}

	_cleanUpText = text => {
		return text
			.replace(/\n\s*\n\s*\n/g, '\n\n') // remove triplicated line breaks
			.replace(/[ ]+/g, ' ') // remove duplicated spaces
	}

	_changeHandler = (id, value, callback) => {
		const inputs = { ...this.state.inputs }

		inputs[id] = value
		this.setState(
			{
				inputs: inputs
			},
			callback
		)
	}

	_validateEnText = () => {
		let valid = true
		const textEnTrackCount = this._getTrackCount('en')

		if (textEnTrackCount < 3) {
			valid = false
			toastMessage('English book must have at least 3 tracks.')
		} else {
			const checkStructureResult = this._checkTextStructure(
				this.state.inputs.textEn,
				this.state.inputs.textPt,
				'en'
			)

			if (checkStructureResult.length) {
				valid = false
				toastMessage(
					'English book has structural issues. See highlighted lines to get more info.'
				)

				this.setState({
					textEnHighlightedLines: checkStructureResult
				})
			} else {
				this.setState({
					textEnHighlightedLines: []
				})
			}
		}

		return valid
	}

	_checkTrackCount = () => {
		const textEnTrackCount = this._getTrackCount('en')
		const textPtTrackCount = this._getTrackCount('pt')

		return textEnTrackCount === textPtTrackCount
	}

	_getTracksArray = languageKey => {
		return this.state.inputs[this._getLanguageIndex(languageKey)].split(/\n\n/)
	}

	_getInvalidTags = text => {
		const invalidTags =
			text.match(/<([^a-z/]+[a-z]+|[a-z]+[^a-z]|[^a-z/]+[a-z]+[^a-z]+)>/gm) ||
			[]

		return invalidTags
	}

	_getOpeningTags = (text, tag = '[a-z]+') => {
		const regex = new RegExp(`<${tag}>`, 'gm')
		return text.match(regex) || []
	}

	_getClosingTags = (text, tag = '[a-z]+') => {
		const regex = new RegExp(`</${tag}>`, 'gm')
		return text.match(regex) || []
	}

	_checkTextStructure = (text, pairedText, lang) => {
		let errors = []
		let invalidTags = false
		let closingTags
		let openingTags
		let unallowedTagsFound
		let originalTagArray
		let pairedTagArray
		let message
		let openingAndClosingTagsMerged
		const lines = text.split(/\n/)

		lines.forEach((lineText, lineIndex) => {
			invalidTags = this._getInvalidTags(lineText)

			if (invalidTags.length) {
				errors.push({
					message: `Invalid tags found: ${invalidTags.join(', ')}.`,
					line: lineIndex + 1
				})
			}
		})

		if (!errors.length) {
			lines.forEach((lineText, lineIndex) => {
				unallowedTagsFound = []

				openingTags = this._getOpeningTags(lineText)
				closingTags = this._getClosingTags(lineText)
				openingAndClosingTagsMerged = [...openingTags, ...closingTags]

				openingAndClosingTagsMerged.forEach(tag => {
					if (!this._allowedTags.includes(tag.replace(/[<>/]/g, ''))) {
						unallowedTagsFound.push(tag)
					}
				})

				if (unallowedTagsFound.length) {
					errors.push({
						message: `Unallowed tags found: ${unallowedTagsFound.join(', ')}.`,
						line: lineIndex + 1
					})
				}
			})

			if (!errors.length) {
				lines.forEach((lineText, lineIndex) => {
					openingTags = this._getOpeningTags(lineText)
					closingTags = this._getClosingTags(lineText)

					if (closingTags.length !== openingTags.length) {
						errors.push({
							message: `Opening and closing tags count doesn't match: ${openingTags.length} opening, ${closingTags.length} closing.`,
							line: lineIndex + 1
						})
					} else {
						const reverseClosingTags = closingTags.reverse()

						openingTags.some((tag, tagIndex) => {
							if (tag !== reverseClosingTags[tagIndex].replace(/<\//, '<')) {
								errors.push({
									message: `Tag ${tag} is NOT closed or it is being closed on the wrong order.`,
									line: lineIndex + 1
								})

								return true
							}

							return false
						})
					}
				})

				if (!errors.length) {
					const pairedTextLines = pairedText.split(/\n/)

					lines.forEach((lineText, lineIndex) => {
						if (pairedTextLines[lineIndex]) {
							originalTagArray = this._getOpeningTags(lineText)
							pairedTagArray = this._getOpeningTags(pairedTextLines[lineIndex])

							if (originalTagArray.length !== pairedTagArray.length) {
								if (pairedTagArray.length) {
									message = `Mismatch: compared to the other version, this line should contain exactly ${
										pairedTagArray.length
									} tag${
										pairedTagArray.length > 1 ? 's' : ''
									}, but it contains ${originalTagArray.length}.`
								} else {
									message = `Mismatch: compared to the other version, this line shouldn't contain any tags, but it contains ${originalTagArray.length}.`
								}

								errors.push({
									message: message,
									line: lineIndex + 1
								})
							}
						}
					})
				}
			}
		}

		if (!errors.length && lang === 'en') {
			let hasErrors = false
			lines.forEach((lineText, lineIndex) => {
				const [hasMistakes, message] = this._checkCurrencyText(lineText)
				if (hasMistakes) {
					errors.push({
						message,
						line: lineIndex + 1
					})
					hasErrors = true
				}
			})
			this.setState({
				hasCurrencyMistake: hasErrors
			})
			if (hasErrors) {
				toastMessage(
					'There are errors with currency formats. Check the marked lines and see the rules for currency text.'
				)
			}
		}

		return errors
	}

	_checkWarning = () => {
		let hasWarning = false
		let errors = []
		let toastMessages = []

		// check for mispelled number with decimals
		const { decimalRegexes } = validationRegexes
		const lines = this.state.inputs.textEn.split(/\n/)
		lines.forEach((lineText, lineIndex) => {
			if (
				lineText.match(decimalRegexes.currency) ||
				lineText.match(decimalRegexes.percentage)
			) {
				hasWarning = true
				errors.push({
					message: decimalRegexes.lineMessage,
					line: lineIndex + 1,
					type: 'warning'
				})
			}
		})
		if (hasWarning) {
			this.setState({
				textEnHighlightedLines: errors
			})
			toastMessages.push([decimalRegexes.toastMessage, 'warning'])
		}

		return [hasWarning, toastMessages]
	}

	_checkLineCount = () => {
		return (
			this.state.inputs.textEn.split(/\n/).length ===
			this.state.inputs.textPt.split(/\n/).length
		)
	}

	_validatePtText = () => {
		let valid = true

		if (!this._checkLineCount()) {
			valid = false
			toastMessage('Both texts must have the exact same line counting.')
		} else if (!this._checkTrackCount()) {
			valid = false
			toastMessage(
				'Portuguese book must have the exact same track count as the English version.'
			)
		} else {
			const checkStructureResult = this._checkTextStructure(
				this.state.inputs.textPt,
				this.state.inputs.textEn,
				'pt'
			)

			if (checkStructureResult.length) {
				valid = false
				toastMessage(
					"Portuguese book should mirror English version's structure. See highlighted lines to get more info."
				)

				this.setState({
					textPtHighlightedLines: checkStructureResult
				})
			} else {
				this.setState({
					textPtHighlightedLines: []
				})
			}
		}

		return valid
	}

	_updateTrackCount = () => {
		this.setState({
			textEnTrackCount: this._getTrackCount('en'),
			textPtTrackCount: this._getTrackCount('pt')
		})
	}

	_cleanUpTracks = text => {
		const tracksArray = text.split(/\n\n/)

		return tracksArray.map(item => item.trim()).join('\n\n')
	}

	_checkCurrencyText = text => {
		// check for redundant currency text
		const { redundancyRegex, currencyRegex, uppercaseRegex, checkRegexes } =
			validationRegexes

		if (
			text.match(redundancyRegex.numericForm) ||
			text.match(redundancyRegex.writtenForm)
		) {
			return [true, redundancyRegex.message]
		}

		const matches = text.match(currencyRegex)
		if (!matches) return [false, ''] // there are no currency terms found

		// check for case errors
		const insensitiveRegex = new RegExp(uppercaseRegex.regex, 'gi')
		const sensitiveRegex = new RegExp(uppercaseRegex.regex, 'g')
		for (let i = 0; i < matches.length; i++) {
			const match = matches[i]
			const insensitiveMatch = match.match(insensitiveRegex)
			const sensitiveMatch = match.match(sensitiveRegex)
			if (insensitiveMatch && !sensitiveMatch) {
				// returns upon first mistake found
				return [true, uppercaseRegex.message]
			}
		}

		for (let i = 0; i < matches.length; i = i + 1) {
			let isWrongFormat = true
			const textMatch = matches[i]
			for (let j = 0; j < checkRegexes.regex.length; j = j + 1) {
				if (textMatch.match(checkRegexes.regex[j])) {
					isWrongFormat = false
					break
				}
			}
			if (isWrongFormat) {
				// returns upon first mistake found
				return [true, checkRegexes.message]
			}
		}
		return [false, '']
	}

	_getForm = () => {
		return new FormComponent({
			textEn: {
				label: `ENGLISH Book${
					this.state.textEnTrackCount > 1
						? ` - ${this.state.textEnTrackCount} tracks`
						: ''
				}`,
				required: true,
				valid: () => {
					return this._validateEnText()
				},
				props: {
					type: 'numeredTextarea',
					minHeight: 200,
					value: this.state.inputs.textEn,
					highlightedLines: this.state.textEnHighlightedLines,
					onBlur: ref => {
						this._changeHandler('textEn', this._cleanUpTracks(ref.value))
					},
					onChange: ref => {
						this._changeHandler('textEn', this._cleanUpText(ref.value), () => {
							this._updateTrackCount()
						})
					}
				}
			},
			textPt: {
				label: `PORTUGUESE Book${
					this.state.textPtTrackCount > 1
						? ` - ${this.state.textPtTrackCount} tracks`
						: ''
				}`,
				required: true,
				valid: () => {
					return this._validatePtText()
				},
				props: {
					type: 'numeredTextarea',
					minHeight: 200,
					value: this.state.inputs.textPt,
					highlightedLines: this.state.textPtHighlightedLines,
					onBlur: ref => {
						this._changeHandler('textPt', this._cleanUpTracks(ref.value))
					},
					onChange: ref => {
						this._changeHandler('textPt', this._cleanUpText(ref.value), () => {
							this._updateTrackCount()
						})
					}
				}
			},
			validate: {
				label: 'Check Syntax',
				props: {
					type: 'button',
					onClick: this._handleValidateReview
				}
			}
		})
	}

	render = () => {
		return (
			<Page
				render={() => {
					this.form = this._getForm()

					return (
						<>
							<div className={mainStyles.container}>
								<div className={mainStyles.wrapper}>
									<h3>Book Syntax Validator</h3>
									{this.state.hasCurrencyMistake && renderCurrencyWarning()}
									<div className={styles.headingWrapper}>
										<div className={styles.leftSide}>
											{this.form.renderInput('textEn')}
											{this.form.renderInput('textPt')}
										</div>
										{this.form.renderButton('validate', {
											loading: this.state.isSaving
										})}
									</div>
								</div>
							</div>
						</>
					)
				}}
			/>
		)
	}
}
