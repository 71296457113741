import React, { useEffect } from 'react'
import styles from './Modal.module.scss'
import closeIcon from '../../assets/images/icons/remove-icon-inverse.png'

export const Modal = ({ children, isOpen, onClose }) => {
	function closeOnEsc(e) {
		if (e.keyCode === 27) {
			onClose()
		}
	}

	useEffect(() => {
		isOpen
			? document.addEventListener('keydown', closeOnEsc)
			: document.removeEventListener('keydown', closeOnEsc)

		return () => {
			document.removeEventListener('keydown', closeOnEsc)
		}
	}, [isOpen])

	if (!isOpen) return null

	return (
		<div className={styles.modal}>
			<div className={styles.wrapper}>
				<img className={styles.closeIcon} src={closeIcon} onClick={onClose} />
				<div className={styles.canvas}>{children}</div>
			</div>
		</div>
	)
}
