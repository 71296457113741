import { useEffect } from 'react'
import { FormComponent } from '../../shared'
import { useLinkState, useLinkDispatch } from '../../contexts/link'
import { FormUtil } from '../../shared/form'
import { toastMessage } from '../../components'

export const EmailInput = ({ focusOnLoad }) => {
	const linkState = useLinkState()
	const linkDispatch = useLinkDispatch()
	const form = new FormComponent({
		email: {
			label: 'Email',
			props: {
				type: 'email',
				value: linkState.data?.email ?? '',
				onBlur: () => {
					if (!FormUtil.isEmail(linkState.data?.email)) {
						toastMessage('Invalid email', 'warning')
					}
				},
				onChange: e => {
					linkDispatch({
						type: 'update',
						payload: {
							data: {
								email: e.target.value
							}
						}
					})
				}
			}
		}
	})

	useEffect(() => {
		if (focusOnLoad) form.getRef('email').focus()
	}, [])

	return form.renderInput('email')
}
