import React from 'react'
import styles from './Loader.module.scss'

const baseClasses = [styles.loader]
export class Loader extends React.PureComponent {
	_isMounted = false

	state = {
		classes: baseClasses
	}

	_activate = () => {
		const classes = [...baseClasses]

		classes.push(styles.active)
		classes.push(styles.visible)

		this.setState({
			classes: classes
		})
	}

	_deactivate = () => {
		const classes = [...baseClasses]

		classes.push(styles.visible)

		this.setState(
			{
				classes: classes
			},
			() => {
				setTimeout(() => {
					if (this._isMounted) {
						const classes = [...baseClasses]
						this.setState({
							classes: classes
						})
					}
				}, 400)
			}
		)
	}

	componentDidMount() {
		this._isMounted = true

		if (this.props.display) this._activate()
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	componentDidUpdate(prevProps) {
		if (prevProps.display !== this.props.display) {
			this.props.display ? this._activate() : this._deactivate()
		}
	}

	render() {
		return (
			<div className={this.state.classes.join(' ')}>
				<div>
					<svg viewBox="0 0 50 50">
						<circle cx="25" cy="25" r="20" fill="none" strokeWidth="3" />
					</svg>
				</div>
			</div>
		)
	}
}
