var ttest = require('@stdlib/stats-ttest')
var normal = require('@stdlib/random-base-normal').factory

export function getAdStats(ad, funnelKPIs) {
	const FUNNEL_AVG_LCVR = funnelKPIs.avgLCVR
	const FUNNEL_AVG_ICCVR = funnelKPIs.avgICCVR
	const FUNNEL_AVG_PCVR = funnelKPIs.avgPCVR
	const FUNNEL_AVG_CPIC_ON_ROAS = funnelKPIs.avgCPICOnROAS
	const MIN_CLICKS = funnelKPIs.minClicks
	const MIN_ROAS = funnelKPIs.minROAS

	const lCVR = ad.clicks && ad.leads ? ad.leads / ad.clicks : 0
	const icCVR = ad.clicks && ad.leads && ad.ics ? ad.ics / ad.leads : 0
	const pCVR =
		ad.clicks && ad.leads && ad.ics && ad.purchases ? ad.purchases / ad.ics : 0
	let analysis = {
		status: 'INCONCLUSIVE',
		rule: 'INSUFICIENT_DATA',
		ruleDescription: 'Inconclusive. Insuficient data to analyze.',
		optimizationAction: undefined
	}

	if (!FUNNEL_AVG_LCVR || !FUNNEL_AVG_ICCVR || !FUNNEL_AVG_PCVR) {
		analysis.ruleDescription +=
			" Funnel averages can't yet be calculated: one or more conversion types not happened."
	}

	if (
		// FUNNEL_AVG_LCVR &&
		FUNNEL_AVG_ICCVR &&
		FUNNEL_AVG_PCVR &&
		ad.clicks >= 2
	) {
		const samples = new Array(ad.clicks)
		const rnormLCVR = normal(lCVR, 0.1, {
			seed: 37827
		})
		const rnormICCVR = normal(icCVR, 0.1, {
			seed: 37427
		})
		const rnormPCVR = normal(pCVR, 0.1, {
			seed: 37923
		})

		for (let i = 0; i < samples.length; i++) {
			samples[i] = rnormLCVR()
		}
		const ttestResultLeads = ttest(samples, {
			alternative: 'less',
			mu: FUNNEL_AVG_LCVR
		})

		for (let i = 0; i < samples.length; i++) {
			samples[i] = rnormICCVR()
		}
		const ttestResultICs = ttest(samples, {
			alternative: 'less',
			mu: FUNNEL_AVG_ICCVR
		})

		for (let i = 0; i < samples.length; i++) {
			samples[i] = rnormPCVR()
		}
		const ttestResultPurchases = ttest(samples, {
			alternative: 'less',
			mu: FUNNEL_AVG_PCVR
		})

		if (ad.purchases) {
			if (ad.roas >= MIN_ROAS) {
				if (ad.roas <= MIN_ROAS * 2) {
					analysis = {
						status: 'GOOD',
						rule: 'GOOD_ROAS',
						ruleDescription: 'Keep this ad. Good ROAS.',
						optimizationAction: 'KEEP'
					}
				} else {
					analysis = {
						status: 'EXCELLENT',
						rule: '2X_ROAS',
						ruleDescription:
							'Scale this ad. Excellent ROAS (2X+ minimum ROAS).',
						optimizationAction: 'SCALE'
					}
				}
			} else if (ad.roas > MIN_ROAS * 0.8) {
				analysis = {
					status: 'MAY_WORK_AT_LOWER_BUDGET',
					rule: 'REASONABLE_ROAS',
					ruleDescription:
						'Pause this ad. ROAS is below minimum expected but it may work at a lower budget.',
					optimizationAction: 'PAUSE'
				}
			} else {
				analysis = {
					status: 'BAD',
					rule: 'LOW_ROAS',
					ruleDescription: 'Pause this ad. ROAS is below minimum expected.',
					optimizationAction: 'PAUSE'
				}
			}
		} else if (
			FUNNEL_AVG_CPIC_ON_ROAS &&
			ad.spend > FUNNEL_AVG_CPIC_ON_ROAS * 2 &&
			ad.roas < MIN_ROAS
		) {
			analysis = {
				status: 'NOT_PLAUSIBLE',
				rule: 'CPIC_COMPARISON',
				ruleDescription:
					"Pause this ad. It spent over twice the average CPIC of good performers and didn't brought sales or is below the minimum ROAS.",
				optimizationAction: 'PAUSE'
			}
		} else if (ttestResultLeads.rejected === false) {
			//
		} else if (
			(lCVR || FUNNEL_AVG_LCVR) *
			(icCVR || FUNNEL_AVG_ICCVR) *
			(pCVR || FUNNEL_AVG_PCVR) >=
			FUNNEL_AVG_LCVR * FUNNEL_AVG_ICCVR * FUNNEL_AVG_PCVR &&
			ad.roas >= MIN_ROAS
		) {
			analysis = {
				status: 'PLAUSIBLE',
				rule: 'FUNNEL_AVERAGE_GOOD_ROAS',
				ruleDescription:
					"This ad is plausible. Data is still not statistically significant, but ad's average conversion rate >= funnel's.",
				optimizationAction: 'KEEP'
			}
		} else if (
			ad.clicks >= MIN_CLICKS &&
			(!ad.leads || lCVR < FUNNEL_AVG_LCVR * 0.5)
		) {
			analysis = {
				status: 'BAD',
				rule: 'LCVR_BELOW_50%_FUNNEL_AVG',
				ruleDescription:
					"Pause this ad. LCVR is below half of funnel's average rate.",
				optimizationAction: 'PAUSE'
			}
		} else if (
			(lCVR || FUNNEL_AVG_LCVR) *
			(icCVR || FUNNEL_AVG_ICCVR) *
			(pCVR || FUNNEL_AVG_PCVR) <
			FUNNEL_AVG_LCVR * FUNNEL_AVG_ICCVR * FUNNEL_AVG_PCVR &&
			ad.roas < MIN_ROAS
		) {
			analysis = {
				status: 'BAD',
				rule: 'TOO_RISKY_STATISTICALLY_HARD_TO_REACH_FUNNEL_AVG_CVR',
				ruleDescription: `Pause this ad. One or more KPIs are too risky, statistically hard to reach funnel average conversion rate and/or MIN_ROAS.`,
				optimizationAction: 'PAUSE'
			}
		}

		analysis = {
			...analysis,
			ttestResultLeads: ttestResultLeads,
			ttestResultICs: ttestResultICs,
			ttestResultPurchases: ttestResultPurchases
		}
	}

	analysis = {
		...analysis,
		lCVR: ad.clicks && ad.leads ? ad.leads / ad.clicks : 0,
		icCVR: ad.clicks && ad.ics ? ad.ics / ad.clicks : 0,
		pCVR: ad.clicks && ad.purchases ? ad.purchases / ad.clicks : 0,
		funnelKPIs: funnelKPIs
	}

	return analysis
}
