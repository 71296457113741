import React from 'react'
import mainStyles from '../../assets/css/App.module.scss'
import { TabBar } from '../../components/TabBar/TabBar'
import { Link } from 'react-router-dom'

const CRM_ADD_OPTION_LIST = [
	{ id: 'crm', label: 'CRM', path: '#', permission: 'crmLeads' },
	{
		id: 'eventTriggering',
		label: 'Event Triggering',
		path: '#',
		permission: 'crmEventsTriggering'
	}
]

export const CrmOptions = ({ handleChange, selectedTab, permissions }) => {
	return (
		<TabBar>
			{CRM_ADD_OPTION_LIST.map(optionItem => {
				if (!permissions.includes(optionItem.permission)) {
					return null
				}

				const itemStyles = [mainStyles.tab]

				if (selectedTab === optionItem.id) {
					itemStyles.push(mainStyles.active)
				}

				return (
					<Link
						key={optionItem.id}
						onClick={() => handleChange(optionItem.id)}
						className={itemStyles.join(' ')}
						to={optionItem.path}
					>
						{optionItem.label}
					</Link>
				)
			})}
		</TabBar>
	)
}
