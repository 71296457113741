import { FormComponent } from '../../shared'
import { useLinkState, useLinkDispatch } from '../../contexts/link'

export const AppLaunchActionInput = () => {
	const linkState = useLinkState()
	const linkDispatch = useLinkDispatch()
	const actionOptions = [
		{
			label: 'Open a book',
			value: 'open-review'
		},
		{
			label: 'Open a program',
			value: 'open-program'
		},
		{
			label: 'Open Daily Challenge',
			value: 'open-daily-challenge'
		},
		{
			label: 'Launch only',
			value: 'launch-only'
		}
	]
	const form = new FormComponent({
		action: {
			label: 'Launch action',
			props: {
				type: 'select',
				value: actionOptions.find(
					a => a.value === linkState.data?.appLaunchAction
				),
				onChange: option => {
					linkDispatch({
						type: 'update',
						payload: {
							data: {
								appLaunchAction: option.value
							}
						}
					})
				}
			},
			options: actionOptions
		}
	})

	return form.renderInput('action')
}
