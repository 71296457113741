import React, { useEffect, useState } from 'react'
import { Forbidden, Page } from '../../components'
import { useAccountDispatch, useAccountState } from '../../contexts/account'
import { AddOptionsBar } from './AddOptionsBar'
import { AccountUtil, FormUtil } from '../../shared/'
import { toastMessage } from '../../components'
import Papa from 'papaparse'
import mainStyles from '../../assets/css/App.module.scss'
import { getPhoneNumberFragments, isPhoneValid } from '../../shared/form'
import { ACCOUNT_ADD_OPTION_LIST } from '../Links/utils'
import template from './template.csv'
import styles from './Account.module.scss'
import csvExample from '../../assets/images/csv-account-import-example.png'

const accountUtil = new AccountUtil()

export const BulkImport = () => {
	const accountState = useAccountState()
	const accountDispatch = useAccountDispatch()
	const [isLoading, setIsLoading] = useState()
	const [processingResults, setProcessingResults] = useState([])
	const isLoaded = accountState?.isInitialized

	const changeHandler = event => {
		const file = event.target.files[0]

		if (!file) return

		Papa.parse(file, {
			header: true,
			complete: results => {
				const data = results?.data
				const errors = []

				if (!data) {
					toastMessage('Error, check the input file')
					return
				}

				setProcessingResults([])
				setIsLoading(true)

				for (let index = 0; index < data.length; index++) {
					let { email, mobile, name, getResponse, expiration } = data[index]

					if (!email) {
						continue
					}

					email = email?.trim()

					if (!FormUtil.isEmail(email)) {
						errors.push({
							index: index,
							error: `email "${email}" is not valid`
						})
						continue
					}

					name = name.trim().replace(/[ ]+/g, ' ') // remove duplicated spaces

					if (name.split(' ').length < 2) {
						errors.push({
							index: index,
							error: `name "${name}" is not valid (min. 2 words)`
						})
						continue
					}

					// capitalize name
					const nameTokens = name.split(' ')

					data[index].name = nameTokens
						.map((word, index) => {
							return !index ||
								index === nameTokens.length - 1 ||
								word.length > 3
								? word.charAt(0).toUpperCase() + word.substring(1)
								: word
						})
						.join(' ')

					if (!mobile) {
						errors.push({
							index: index,
							error: 'empty mobile number'
						})
						continue
					} else if (isPhoneValid(mobile)) {
						const fragments = getPhoneNumberFragments(mobile)
						data[index].mobileNumber = fragments.international
						data[index].mobileCountryCode = fragments.country || null
						delete data[index].mobile
					} else {
						errors.push({
							index: index,
							error: `invalid mobile number "${mobile}"`
						})
						continue
					}

					const expirationDate = new Date(expiration)
					expirationDate.setUTCHours(20, 59, 59, 0)

					if (new Date().getTime() > expirationDate.getTime()) {
						errors.push({
							index: index,
							error: 'expiration date must be in the future'
						})
						continue
					}

					data[index].expiration = expirationDate.toJSON()
					data[index].getResponse = getResponse === 'true'
				}

				if (errors.length) {
					setProcessingResults(errors)
					toastMessage(
						'Something went wrong. Please check the logs for more details',
						'error'
					)
					setIsLoading(false)
					return
				}

				accountUtil
					.createAccountsInBatch(data)
					.then(response => {
						if ([200, 201].includes(response.status)) {
							toastMessage('Bulk import process finished!')

							if (response.data?.length) {
								setProcessingResults(
									response.data.map((error, index) => ({
										index: index,
										error: error
									}))
								)

								toastMessage(
									'Some errors were encountered though. Please check the logs for more details'
								)
							}
						} else {
							toastMessage('Oops, something went wrong...', 'error')
							setProcessingResults([
								{
									error: `Something went wrong while processing the file, HTTP status code ${response.status} was not expected`
								}
							])
							console.log(response)
						}
						setIsLoading(false)
					})
					.catch(error => {
						toastMessage('Error accessing the server.', 'error')
						setProcessingResults([
							{
								error: 'Server error while processing the file'
							}
						])
						setIsLoading(false)
						console.log(error)
					})
			}
		})
	}

	function render(pageState) {
		if (
			!pageState.authData.account.permissions?.includes('accountBulkImport')
		) {
			return <Forbidden />
		}

		return (
			<>
				<div className={mainStyles.container}>
					<div className={mainStyles.wrapper}>
						<h3>Create a New Account</h3>
						<AddOptionsBar />

						<ul style={{ fontSize: 13, marginBottom: 25 }}>
							<li>DIRECTIONS:</li>
							<li>
								- <strong>name</strong> must contain the user's fullname, with
								at least 2 words.
							</li>
							<li>
								- <strong>email</strong> must be a well-formed and valid email
								address.
							</li>
							<li>
								- <strong>mobile</strong> must contain a valid mobile phone
								number. Examples: +55 47 98888-4554 or +356 7749-6850.
							</li>
							<li>
								- <strong>expiration</strong> is account's expiration date
								(Brazilian timezone), in the following format: YYYY-mm-dd.
							</li>
							<li>
								- <strong>getResponse</strong> should either be{' '}
								<strong>true</strong> or <strong>false</strong>. If{' '}
								<strong>true</strong>, it means the user's email will be added
								to GetResponse and will enter our automations for premium users.
							</li>
							<li>
								- <strong>The file should be UTF-8 encoded.</strong>
							</li>
							<li>
								- <strong>Headers must me kept!</strong> Don't remove the
								headers from the template.
							</li>
							<li>
								-{' '}
								<strong>
									There must not be no trailing spaces in any info.
								</strong>
							</li>
							<li>
								- <strong>There must not be no empty lines.</strong>
							</li>
							<li>
								<br />
								EXAMPLE:
							</li>
							<li>
								<img src={csvExample} style={{ width: 600 }} />
							</li>
						</ul>
						<p style={{ marginBottom: 20 }}>
							Download the .csv template{' '}
							<a href={template} target="_blank" rel="noreferrer">
								here.
							</a>
							<br />
							If you already have your bulk import CSV file ready, just select
							it below.
							<br />
							<br />
							<strong>
								⚠️ Be aware: the import process will begin automatically, right
								after selecting the file (no questions will be asked). Once
								started, the import process can't be stopped.
								<br />
								<br /> Keep this page open until you get a feedback message.
							</strong>
						</p>

						{processingResults.length ? (
							<>
								<a href="#" onClick={refreshPage}>
									Try again.
								</a>

								<div className={styles.errorBox}>
									{processingResults.map(result => (
										<p key={result.index} className={mainStyles.error}>
											{result.index !== undefined
												? `Error on line ${result.index + 2}: `
												: null}
											{result.error}.
										</p>
									))}
								</div>
							</>
						) : (
							<input type="file" onChange={changeHandler} accept=".csv" />
						)}
					</div>
				</div>
			</>
		)
	}

	function refreshPage(e) {
		window.location.reload(false)
		e.preventDefault()
	}

	useEffect(() => {
		accountDispatch({
			type: 'update',
			payload: {
				data: { accountAddOption: ACCOUNT_ADD_OPTION_LIST[1].id }
			}
		})
	}, [])

	return <Page render={render} isLoaded={isLoaded && !isLoading} />
}
