const getExpireTime = key => {
	const expirationTimes = {}
	let expiration = null

	expirationTimes[StorageKeys.AUTH_DATA] = 12 * 60 * 60 * 1000 // 12 hours

	for (let [index] of Object.entries(expirationTimes)) {
		const regex = new RegExp(`.*?${index}$`)
		if (key.match(regex)) {
			expiration = expirationTimes[index]
			break
		}
	}

	return expiration || null
}

export const StorageKeys = {
	AUTH_DATA: 'authData',
	UUID: 'uuid'
}

export const StorageUtil = {
	setItem(key, data, callback) {
		const dataToStore = {
			data: data
		}

		try {
			const expirationTime = getExpireTime(key)

			if (expirationTime) {
				const currentTimestamp = new Date().getTime()

				dataToStore._cacheExpiresAt = new Date(
					currentTimestamp + expirationTime
				)
			}

			localStorage.setItem(key, JSON.stringify(dataToStore))

			if (callback) callback()

			return true
		} catch (error) {
			return false
		}
	},

	getItem(key, callback, forceUseCache) {
		let data = null
		let returnData

		try {
			data = JSON.parse(localStorage.getItem(key))

			if (
				data &&
				data._cacheExpiresAt &&
				new Date() > new Date(data._cacheExpiresAt) &&
				!forceUseCache
			) {
				data = null
			}

			returnData = data ? data.data : data

			if (callback) callback(returnData, data)
		} catch (error) {
			//
		}
		return returnData
	},

	getExpiresAt(key) {
		let data = null
		let returnData

		try {
			data = JSON.parse(localStorage.getItem(key))
			returnData = data ? data._cacheExpiresAt : data
		} catch (error) {
			//
		}

		return returnData
	}
}
