import React from 'react'
import { withRouter } from 'react-router'
import { Route, Redirect } from 'react-router-dom'
import { AutoScroll, ToastWrapper, ValidateAuthSession } from './components'
import {
	Login,
	Links,
	Review,
	ReviewList,
	ReviewValidator,
	Account,
	AccountList,
	Program,
	ProgramList
} from './pages'
import styles from './assets/css/App.module.scss'
import { Stacks } from './Stacks'
import { StateHandler } from './StateHandler'
import { Welcome } from './pages/Welcome/Welcome'
import { Reports } from './pages/Reports/Reports'
import { TrafficSourcePerformance } from './pages/Reports/TrafficSourcePerformance'
import { BulkImport } from './pages/Account/BulkImport'
import { PoliAccountList } from './pages/PoliAccountList/PoliAccountList'
import { PoliAccount } from './pages/PoliAccount/PoliAccount'
import { Permissions } from './pages/Permissions/Permissions'
import { EmailFix } from './pages/EmailFix/EmailFix'
import Crm from './pages/Crm/Crm'

const App = () => {
	return (
		<Stacks>
			<StateHandler />
			<AutoScroll />
			<ValidateAuthSession />
			<div className={styles.app}>
				<Route exact path={'/welcome'} render={() => <Welcome />} />
				<Route exact path={'/reports'} render={() => <Reports />} />
				<Route
					exact
					path={'/reports/paid-traffic'}
					render={() => <TrafficSourcePerformance />}
				/>
				<Route exact path={'/'} render={() => <Redirect to="/login" />} />
				<Route exact path={'/app/links'} render={() => <Links />} />
				<Route exact path={'/login'} render={() => <Login />} />
				<Route
					exact
					path={'/review/validator'}
					render={() => <ReviewValidator />}
				/>
				<Route exact path={'/review/list'} render={() => <ReviewList />} />
				<Route
					exact
					path={'/review/save/:id?'}
					render={({ match }) => <Review route={{ match: match }} />}
				/>
				<Route exact path={'/program/list'} render={() => <ProgramList />} />
				<Route
					exact
					path={'/program/save/:id?'}
					render={({ match }) => <Program route={{ match: match }} />}
				/>
				<Route exact path={'/account/list'} render={() => <AccountList />} />
				<Route
					exact
					path={'/poli/account/list'}
					render={() => <PoliAccountList />}
				/>
				<Route
					exact
					path={'/poli/account/save/:id?'}
					render={({ match }) => <PoliAccount route={{ match: match }} />}
				/>
				<Route
					exact
					path={'/account/save/:id?'}
					render={({ match }) => <Account route={{ match: match }} />}
				/>
				<Route
					exact
					path={'/account/bulk-import'}
					render={({ match }) => <BulkImport route={{ match: match }} />}
				/>
				<Route
					exact
					path={'/account/permissions'}
					render={({ match }) => <Permissions route={{ match: match }} />}
				/>
				<Route
					path={'/email/fix'}
					render={({ match }) => <EmailFix route={{ match: match }} />}
				/>
				<Route
					path={'/crm'}
					render={({ match }) => <Crm route={{ match: match }} />}
				/>
			</div>
			<ToastWrapper />
		</Stacks>
	)
}

export default withRouter(App)
