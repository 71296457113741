import { useEffect } from 'react'
import { AsYouType } from 'libphonenumber-js/max'
import { FormComponent } from '../../shared'
import { useLinkState, useLinkDispatch } from '../../contexts/link'
import { DEFAULT_COUNTRY, isPhoneValid } from '../../shared/form'
import { toastMessage } from '../../components'

export const WhatsAppNumberInput = ({ onKeyUp, focusOnLoad }) => {
	const linkState = useLinkState()
	const linkDispatch = useLinkDispatch()
	const form = new FormComponent({
		mobileNumber: {
			label: 'WhatsApp Number',
			props: {
				type: 'text',
				value: linkState.data?.mobileNumber ?? '',
				onKeyUp: onKeyUp,
				onBlur: () => {
					if (
						linkState.data?.mobileNumber?.length &&
						!isPhoneValid(linkState.data.mobileNumber)
					) {
						toastMessage('Invalid WhatsApp number', 'warning')
					}
				},
				onChange: e => {
					const asYouType = new AsYouType(DEFAULT_COUNTRY)
					linkDispatch({
						type: 'update',
						payload: {
							data: {
								mobileNumber: asYouType.input(e.target.value)
							}
						}
					})
				}
			}
		}
	})

	useEffect(() => {
		if (focusOnLoad) form.getRef('mobileNumber').focus()
	}, [])

	return form.renderInput('mobileNumber')
}
