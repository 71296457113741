import { useMemo } from 'react'
import { range } from 'lodash'

const DOTS = '...'

export const usePagination = ({
	totalCount,
	pageSize,
	siblingCount = 1,
	currentPage
}) => {
	return useMemo(() => {
		const totalPageCount = Math.ceil(totalCount / pageSize)

		// Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
		const totalPageNumbers = siblingCount + 2

		/*
			Case 1:
			If the number of pages is less than the page numbers we want to show in our
			paginationComponent, we return the range [1..totalPageCount]
		*/
		if (totalPageNumbers >= totalPageCount) {
			return range(1, totalPageCount)
		}

		/*
			Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
		*/
		const leftSiblingIndex = Math.max(currentPage - 1, 1)
		const rightSiblingIndex = Math.min(currentPage + 2, totalPageCount)

		/*
			We do not show dots just when there is just one page number to be inserted
			between the extremes of sibling and the page limits
			i.e 1 and totalPageCount.
			Hence, we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
		*/
		const shouldShowLeftDots = leftSiblingIndex >= 4
		const shouldShowRightDots = rightSiblingIndex + 1 < totalPageCount

		const firstPageIndex = 1
		const lastPageIndex = totalPageCount

		/*
			Case 2: No left dots to show, but rights dots to be shown
		*/
		if (!shouldShowLeftDots && shouldShowRightDots) {
			let leftItemCount = 2 * siblingCount + 1
			let leftRange = range(1, leftItemCount)

			return [...leftRange, DOTS, totalPageCount]
		}

		/*
			Case 3: No right dots to show, but left dots to be shown
		*/
		if (shouldShowLeftDots && !shouldShowRightDots) {
			let rightItemCount = 2 * siblingCount - 2
			let rightRange = range(
				totalPageCount - rightItemCount - 1,
				totalPageCount + 1
			)
			return [firstPageIndex, DOTS, ...rightRange]
		}

		/*
			Case 4: Both left and right dots to be shown
		*/
		if (shouldShowLeftDots && shouldShowRightDots) {
			let middleRange = range(leftSiblingIndex, rightSiblingIndex)

			return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex]
		}

		/**
		 * Case 5: No left dots to show
		 */
		if (!shouldShowLeftDots && !shouldShowRightDots) {
			return range(1, totalPageCount)
		}
	}, [totalCount, pageSize, siblingCount, currentPage])
}
