import React from 'react'
import styles from './NumeredTextarea.module.scss'

export class NumeredTextarea extends React.PureComponent {
	static defaultProps = {
		highlightedLines: []
	}

	state = {
		measures: {}
	}

	_init = () => {
		if (this.ref) {
			this.setState({
				lineCount: this.ref.value.split(/\n/).length
			})
		}
	}

	_renderLineNumbers = () => {
		const { highlightedLines } = this.props
		const lines = []
		let classes
		let props

		for (let i = 1; i <= this.state.lineCount; i++) {
			classes = [styles.lineNo]
			props = {}

			if (highlightedLines.map(item => item.line).includes(i)) {
				const error = highlightedLines.find(item => item.line === i)
				if (error.message) props.title = error.message

				classes.push(styles.highlighted, styles[error.type] || styles['error'])
			}

			lines.push(
				<span key={i} className={classes.join(' ')} {...props}>
					{i}
				</span>
			)
		}

		return lines
	}

	componentDidMount = () => {
		const { minHeight } = this.props
		const height = window.getComputedStyle(this.ref).getPropertyValue('height')

		this.lineHeight = parseFloat(
			window.getComputedStyle(this.ref).getPropertyValue('line-height')
		)
		this.initialHeight = !isNaN(height) ? parseInt(height) : minHeight
		setTimeout(this._init, 500)
	}

	render = () => {
		const props = { ...this.props }
		const allowedProps = {}
		const textareaStyles = {}

		for (const [key, value] of Object.entries(props)) {
			if (
				![
					'minHeight',
					'highlightedLines',
					'onBlur',
					'onKeyUp',
					'onChange'
				].includes(key)
			) {
				allowedProps[key] = value
			}
		}

		if (
			this.initialHeight !== undefined &&
			this.lineHeight !== undefined &&
			this.state.lineCount !== undefined
		) {
			textareaStyles.height = Math.max(
				this.initialHeight,
				this.state.lineCount * this.lineHeight
			)
		}

		return (
			<div className={styles.wrapper}>
				<div className={styles.lines}>
					<div className={styles.numbers} style={textareaStyles}>
						{this._renderLineNumbers()}
					</div>
				</div>
				<textarea
					wrap="off"
					ref={ref => (this.ref = ref)}
					onKeyUp={() => {
						if (props.onKeyUp) props.onKeyUp(this.ref)

						this._init()
					}}
					onBlur={() => {
						if (props.onBlur) props.onBlur(this.ref)

						setTimeout(this._init, 500)
					}}
					onChange={() => {
						if (props.onChange) props.onChange(this.ref)

						setTimeout(this._init, 500)
					}}
					className={styles.textarea}
					style={textareaStyles}
					{...allowedProps}
				/>
			</div>
		)
	}
}
