import React from 'react'
import { CgTrending } from 'react-icons/cg'
import { useReportDispatch, useReportState } from '../../contexts/report'
import styles from './Reports.module.scss'

export const ToggleShouldDisplayChartsButton = () => {
	const reportState = useReportState()
	const reportDispatch = useReportDispatch()
	const wrapperStyles = [styles.singleButtonWrapper]

	function onClick() {
		reportDispatch({
			type: 'update',
			payload: {
				data: {
					shouldDisplayCharts: !reportState.data?.shouldDisplayCharts
				}
			}
		})
	}

	if (reportState.data?.shouldDisplayCharts) {
		wrapperStyles.push(styles.active)
	}

	return (
		<div onClick={onClick} className={wrapperStyles.join(' ')}>
			<CgTrending />
		</div>
	)
}
