import React from 'react'
import { LINK_TYPE_LIST } from './utils'
import { useLinkState } from '../../contexts/link'
import { FunnelMap } from './FunnelMap/FunnelMap'
import { WhatsAppSources } from './WhatsAppSources'

export const LinkTypeOptions = ({ pageState }) => {
	const linkState = useLinkState()

	switch (linkState.data?.linkType) {
		case LINK_TYPE_LIST[0].id:
			return <WhatsAppSources pageState={pageState} />
		case LINK_TYPE_LIST[1].id:
			return <FunnelMap type="getresponse" pageState={pageState} />
		case LINK_TYPE_LIST[2].id:
			return <FunnelMap type="customer-support" pageState={pageState} />
		case LINK_TYPE_LIST[3].id:
			return <FunnelMap type="traffic-source" pageState={pageState} />
		case LINK_TYPE_LIST[4].id:
			return <FunnelMap type="organic" pageState={pageState} />
		default:
			return null
	}
}
