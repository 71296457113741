import React, { useState } from 'react'
import { RequestUtil } from '../../shared'
import { Button } from '../Button/Button'
import { copyStringToClipboard, LINK_TYPE_LIST } from '../../pages/Links/utils'
import { useLinkState } from '../../contexts/link'
import { toastMessage } from '..'

export const BitlyButton = ({ getLink, label = 'Get Bitly' }) => {
	const [isShorteningLink, setIsShorteningLink] = useState()
	const linkState = useLinkState()

	if (
		![
			'telegram',
			'instagram',
			'facebook',
			'youtube',
			'podcast',
			'partner'
		].includes(linkState.data?.trafficSource?.value) &&
		linkState.data?.linkType !== LINK_TYPE_LIST[1].id
	)
		return null

	async function shortenLink() {
		setIsShorteningLink(true)
		const result = await RequestUtil.post('/link/shorten', { url: getLink() })

		if (result.status === 200) {
			copyStringToClipboard(result.data.link)
		} else if (result.status === 429) {
			toastMessage('Bitly API limit exceeded')
		} else {
			toastMessage('Oops, something went wrong')
		}
		setIsShorteningLink(false)
	}
	return (
		<Button onClick={shortenLink} loading={isShorteningLink} size="small">
			{label}
		</Button>
	)
}
