/**
 * Utils > Object
 */

export const ObjectUtil = {
	isFunction(obj) {
		return typeof obj === 'function'
	}
}

export function replaceKeys(map, obj) {
	return Object.keys(obj).reduce(
		(acc, key) => ({
			...acc,
			...{ [map[key] || key]: obj[key] }
		}),
		{}
	)
}
