import { FormComponent } from '../../shared'
import { useLinkState, useLinkDispatch } from '../../contexts/link'
import { useEffect } from 'react'
import { isValidCheckoutUrl } from './utils'
import { toastMessage } from '../../components'

export const CheckoutUrlInput = ({ focusOnLoad }) => {
	const linkState = useLinkState()
	const linkDispatch = useLinkDispatch()
	const form = new FormComponent({
		checkoutUrl: {
			label: 'Checkout URL',
			props: {
				onBlur: e => {
					const value = e.target.value
					if (value !== '' && !isValidCheckoutUrl(value))
						toastMessage('Invalid checkout URL', 'warning')
				},
				onChange: e => {
					linkDispatch({
						type: 'update',
						payload: {
							data: {
								checkoutUrl: e.target.value
							}
						}
					})
				},
				value: linkState.data?.checkoutUrl ?? '',
				type: 'text'
			}
		}
	})

	useEffect(() => {
		if (focusOnLoad) form.getRef('checkoutUrl').focus()
	}, [])

	return form.renderInput('checkoutUrl')
}
