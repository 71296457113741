import React, { useEffect, useMemo, useState } from 'react'
import { toastMessage } from '../../components'
import { FormComponent } from '../../shared'
import { useLeadState } from '../../contexts/lead'
import { LeadUtil } from '../../shared/lead'

export const CrmSegments = ({
	onChangeSegment,
	selectedSegment,
	setIsLoading,
	segmentQueryParams = {}
}) => {
	const [segments, setSegments] = useState(null)
	const leadState = useLeadState()
	const leadUtil = new LeadUtil()

	useEffect(() => {
		setIsLoading(true)

		leadUtil
			.getSegments(segmentQueryParams)
			.then(({ status, data }) => {
				if (status !== 200 || data.success === false) {
					toastMessage('Error getting segments', 'error')
					setIsLoading(false)
					return
				}

				setSegments(Object.values(data))

				setIsLoading(false)
			})
			.catch(() => {
				setIsLoading(false)
				setSegments(null)
			})
	}, [])

	return useMemo(() => {
		const options = {
			segments: {
				label: 'Segment',
				props: {
					type: 'select',
					onChange: event => {
						onChangeSegment(event)
					},
					value: leadState?.data?.segment ?? selectedSegment ?? {}
				},
				options: segments?.map(segment => ({
					label: segment.name,
					value: segment.searchContactId
				}))
			}
		}

		const form = new FormComponent(options)

		return <>{form.renderInput('segments')}</>
	}, [segments, selectedSegment])
}
