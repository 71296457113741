import { FormComponent } from '../../shared'
import { useLinkState, useLinkDispatch } from '../../contexts/link'
import { useReviewState } from '../../contexts/review'

export const ReviewInput = () => {
	const linkState = useLinkState()
	const linkDispatch = useLinkDispatch()
	const reviewState = useReviewState()
	const reviewOptions = reviewState.data.map(review => ({
		label: review.title,
		value: review.id
	}))
	const form = new FormComponent({
		reviewId: {
			label: 'Book',
			props: {
				type: 'select',
				value: reviewOptions.filter(
					reviewOption => reviewOption.value === linkState.data.reviewId
				),
				onChange: option => {
					linkDispatch({
						type: 'update',
						payload: {
							data: {
								reviewId: option.value
							}
						}
					})
				}
			},
			options: reviewOptions
		}
	})

	return form.renderInput('reviewId')
}
