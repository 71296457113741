import React from 'react'
import { isEqual } from 'lodash'
import { ACCOUNT_ADD_OPTION_LIST } from '../pages/Links/utils'

const AccountStateContext = React.createContext()
const AccountDispatchContext = React.createContext()

function initState(dispatch, state) {
	if (state?.isInitialized) return

	dispatch({
		type: 'init',
		payload: {
			isInitialized: true,
			data: {
				accountAddOption: ACCOUNT_ADD_OPTION_LIST[0].id
			}
		}
	})
}

function accountReducer(state, action) {
	const newState =
		action.type === 'init'
			? action.payload
			: {
					...state,
					data: {
						...state.data,
						...action.payload.data
					}
			  }

	if (isEqual(state, newState)) return state

	switch (action.type) {
		case 'init':
		case 'update':
			return newState

		default:
			throw new Error(`Unhandled action type: ${action.type}`)
	}
}

function AccountProvider({ children }) {
	const [state, dispatch] = React.useReducer(accountReducer, {})
	return (
		<AccountStateContext.Provider value={state}>
			<AccountDispatchContext.Provider value={dispatch}>
				{children}
			</AccountDispatchContext.Provider>
		</AccountStateContext.Provider>
	)
}

function useAccountState() {
	const context = React.useContext(AccountStateContext)
	if (context === undefined) {
		throw new Error('useAccountState must be used within a AccountProvider')
	}
	return context
}

function useAccountDispatch() {
	const context = React.useContext(AccountDispatchContext)
	if (context === undefined) {
		throw new Error('useAccountDispatch must be used within a AccountProvider')
	}
	return context
}

export { AccountProvider, useAccountState, useAccountDispatch, initState }
