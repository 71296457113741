import { RequestUtil } from './'

export class AccountUtil {
	constructor(accountId) {
		if (accountId) {
			this._id = accountId
		}
	}

	setId(id) {
		this._id = id
	}

	getId() {
		return this._id
	}

	async save(data) {
		const res = await RequestUtil.post('/account', data)

		return res
	}

	async _getRemoteInfo(params, settings) {
		const data = await RequestUtil.get(
			'/account',
			{
				accountId: this._id
			},
			settings
		)

		return data
	}

	async getInfo(params = {}, settings) {
		const data = await this._getRemoteInfo(params, settings)

		return data
	}

	async verifyEmail(params, settings) {
		const res = await RequestUtil.get(
			'/account/email/validate',
			{
				accountId: this._id,
				email: params.email
			},
			settings
		)

		return res
	}

	async create(data) {
		const res = await RequestUtil.post('/account/manager', data)

		return res
	}

	getData(params = {}, settings) {
		return RequestUtil.get('/account/list', params, settings).then(
			response => response.data
		)
	}

	createAccountsInBatch(data) {
		return RequestUtil.post('/account/batch-import', data)
	}

	savePermissions(data) {
		return RequestUtil.post('/account/admin', data)
	}

	getEmailFix(email) {
		return RequestUtil.get(`/email/${email}`, {})
	}

	saveFixEmail(data) {
		return RequestUtil.post('/email/fix', data)
	}
}
