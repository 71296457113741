import React, { useState } from 'react'
import { Button, toastMessage } from '../../../components'
import styles from '../Links.module.scss'
import { useLinkState } from '../../../contexts/link'
import { copyStringToClipboard, isValidCheckoutUrl } from '../utils'
import { CheckoutUrlInput } from '../CheckoutUrlInput'
import { useShortLinkState } from '../../../contexts/short-link'
import { TrafficSourceInput } from '../TrafficSourceInput'
import { TrafficSourceTrackingFieldInputs } from '../TrafficSourceInputs'
import { AppLaunchActionInput } from '../AppLaunchActionInput'
import { AppLaunchTargetInput } from '../AppLaunchTargetInput'

export const BuildTSLinkForm = ({ modalControls, node }) => {
	const linkState = useLinkState()
	const shortLinkState = useShortLinkState()
	const [isLoading, setIsLoading] = useState(false)
	const url = linkState.data.link.urls.find(
		url =>
			url.nodeId === node.id &&
			url.trafficSourceId === linkState.data.trafficSource?.value
	)
	const funnel = linkState.data.link.funnels.find(
		funnel => funnel.id === node.funnelId
	)
	const product = linkState.data.link.products.find(
		product => funnel.productId === product.id
	)
	const isFormValid = !!(
		((node.type === 'checkout' &&
			isValidCheckoutUrl(linkState.data.checkoutUrl)) ||
			(node?.type === 'app-launcher' &&
				((linkState.data.appLaunchAction === 'open-review' &&
					linkState.data.reviewId) ||
					(linkState.data.appLaunchAction === 'open-program' &&
						linkState.data.programId))) ||
			['launch-only', 'open-daily-challenge'].includes(
				linkState.data.appLaunchAction
			) ||
			node?.type === 'regular') &&
		url
	)

	async function personalizeLink() {
		setIsLoading(true)
		const link = new URL(url.url)
		const linkParams = new URLSearchParams(link.search)

		if (node.type === 'checkout') {
			linkParams.set('url', linkState.data.checkoutUrl)
		} else if (node.type === 'app-launcher') {
			let params = null

			if (linkState.data.appLaunchAction === 'open-review') {
				params = {
					actions: {
						navigate: {
							screen: 'PreReview',
							params: {
								reviewId: linkState.data.reviewId
							}
						}
					}
				}
			} else if (linkState.data.appLaunchAction === 'open-program') {
				params = {
					actions: {
						navigate: {
							screen: 'ProgramDetails',
							params: {
								programId: linkState.data.programId
							}
						}
					}
				}
			} else if (linkState.data.appLaunchAction === 'open-daily-challenge') {
				params = {
					actions: {
						navigate: {
							screen: 'DailyChallenge'
						}
					}
				}
			}

			if (params) {
				for (const [key, value] of Object.entries(params)) {
					linkParams.set(key, JSON.stringify(value))
				}
			}
		}

		Object.keys(linkState.data.trafficSource.trackingFields || {}).forEach(
			key => {
				const trackingField = linkState.data.trafficSource.trackingFields[key]

				if (trackingField.isToken === false) {
					const value = trackingField.hidden
						? trackingField.value
						: linkState.data.trackingFields?.[
								linkState.data.trafficSource.value
						  ]?.[key] ?? linkState.data.trafficSource.trackingFields[key].value

					linkParams.set(key, value)
				}
			}
		)

		const shortLink = await shortLinkState.instance.shorten({
			url: `${link.protocol}//${link.host}${
				link.pathname
			}?${linkParams.toString()}`,
			domain: node.domain
		})

		if (shortLink) {
			const trackingFields = []

			Object.keys(linkState.data.trafficSource.trackingFields || {}).forEach(
				key => {
					const trackingField = linkState.data.trafficSource.trackingFields[key]

					if (trackingField.isToken !== false) {
						const value = trackingField.hidden
							? trackingField.value
							: linkState.data.trackingFields?.[
									linkState.data.trafficSource.value
							  ]?.[key] ??
							  linkState.data.trafficSource.trackingFields[key].value

						trackingFields.push(`${trackingField.label}=${value}`)
					}
				}
			)

			if (trackingFields.length) {
				shortLink.shortLink += `?${trackingFields.join('&')}`
			}

			copyStringToClipboard(shortLink.shortLink, 'Traffic source link copied!')
			modalControls.closeModal()
		} else {
			toastMessage('Fail trying to create short link', 'error')
		}
	}

	return (
		<>
			<h4>Traffic Source Link</h4>
			<h5>
				{product.name} → {funnel.name} → {node.name}
			</h5>
			<TrafficSourceInput />
			<TrafficSourceTrackingFieldInputs />
			{node.type === 'checkout' ? <CheckoutUrlInput /> : null}
			{node?.type === 'app-launcher' ? (
				<>
					<AppLaunchActionInput />
					<AppLaunchTargetInput />
				</>
			) : null}
			{linkState.data.trafficSource && !url ? (
				<p className={styles.error}>
					No tracking link found for{' '}
					{
						linkState.data.link.trafficSources.find(
							trafficSource =>
								trafficSource.id === linkState.data.trafficSource.value
						).name
					}
					.
				</p>
			) : null}
			<Button
				disabled={isLoading || !isFormValid}
				loading={isLoading}
				onClick={personalizeLink}
			>
				Copy Link
			</Button>
		</>
	)
}
