export const AutoScroll = () => {
	window.setTimeout(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}, 50)

	return null
}
