import React from 'react'
import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'

// Import the plugins
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginImageResize from 'filepond-plugin-image-resize'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

// Register the plugins
registerPlugin(
	FilePondPluginImageExifOrientation,
	FilePondPluginImagePreview,
	FilePondPluginImageResize,
	FilePondPluginFileValidateType,
	FilePondPluginImageTransform,
	FilePondPluginImageCrop,
	FilePondPluginImageValidateSize
)

export class FileUpload extends React.PureComponent {
	getRef = () => {
		return this.ref
	}

	render() {
		return (
			<div ref={ref => (this.ref = ref)}>
				<FilePond {...this.props} />
			</div>
		)
	}
}
