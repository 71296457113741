import React from 'react'
import mainStyles from '../../assets/css/App.module.scss'
import { TabBar } from '../../components/TabBar/TabBar'
import { Link } from 'react-router-dom'
import { useAccountDispatch, useAccountState } from '../../contexts/account'
import { ACCOUNT_ADD_OPTION_LIST } from '../Links/utils'

export const AddOptionsBar = () => {
	const accountState = useAccountState()
	const accountDispatch = useAccountDispatch()
	const isLoaded = accountState?.isInitialized

	function handleChangeAccountAddOption(id) {
		accountDispatch({
			type: 'update',
			payload: {
				data: { accountAddOption: id }
			}
		})
	}

	if (!isLoaded) return null

	return (
		<TabBar>
			{ACCOUNT_ADD_OPTION_LIST.map(optionItem => {
				const itemStyles = [mainStyles.tab]

				if (accountState.data.accountAddOption === optionItem.id)
					itemStyles.push(mainStyles.active)

				return (
					<Link
						key={optionItem.id}
						onClick={handleChangeAccountAddOption.bind(null, optionItem.id)}
						className={itemStyles.join(' ')}
						to={optionItem.path}
					>
						{optionItem.label}
					</Link>
				)
			})}
		</TabBar>
	)
}
