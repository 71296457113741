import { FormComponent } from '../../shared'
import { useLinkState, useLinkDispatch } from '../../contexts/link'
import { VALID_SOURCE_REGEX } from './utils'
import { useEffect } from 'react'

export const OrganicSourceInput = ({ focusOnLoad }) => {
	const linkState = useLinkState()
	const linkDispatch = useLinkDispatch()
	const form = new FormComponent({
		source: {
			label: 'Source',
			required: true,
			valid: () => true,
			props: {
				onBlur: e => {
					const value = e.target.value
					if (value.match(VALID_SOURCE_REGEX) || value === '') {
						linkDispatch({
							type: 'update',
							payload: {
								data: {
									organicSource: value.replace(/^(.*)(-)$/, '$1')
								}
							}
						})
					}
				},
				onChange: e => {
					const value = e.target.value
					if (value.match(VALID_SOURCE_REGEX) || value === '') {
						linkDispatch({
							type: 'update',
							payload: {
								data: {
									organicSource: value.replace(/--+/, '-')
								}
							}
						})
					}
				},
				value: linkState.data?.organicSource ?? '',
				type: 'text'
			}
		}
	})

	useEffect(() => {
		if (focusOnLoad) form.getRef('source').focus()
	}, [])

	return form.renderInput('source')
}
