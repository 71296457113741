import React from 'react'
import closeIcon from '../../assets/images/icons/remove-icon-inverse.png'
import styles from './Reports.module.scss'

export const Wrapper = ({ title, children }) => {
	function handleGoBack() {
		window.history.back()
	}

	return (
		<div className={styles.wrapper}>
			<h3>
				{title}
				<img
					className={styles.closeIcon}
					src={closeIcon}
					onClick={handleGoBack}
				/>
			</h3>
			{children}
		</div>
	)
}
