import { RequestUtil } from './'

export class ProgramUtil {
	constructor(programId) {
		if (programId) {
			this._id = programId
		}
	}

	setId(id) {
		this._id = id
	}

	getId() {
		return this._id
	}

	save(data) {
		return RequestUtil.post('/program', data)
	}

	getData() {
		return RequestUtil.get('/program/list').then(response => response.data)
	}

	getInfo(params, settings) {
		return RequestUtil.get(
			'/program',
			{
				programId: this._id
			},
			settings
		)
	}
}
