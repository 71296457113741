import React, { useState } from 'react'
import { Modal } from '../../../components'
import { useLinkState } from '../../../contexts/link'
import styles from '../Links.module.scss'
import regularNodeIcon from '../../../assets/images/icons/regular-node-icon-inverse.png'
import checkoutNodeIcon from '../../../assets/images/icons/checkout-node-icon-inverse.png'
import { BuildGetResponseLinkForm } from './BuildGetResponseLinkForm'
import { BuildTSLinkForm } from './BuildTSLinkForm'
import { BuildOrganicLinkForm } from './BuildOrganicLinkForm'
import { BuildCustomerSupportLinkForm } from './BuildCustomerSupportLinkForm'

const Nodes = ({ type, nodes, modalControls }) => {
	function buildLink(node) {
		let modalContent

		if (type === 'organic') {
			modalControls.setModalContent(
				<BuildOrganicLinkForm modalControls={modalControls} node={node} />
			)
		} else if (type === 'getresponse') {
			modalContent = (
				<BuildGetResponseLinkForm modalControls={modalControls} node={node} />
			)
		} else if (type === 'customer-support') {
			modalContent = (
				<BuildCustomerSupportLinkForm
					modalControls={modalControls}
					node={node}
				/>
			)
		} else if (type === 'traffic-source') {
			modalContent = (
				<BuildTSLinkForm modalControls={modalControls} node={node} />
			)
		}

		if (modalContent) {
			modalControls.setModalContent(modalContent)
		}

		modalControls.openModal()
	}

	if (!nodes?.length)
		return <p className={styles.noData}>No nodes available for this funnel.</p>

	return (
		<div>
			{nodes.map(node => {
				return (
					<div
						className={[styles.nodeContainer, styles.tile].join(' ')}
						key={`node-${node.id}`}
					>
						<a
							href="#"
							onClick={e => {
								e.preventDefault()
								buildLink(node)
							}}
							className={styles.nodeName}
						>
							<img
								alt=""
								src={
									node.type === 'checkout' ? checkoutNodeIcon : regularNodeIcon
								}
							/>
							{node.name}
						</a>
					</div>
				)
			})}
		</div>
	)
}

const Funnels = ({ type, funnels, modalControls }) => {
	const linkState = useLinkState()

	if (!funnels?.length)
		return (
			<p className={styles.noData}>No funnels available for this product.</p>
		)

	return funnels.map(funnel => {
		let nodes = linkState.data.link.nodes?.filter(
			node => node.funnelId === funnel.id && node.status === 'active'
		)

		if (type === 'organic') {
			nodes = nodes.filter(node => node.type === 'regular')
		}

		return (
			<div key={`funnel-${funnel.id}`} className={styles.funnelContainer}>
				<h4>{funnel.name}</h4>
				<Nodes type={type} nodes={nodes} modalControls={modalControls} />
			</div>
		)
	})
}

export const FunnelMap = ({ pageState, type }) => {
	const linkState = useLinkState()
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [modalContent, setModalContent] = useState(null)

	function closeModal() {
		setIsModalOpen(false)
	}

	function openModal() {
		setIsModalOpen(true)
	}

	return (
		<>
			{type === 'organic' ? (
				<p className={styles.warningBox}>
					⚠️ Links you get from this screen are intended to be used ONLY on
					traffic source-identified funnel pages. Plus, they cannot be used on
					pages that get organic traffic.
				</p>
			) : null}
			{type === 'traffic-source' ? (
				<p className={styles.infoBox}>
					ℹ️ Links you get from this screen are intended to be used as entry
					points for{' '}
					{linkState.data.link.trafficSources
						.map(trafficSource => trafficSource.name)
						.join(', ')}
					. Mind that some traffic sources may not yet be listed here; if you
					don't see the needed traffic source,{' '}
					<a href="mailto:pablo@idiomus.com">request it here</a>.
				</p>
			) : null}
			{linkState.data.link.products.map(product => {
				const funnels = linkState.data.link.funnels?.filter(
					funnel =>
						funnel.productId === product.id && funnel.status === 'active'
				)

				if (!funnels?.length) return null

				return (
					<div
						key={`product-${product.id}`}
						className={styles.productContainer}
					>
						<h4>{product.name}</h4>
						<Funnels
							type={type}
							funnels={funnels}
							pageState={pageState}
							modalControls={{
								setModalContent: setModalContent,
								closeModal: closeModal,
								openModal: openModal
							}}
						/>
					</div>
				)
			})}
			<Modal isOpen={isModalOpen} onClose={closeModal}>
				{modalContent}
			</Modal>
		</>
	)
}
