import { RequestUtil } from './'
import config from '../config.json'
export class ReportUtil {
	getTrafficSourceStats(filters) {
		return RequestUtil.get('/report/traffic-source/stats', filters).then(
			response => response.data
		)
	}

	getAdsInfo(filters) {
		return RequestUtil.get('/report/ad/info', filters).then(
			response => response.data
		)
	}

	getCampaignHistory(filters) {
		return RequestUtil.get('/report/campaign/history', filters).then(
			response => response.data
		)
	}

	toggleAdStatus(filters) {
		return RequestUtil.post('/report/ad/status', filters).then(
			response => response.data
		)
	}

	assessCampaignAds(filters) {
		return RequestUtil.post(
			'/fbads/ads/assess',
			{ ...filters, token: config.aiAPIToken },
			null,
			config.aiAPIBaseUrl
		).then(response => response.data)
	}
}
