import React, { useEffect, useMemo, useState } from 'react'
import { FormComponent } from '../../shared'
import { LeadUtil } from '../../shared/lead'
import { Button, Loader, toastMessage } from '../../components'
import crmStyles from './Crm.module.scss'

export const CrmEvents = ({
	subject,
	queryParams = {},
	isBatch = false,
	onEventTriggerCallback = () => {}
}) => {
	const [events, setEvents] = useState(null)
	const [selectedEvent, setSelectedEvent] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const leadUtil = new LeadUtil()

	const onClick = () => {
		setIsLoading(true)
		if (isBatch) {
			const events = Object.fromEntries(
				selectedEvent?.map(item => [item.label, {}])
			)

			if (
				!confirm(
					'Are you sure you want to trigger the events now? This action cannot be undone.\n\n' +
						'Segment: ' +
						subject +
						'\n\n' +
						'Events: ' +
						Object.keys(events).join(', ')
				)
			) {
				setIsLoading(false)
				return
			}

			leadUtil
				.triggerEvent({
					segment_name: subject,
					events
				})
				.then(({ status, data }) => {
					if (status !== 201) {
						toastMessage("Batch can't be created!", 'error')
						setIsLoading(false)
						return
					}

					toastMessage(data.message, 'success')
					setIsLoading(false)

					onEventTriggerCallback()
				})
				.catch(() => {
					toastMessage('Something went wrong...', 'error')
					setIsLoading(false)
				})

			return
		}

		leadUtil
			.triggerLeadEvent({
				email: subject,
				events: {
					[selectedEvent.label]: {}
				}
			})
			.then(({ status }) => {
				if (status !== 200) {
					toastMessage("Event can't be triggered", 'error')
					setIsLoading(false)
					return
				}

				toastMessage('Event successfully triggered', 'success')
				setIsLoading(false)
			})
			.catch(() => {
				toastMessage('Something went wrong...', 'error')
				setIsLoading(false)
			})
	}

	useEffect(() => {
		setIsLoading(true)

		leadUtil
			.getEvents(queryParams)
			.then(({ status, data }) => {
				if (status !== 200) {
					if (data.success === false && data.result?.body) {
						var { message } = data.result.body || null
					}

					toastMessage(message || 'Error getting events', 'error')
					return
				}

				setEvents(Object.values(data))

				setIsLoading(false)
			})
			.catch(() => {
				toastMessage('Error getting events', 'error')
				setEvents(null)
			})

		return () => {
			setEvents(null)
		}
	}, [])

	return useMemo(() => {
		let opt = {
			events: {
				label: 'Events',
				props: {
					type: 'select',
					isMulti: isBatch,
					onChange: option => {
						setSelectedEvent(option)
					}
				},
				options: events?.map(event => ({
					value: event.customEventId,
					label: event.name
				}))
			}
		}

		const form = new FormComponent(opt)

		return (
			<div className={crmStyles.crmEvents}>
				{form.renderInput('events')}
				{selectedEvent && (
					<Button
						classes={crmStyles.triggerEvent}
						to={'#'}
						size={'small'}
						loading={isLoading}
						onClick={onClick.bind(this)}
					>
						Trigger Event
					</Button>
				)}
				<Loader display={isLoading} />
			</div>
		)
	}, [events, isLoading, selectedEvent])
}
