import React from 'react'
import { isEqual } from 'lodash'
import { ReportUtil } from '../shared/report'
import { chartList } from '../pages/Reports/TrafficSourceCampaignHistoryCharts'

const ReportStateContext = React.createContext()
const ReportDispatchContext = React.createContext()
const reportService = new ReportUtil()

function initState(dispatch, state) {
	if (state?.isInitialized) return

	dispatch({
		type: 'init',
		payload: {
			data: {
				selectedCharts: new Set().add(chartList[0].id),
				filteredCampaignIds: []
			},
			instance: reportService,
			isInitialized: true
		}
	})
}

function reportReducer(state, action) {
	const newState =
		action.type === 'init'
			? action.payload
			: {
					...state,
					data: {
						...state.data,
						...action.payload.data
					}
				}

	if (isEqual(state, newState)) return state

	switch (action.type) {
		case 'init':
		case 'update':
			return newState

		default:
			throw new Error(`Unhandled action type: ${action.type}`)
	}
}

function ReportProvider({ children }) {
	const [state, dispatch] = React.useReducer(reportReducer, {})
	return (
		<ReportStateContext.Provider value={state}>
			<ReportDispatchContext.Provider value={dispatch}>
				{children}
			</ReportDispatchContext.Provider>
		</ReportStateContext.Provider>
	)
}

function useReportState() {
	const context = React.useContext(ReportStateContext)
	if (context === undefined) {
		throw new Error('useReportState must be used within a ReportProvider')
	}
	return context
}

function useReportDispatch() {
	const context = React.useContext(ReportDispatchContext)
	if (context === undefined) {
		throw new Error('useReportDispatch must be used within a ReportProvider')
	}
	return context
}

export { ReportProvider, useReportState, useReportDispatch, initState }
