import React, { useEffect, useState } from 'react'
import { TabBar } from '../../components/TabBar/TabBar'
import { LINK_TYPE_LIST } from './utils'
import { useLinkState, useLinkDispatch } from '../../contexts/link'
import mainStyles from '../../assets/css/App.module.scss'

export const LinkType = () => {
	const linkState = useLinkState()
	const linkDispatch = useLinkDispatch()
	const [isDefaultSet, setIsDefaultSet] = useState(false)

	function handleChangeLinkType(type) {
		// todo: reset other states... ?
		linkDispatch({
			type: 'update',
			payload: {
				data: { linkType: type }
			}
		})
	}

	useEffect(() => {
		if (!isDefaultSet) return
		linkDispatch({
			type: 'update',
			payload: {
				data: { linkType: LINK_TYPE_LIST[0].id }
			}
		})
	}, [isDefaultSet])

	useEffect(() => {
		if (!linkState.isInitialized || isDefaultSet) return
		setIsDefaultSet(true)
	}, [linkState])

	if (!linkState.data?.linkType) return null

	return (
		<TabBar>
			{LINK_TYPE_LIST.map(linkTypeItem => {
				const itemStyles = [mainStyles.tab]

				if (linkState.data?.linkType === linkTypeItem.id)
					itemStyles.push(mainStyles.active)

				return (
					<button
						onClick={handleChangeLinkType.bind(this, linkTypeItem.id)}
						key={linkTypeItem.id}
						className={itemStyles.join(' ')}
					>
						{linkTypeItem.label}
					</button>
				)
			})}
		</TabBar>
	)
}
