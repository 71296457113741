import { StorageUtil, RequestUtil, StorageKeys, DeviceUtil } from './'

export class AuthUtil {
	getDeviceInstance() {
		if (!this._device) this._device = new DeviceUtil()

		return this._device
	}

	run(email, password) {
		const uuid = this.getDeviceInstance().getUUID()

		return RequestUtil.post('/auth', {
			email: email,
			password: password,
			uuid: uuid || null,
			hash: null,
			metadata: null
		}).then(result => {
			if ([200, 201].includes(result.status)) {
				const json = { ...result.data }
				const uuid = json.uuid

				if (!json.account || json.account.admin !== true) {
					throw new Error('Only admin users can log in')
				}

				delete json['uuid']
				StorageUtil.setItem(StorageKeys.UUID, uuid, true)
				StorageUtil.setItem(StorageKeys.AUTH_DATA, json, true)
			}

			return result
		})
	}
}
