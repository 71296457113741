import React, { useState } from 'react'
import { Page, toastMessage } from '../../components'
import history from '../../history'
import { FormComponent, FormUtil, AuthUtil } from '../../shared'
import mainStyles from '../../assets/css/App.module.scss'

export const Login = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const form = new FormComponent({
		email: {
			label: 'Email',
			required: true,
			valid: value => {
				return FormUtil.isEmail(value)
			},
			props: {
				onChange: e => {
					setEmail(e.target.value)
				},
				value: email,
				type: 'email'
			}
		},
		password: {
			label: 'Password',
			required: true,
			valid: value => {
				return value.length >= 5
			},
			props: {
				onChange: e => {
					setPassword(e.target.value)
				},
				value: password,
				type: 'password',
				onKeyPress: e => {
					if (e.key === 'Enter') login()
				}
			}
		},
		auth: {
			label: 'Login',
			props: {
				type: 'button',
				onClick: login
			}
		}
	})

	function login() {
		if (form.valid()) {
			const auth = new AuthUtil()

			setIsLoading(true)

			auth
				.run(email, password)
				.then(result => {
					switch (result.status) {
						case 200:
						case 201:
							history.push('/welcome')
							break

						case 403:
						default:
							toastMessage('Access denied!', 'error')
							setIsLoading(false)
							break
					}
				})
				.catch(error => {
					const message = error?.message || 'Oops, something went wrong!'
					toastMessage(message)
					setIsLoading(false)
				})
		}
	}

	function render() {
		return (
			<>
				<div className={mainStyles.container}>
					<div className={mainStyles.wrapper}>
						<h3>Sign In</h3>
						{form.renderInput('email')}
						{form.renderInput('password')}
						{form.renderButton('auth', {
							loading: isLoading
						})}
					</div>
				</div>
			</>
		)
	}

	return <Page checkAuth={false} render={render} />
}
