import React from 'react'
import { Link } from 'react-router-dom'
import { Page, Button, Forbidden } from '../../components'
import mainStyles from '../../assets/css/App.module.scss'
import { useReviewState } from '../../contexts/review'
import { padStart } from 'lodash'

export const ReviewList = () => {
	const reviewState = useReviewState()
	const isLoaded = !!reviewState?.isInitialized

	function render(pageState) {
		if (!pageState.authData.account.permissions?.includes('reviewList')) {
			return <Forbidden />
		}

		return (
			<>
				<div className={mainStyles.container}>
					<div className={mainStyles.wrapper}>
						<h3>
							Books
							{pageState.authData.account.permissions?.includes(
								'reviewManagement'
							) ? (
								<span>
									<Button size="small" to="/review/save">
										Create
									</Button>
								</span>
							) : null}
						</h3>

						{reviewState.data?.length ? (
							<table>
								<thead>
									<tr>
										<td>Title</td>
										<td>Length</td>
										<td>Status</td>
									</tr>
								</thead>
								<tbody>
									{reviewState.data.map(item => {
										return (
											<tr key={item.id}>
												<td>
													<Link
														className={mainStyles.link}
														to={`/review/save/${item.id}`}
													>
														{item.title}
													</Link>
												</td>
												<td>
													00:
													{padStart(
														Math.ceil(item.version.length / 60).toString(),
														2,
														'0'
													)}
												</td>
												<td>{item.version.status}</td>
											</tr>
										)
									})}
									<tr />
								</tbody>
							</table>
						) : isLoaded ? (
							<p>No books were found.</p>
						) : null}
					</div>
				</div>
			</>
		)
	}

	return <Page render={render} isLoaded={isLoaded} />
}
