import React, { useEffect, useState, useMemo } from 'react'
import { useReportDispatch, useReportState } from '../../contexts/report'
import { format, differenceInDays, isToday } from 'date-fns'
import { Line } from '@nivo/line'
import styles from './Reports.module.scss'
import { minBy, maxBy, cloneDeep } from 'lodash'
import config from '../../config.json'
import Colors from './Colors'
import { TabBar } from '../../components/TabBar/TabBar'

const CustomSymbol = ({ size, color, borderWidth, borderColor }) => (
	<g>
		<circle
			fill="#fff"
			r={size / 2}
			strokeWidth={borderWidth}
			stroke={borderColor}
		/>
		<circle
			r={size / 5}
			strokeWidth={borderWidth}
			stroke={borderColor}
			fill={color}
			fillOpacity={0.35}
		/>
	</g>
)

const chartsHeight = 300
const maxChartWidth = 710
const minChartWidth = 360
const chartItemSpacing = 100
export const chartList = [
	{
		id: 'spendProfitLoss',
		label: 'Spend, Profit & Loss'
	},
	{
		id: 'leadsICsPurchases',
		label: 'Leads, ICs & Purchases'
	},
	{
		id: 'lcvrICVRpcvr',
		label: 'Conversion Rates'
	},
	{
		id: 'roas',
		label: 'ROAS'
	},
	{
		id: 'costPerAction',
		label: 'Cost per Action'
	},
	{
		id: 'cpmCPC',
		label: 'CPM & CPC'
	},
	{
		id: 'ctr',
		label: 'CTR'
	}
]

const commonLineProperties = {
	height: chartsHeight,
	margin: { top: 60, right: 20, bottom: 60, left: 20 },
	animate: true,
	pointSize: 14,
	pointBorderWidth: 1,
	pointBorderColor: {
		from: 'color',
		modifiers: [['darker', 0.3]]
	},
	pointSymbol: CustomSymbol,
	legends: [
		{
			anchor: 'bottom',
			direction: 'row',
			itemCount: 3,
			itemWidth: 50,
			itemHeight: 36,
			itemsSpacing: 30,
			translateY: 60,
			symbolSize: 12,
			symbolShape: 'circle',
			symbolBorderColor: 'rgba(0, 0, 0, 1)'
		}
	],
	axisLeft: null,
	enableGridY: false,
	xScale: { type: 'point' },
	yScale: {
		type: 'linear',
		stacked: false
	},
	yFormat: value => `${value}`,
	enableArea: true,
	areaOpacity: 0.05,
	enableSlices: 'x',
	curve: 'natural'
}

export const TrafficSourceCampaignHistoryCharts = () => {
	const reportState = useReportState()
	const reportDispatch = useReportDispatch()
	const [data, setData] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const canDisplayCharts =
		reportState.data.shouldDisplayCharts &&
		reportState.data.selectedFunnel?.id &&
		reportState.data.filteredCampaignIds?.length &&
		reportState.data.selectedDateRange?.[0] &&
		reportState.data.selectedDateRange?.[1] &&
		differenceInDays(
			reportState.data.selectedDateRange[1],
			reportState.data.selectedDateRange[0]
		) >= 0 &&
		!isToday(reportState.data.selectedDateRange[1]) &&
		differenceInDays(
			reportState.data.selectedDateRange[1],
			reportState.data.selectedDateRange[0]
		) <= config.maxTrafficSourceReportDays

	const ctrData = useMemo(() => formatVisitorData(['ctr']), [data])
	const cpmCPCData = useMemo(
		() =>
			formatVisitorData(['cpm', 'cpc'], function (value, data, id) {
				switch (id) {
					case 'cpc':
						return data.clicks ? (data.spend / data.clicks).toFixed(2) : null

					default:
						return value?.toFixed(2) ?? null
				}
			}),
		[data]
	)
	const costPerActionData = useMemo(
		() =>
			formatVisitorData(['cpl', 'cpic', 'cpp'], function (_, data, id) {
				switch (id) {
					case 'cpl':
						return data.leads ? (data.spend / data.leads).toFixed(2) : null

					case 'cpic':
						return data.ics ? (data.spend / data.ics).toFixed(2) : null

					case 'cpp':
						return data.purchases
							? (data.spend / data.purchases).toFixed(2)
							: null
				}
			}),
		[data]
	)
	const visitorData = useMemo(() => formatVisitorData(), [data])
	const visitorPercentageData = useMemo(
		() => formatVisitorData(['avgPCVR', 'avgICCVR', 'avgLCVR']),
		[data]
	)
	const roasData = useMemo(
		() =>
			formatVisitorData(['roas'], function (value) {
				return value?.toFixed(2) ?? null
			}),
		[data]
	)
	const profitLossData = useMemo(() => formatProfitLossData(), [data])
	const profitLossChartMin = useMemo(() => {
		if (!profitLossData) return 0

		return (
			Math.floor(
				(minBy(
					profitLossData.history.find(r => r.id === 'loss').data,
					function (o) {
						return o.y
					}
				)?.y ?? 0) / 1000
			) * 1000
		)
	}, [profitLossData])
	const profitLossChartMax = useMemo(() => {
		if (!profitLossData) return 1

		return (
			Math.ceil(
				Math.max(
					(maxBy(
						profitLossData.history.find(r => r.id === 'profit').data,
						function (o) {
							return o?.y
						}
					)?.y ?? 0) / 1000,
					(maxBy(
						profitLossData.history.find(r => r.id === 'spend').data,
						function (o) {
							return o?.y
						}
					)?.y ?? 0) / 1000
				)
			) * 1000
		)
	}, [profitLossData])
	const totalSpend = useMemo(() => {
		if (!data) return

		return parseFloat(data.reduce((a, b) => a + b.spend, 0).toFixed(2))
	}, [data])
	const totalRevenue = useMemo(() => {
		if (!data) return

		return parseFloat(data.reduce((a, b) => a + b.revenue, 0).toFixed(2))
	}, [data])
	const totalProfit = useMemo(() => {
		if (!data) return

		return parseFloat(data.reduce((a, b) => a + b.profit, 0).toFixed(2))
	}, [data])
	const totalClicks = useMemo(() => {
		if (!data) return

		return data.reduce((a, b) => a + b.clicks, 0)
	}, [data])

	function formatDate(date, dateFormat = 'D') {
		return format(new Date(date), dateFormat)
	}

	function formatProfitLossData() {
		if (!data) return

		const profitData = data.map(d => ({
			x: formatDate(d.date),
			y: d.profit ? parseFloat(d.profit.toFixed(2)) : null
		}))
		const positiveProfitData = []
		const negativeProfitData = []
		let lastPositiveIndex = -1
		let lastNegativeIndex = -1

		for (let i = profitData.length - 1; i >= 0; i--) {
			if (
				lastPositiveIndex === -1 &&
				(profitData[i].y > 0 || profitData[i].y === null)
			) {
				lastPositiveIndex = i
			}

			if (lastNegativeIndex === -1 && profitData[i].y < 0) {
				lastNegativeIndex = i
			}

			if (lastPositiveIndex !== -1 && lastNegativeIndex !== -1) {
				break
			}
		}

		const positiveDaysSpend = []

		for (let i = 0; i < profitData.length; i++) {
			if (profitData[i].y >= 0) {
				positiveDaysSpend.push(data[i].spend)
			}

			if (profitData[i].y < 0) {
				negativeProfitData.push(profitData[i])
			} else if (
				i <= lastNegativeIndex ||
				(i === lastNegativeIndex + 1 && i < profitData.length)
			) {
				if (profitData[i - 1]?.y < 0 || profitData[i + 1]?.y < 0) {
					negativeProfitData.push(profitData[i])
				} else {
					negativeProfitData.push({
						...profitData[i],
						y: null
					})
				}
			}

			if (i <= lastPositiveIndex) {
				positiveProfitData.push({
					...profitData[i],
					y: profitData[i].y < 0 ? null : profitData[i].y
				})
			}
		}

		if (negativeProfitData.length === 1 && negativeProfitData[0].y === null) {
			negativeProfitData[0].y = 0
		}

		return {
			history: [
				{
					id: 'spend',
					data: data.map(d => ({
						x: formatDate(d.date),
						y: parseFloat(d.spend?.toFixed(2))
					}))
				},
				{
					id: 'profit',
					data: positiveProfitData
				},
				{
					id: 'loss',
					data: negativeProfitData
				}
			],
			positiveDaysAvgSpend:
				positiveDaysSpend.length > 1
					? positiveDaysSpend.reduce((a, b) => a + b, 0) /
						positiveDaysSpend.length
					: 0
		}
	}

	function formatVisitorData(
		metrics = ['purchases', 'ics', 'leads'],
		formatFn
	) {
		if (!data) return

		if (!formatFn) formatFn = v => v

		return metrics.map(id => ({
			id,
			data: data.map(history => {
				return {
					x: formatDate(history.date),
					y: formatFn(history[id], history, id)
				}
			})
		}))
	}

	function toggleChart(chartId) {
		const selectedCharts = cloneDeep(reportState.data.selectedCharts)
		selectedCharts[selectedCharts.has(chartId) ? 'delete' : 'add'](chartId)

		reportDispatch({
			type: 'update',
			payload: {
				data: {
					selectedCharts: selectedCharts
				}
			}
		})
	}

	useEffect(() => {
		if (!canDisplayCharts) {
			setData(null)
			return
		}

		setIsLoading(true)

		reportState.instance
			.getCampaignHistory({
				trafficSourceToken: reportState.data.selectedTrafficSourceToken,
				funnelId: reportState.data.selectedFunnel.id,
				dateFrom: format(reportState.data.selectedDateRange[0], 'YYYY-MM-DD'),
				dateTo: format(reportState.data.selectedDateRange[1], 'YYYY-MM-DD'),
				campaignIds: reportState.data.filteredCampaignIds.join(',')
			})
			.then(data => {
				setData(data)
				setIsLoading(false)
			})
	}, [
		canDisplayCharts,
		reportState.data.selectedDateRange,
		reportState.data.selectedTrafficSourceAccount,
		reportState.data.selectedTrafficSourceToken
	])

	if (isLoading) {
		return (
			<div className={styles.chartWrapper}>
				<p>Loading funnel charts...</p>
			</div>
		)
	}

	if (!data) return null

	return (
		<>
			<TabBar>
				{chartList.map(chart => {
					const itemStyles = [styles.toggleButton]

					if (reportState.data?.selectedCharts?.has(chart.id))
						itemStyles.push(styles.active)

					return (
						<button
							onClick={toggleChart.bind(null, chart.id)}
							key={chart.id}
							className={itemStyles.join(' ')}
						>
							{chart.label}
						</button>
					)
				})}
			</TabBar>

			<div className={styles.chartWrapper}>
				{reportState.data.selectedCharts?.has('spendProfitLoss') ? (
					<Line
						{...commonLineProperties}
						margin={{ ...commonLineProperties.margin, left: 60 }}
						yScale={{
							type: 'linear',
							stacked: false,
							min: profitLossChartMin,
							max: profitLossChartMax
						}}
						enableSlices={false}
						useMesh={true}
						enableGridY={true}
						enableArea={false}
						axisLeft={{}}
						markers={[
							{
								axis: 'y',
								value: profitLossData.positiveDaysAvgSpend,
								lineStyle: { stroke: Colors.cyan, strokeWidth: 1 },
								legend: `Good ROAS days daily spend avg: ${
									profitLossData.positiveDaysAvgSpend > 0
										? profitLossData.positiveDaysAvgSpend.toFixed(2)
										: 'unknown'
								}`,
								legendOrientation: 'horizontal',
								textStyle: { fontSize: 12 }
							}
						]}
						curve="linear"
						crosshairType="cross"
						colors={[Colors.blue, Colors.green, Colors.red]}
						width={Math.max(
							minChartWidth,
							Math.min(data.length * chartItemSpacing, maxChartWidth)
						)}
						data={profitLossData.history}
					/>
				) : null}

				{reportState.data.selectedCharts?.has('leadsICsPurchases') ? (
					<Line
						{...commonLineProperties}
						colors={[Colors.red, Colors.orange, Colors.green]}
						width={Math.max(
							minChartWidth,
							Math.min(data.length * chartItemSpacing, maxChartWidth)
						)}
						data={visitorData}
					/>
				) : null}

				{reportState.data.selectedCharts?.has('lcvrICVRpcvr') ? (
					<Line
						{...commonLineProperties}
						colors={[Colors.red, Colors.orange, Colors.green]}
						width={Math.max(
							minChartWidth,
							Math.min(data.length * chartItemSpacing, maxChartWidth)
						)}
						yFormat=">-.2%"
						data={visitorPercentageData}
					/>
				) : null}

				{reportState.data.selectedCharts?.has('roas') ? (
					<Line
						{...commonLineProperties}
						margin={{ ...commonLineProperties.margin, left: 60 }}
						colors={[Colors.purple]}
						width={Math.max(
							minChartWidth,
							Math.min(data.length * chartItemSpacing, maxChartWidth)
						)}
						axisLeft={{}}
						data={roasData}
						curve="stepAfter"
					/>
				) : null}

				{reportState.data.selectedCharts?.has('costPerAction') ? (
					<Line
						{...commonLineProperties}
						colors={[Colors.green, Colors.orange, Colors.red]}
						width={Math.max(
							minChartWidth,
							Math.min(data.length * chartItemSpacing, maxChartWidth)
						)}
						yScale={{
							type: 'linear',
							stacked: false
						}}
						data={costPerActionData}
					/>
				) : null}

				{reportState.data.selectedCharts?.has('cpmCPC') ? (
					<Line
						{...commonLineProperties}
						colors={[Colors.cyan, Colors.pink]}
						width={Math.max(
							minChartWidth,
							Math.min(data.length * chartItemSpacing, maxChartWidth)
						)}
						yScale={{
							type: 'linear',
							stacked: false
						}}
						data={cpmCPCData}
					/>
				) : null}

				{reportState.data.selectedCharts?.has('ctr') ? (
					<Line
						{...commonLineProperties}
						colors={[Colors.yellow]}
						width={Math.max(
							minChartWidth,
							Math.min(data.length * chartItemSpacing, maxChartWidth)
						)}
						yFormat=">-.2%"
						data={ctrData}
					/>
				) : null}
				<div>
					<table>
						<thead>
							<tr>
								<th colSpan={2}>General stats</th>
							</tr>
						</thead>
						<tbody style={{ fontSize: 14 }}>
							<tr>
								<th>Spend</th>
								<td>{totalSpend}</td>
							</tr>
							<tr>
								<th>Revenue</th>
								<td>{totalRevenue}</td>
							</tr>
							<tr>
								<th>Clicks</th>
								<td>{totalClicks}</td>
							</tr>

							<tr>
								<th>Profit/loss</th>
								<td
									style={{
										color: totalProfit < 0 ? Colors.red : Colors.green
									}}
								>
									{totalProfit}
								</td>
							</tr>
							<tr>
								<th>ROAS</th>
								<td>{(totalRevenue / totalSpend).toFixed(2)}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</>
	)
}
