import React from 'react'
import mainStyles from '../../assets/css/App.module.scss'

export const Forbidden = () => {
	return (
		<div className={mainStyles.container}>
			<div className={mainStyles.wrapper}>
				<h3>Access Forbidden</h3>
				<p>You don&apos;t have permission to access {document.location.pathname}.</p>
				<p>
					Should you believe this might be incorrect, please reach out Pablo or
					Felipe.
				</p>
			</div>
		</div>
	)
}
