import React from 'react'
import { useLinkState } from '../../contexts/link'
import { ReviewInput } from './ReviewInput'
import { ProgramInput } from './ProgramInput'

export const AppLaunchTargetInput = () => {
	const linkState = useLinkState()

	if (linkState.data.appLaunchAction === 'open-review') return <ReviewInput />

	if (linkState.data.appLaunchAction === 'open-program') return <ProgramInput />

	return null
}
