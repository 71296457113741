import history from '../../history'
import { StorageUtil, StorageKeys } from '../../shared'

export const ValidateAuthSession = () => {
	if (window._validateAuthSessionHandler)
		clearInterval(window._validateAuthSessionHandler)

	window._validateAuthSessionHandler = setInterval(() => {
		const expiration = StorageUtil.getExpiresAt(StorageKeys.AUTH_DATA)

		if (
			expiration &&
			new Date() > new Date(expiration) &&
			window.location.pathname !== '/login'
		) {
			history.push('/login')
		}
	}, 5000)

	return null
}
