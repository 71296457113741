import React from 'react'
import { Forbidden, Page } from '../../components'
import mainStyles from '../../assets/css/App.module.scss'
import { Link } from 'react-router-dom'

export const Reports = () => {
	function render(pageState) {
		if (!pageState.authData.account.permissions?.includes('reports')) {
			return <Forbidden />
		}

		return (
			<div className={mainStyles.container}>
				<div className={mainStyles.wrapper}>
					<h3>Reports</h3>
					<p>Howdy! Pick up a report below to start exploring...</p>
					<div style={{ marginTop: 30 }}>
						<Link className={mainStyles.link} to="/reports/paid-traffic">
							Traffic Source Reports
						</Link>
					</div>
					<div className={mainStyles.resetAll}></div>
				</div>
			</div>
		)
	}

	return <Page render={render} />
}
