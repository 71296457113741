import { useEffect } from 'react'
import {
	useLinkDispatch,
	useLinkState,
	initState as linkInitState,
	updateState as linkUpdateState
} from './contexts/link'
import {
	useLeadDispatch,
	useLeadState,
	initState as leadInitState
} from './contexts/lead'
import {
	useShortLinkDispatch,
	useShortLinkState,
	initState as shortLinkInitState
} from './contexts/short-link'
import {
	useProgramDispatch,
	useProgramState,
	initState as programInitState,
	updateState as programUpdateState
} from './contexts/program'
import {
	useReviewDispatch,
	useReviewState,
	initState as reviewInitState,
	updateState as reviewUpdateState
} from './contexts/review'
import {
	useReportDispatch,
	useReportState,
	initState as reportInitState
} from './contexts/report'
import {
	useAccountDispatch,
	useAccountState,
	initState as accountInitState
} from './contexts/account'

export const StateHandler = () => {
	const linkState = useLinkState()
	const linkDispatch = useLinkDispatch()
	const leadState = useLeadState()
	const leadDispatch = useLeadDispatch()
	const shortLinkState = useShortLinkState()
	const shortLinkDispatch = useShortLinkDispatch()
	const programState = useProgramState()
	const programDispatch = useProgramDispatch()
	const reviewState = useReviewState()
	const reviewDispatch = useReviewDispatch()
	const reportDispatch = useReportDispatch()
	const reportState = useReportState()
	const accountState = useAccountState()
	const accountDispatch = useAccountDispatch()

	useEffect(() => {
		const path = window.location.pathname

		if (['/app/links', '/reports/paid-traffic', '/crm'].includes(path)) {
			if (
				linkState?.isInitialized &&
				leadState?.isInitialized &&
				shortLinkState?.isInitialized
			) {
				linkUpdateState(linkDispatch)
				programUpdateState(programDispatch)
				reviewUpdateState(reviewDispatch)
			} else {
				linkInitState(linkDispatch, linkState)
				leadInitState(leadDispatch, leadState)
				shortLinkInitState(shortLinkDispatch, shortLinkState)
				programInitState(programDispatch, programState)
				reviewInitState(reviewDispatch, reviewState)
			}
		} else if (['/program/list', '/program/save'].includes(path)) {
			if (programState?.isInitialized) {
				programUpdateState(programDispatch)
			} else {
				programInitState(programDispatch, programState)
			}
		} else if (['/review/list', '/review/save'].includes(path)) {
			if (reviewState?.isInitialized) {
				reviewUpdateState(reviewDispatch)
			} else {
				reviewInitState(reviewDispatch, reviewState)
			}
		} else if (
			path.match(/\/account\/list|\/account\/save|\/account\/bulk-import/)
		) {
			if (!accountState?.isInitialized) {
				accountInitState(accountDispatch, accountState)
			}
		}

		if (['/reports', '/reports/paid-traffic'].includes(path)) {
			reportInitState(reportDispatch, reportState)
		}
	}, [window.location.pathname])

	return null
}
