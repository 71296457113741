import React from 'react'
import { Link } from 'react-router-dom'
import { Page, Button, Forbidden } from '../../components'
import mainStyles from '../../assets/css/App.module.scss'
import { useProgramState } from '../../contexts/program'

export const ProgramList = () => {
	const programState = useProgramState()
	const isLoaded = !!programState?.isInitialized

	function render(pageState) {
		if (!pageState.authData.account.permissions?.includes('programList')) {
			return <Forbidden />
		}

		return (
			<div className={mainStyles.container}>
				<div className={mainStyles.wrapper}>
					<h3>
						Programs
						{pageState.authData.account.permissions?.includes(
							'programManagement'
						) ? (
							<span>
								<Button size="small" to="/program/save">
									Create
								</Button>
							</span>
						) : null}
					</h3>

					{programState.data?.length ? (
						<table>
							<thead>
								<tr>
									<td>Name</td>
									<td>Status</td>
								</tr>
							</thead>
							<tbody>
								{programState.data.map(item => {
									return (
										<tr key={item.id}>
											<td>
												<Link
													className={mainStyles.link}
													to={`/program/save/${item.id}`}
												>
													{item.name}
												</Link>
											</td>
											<td>{item.status}</td>
										</tr>
									)
								})}
								<tr />
							</tbody>
						</table>
					) : isLoaded ? (
						<p>No programs were found.</p>
					) : null}
				</div>
			</div>
		)
	}

	return <Page render={render} isLoaded={isLoaded} />
}
