import { useEffect, useState } from 'react'
import { useAccountState } from '../../contexts/account'
import { FormComponent } from '../../shared'

export const SearchInput = ({ focusOnLoad, onChange }) => {
	const accountState = useAccountState()
	const [keywords, setKeywords] = useState(
		accountState.data?.accountListKeywords ?? ''
	)
	const form = new FormComponent({
		keywords: {
			label: 'Search',
			props: {
				placeholder: 'Type name, email or WhatsApp number',
				type: 'text',
				value: keywords,
				onChange: e => {
					setKeywords(e.target.value)
					onChange(e.target.value)
				}
			}
		}
	})

	useEffect(() => {
		if (focusOnLoad) form.getRef('keywords').focus()
	}, [])

	return form.renderInput('keywords')
}
