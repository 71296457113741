import { useReportDispatch, useReportState } from '../../contexts/report'
import { FormComponent } from '../../shared'

export const CampaignFilterInput = () => {
	const reportState = useReportState()
	const reportDispatch = useReportDispatch()
	const form = new FormComponent({
		campaignFilter: {
			label: 'Campaign Filter',
			props: {
				type: 'text',
				value: reportState.data?.campaignFilter ?? '',
				onChange: e => {
					reportDispatch({
						type: 'update',
						payload: {
							data: {
								campaignFilter: e.target.value,
								shouldDisplayCharts: false
							}
						}
					})
				}
			}
		}
	})

	return form.renderInput('campaignFilter')
}
