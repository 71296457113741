import React from 'react'
import './Paginator.component.scss'
import { usePagination } from './usePagination'

const Paginator = ({ itemsPerPage, length, currentPage, paginate }) => {
	const paginationRange = usePagination({
		totalCount: length,
		pageSize: itemsPerPage,
		siblingCount: 3,
		currentPage
	})

	// If there are less than 2 times in pagination range we shall not render the component
	if (currentPage === 0 || paginationRange.length < 2) {
		return null
	}

	return (
		<div className="paginator">
			{paginationRange.map((pageNumber, index) => {
				if (pageNumber === '...') {
					return <span key={99 + index}>&#8230;</span>
				}

				return (
					<button
						onClick={() => paginate(pageNumber)}
						className={currentPage === pageNumber ? 'active' : ''}
						key={pageNumber + 'page'}
					>
						{pageNumber}
					</button>
				)
			})}
		</div>
	)
}

export default Paginator
