import React from 'react'
import { Page, toastMessage, Forbidden } from '../../components'
import { FormComponent } from '../../shared'
import {
	ProgramUtil,
	RequestUtil,
	KnowledgeAreaUtil,
	FormUtil,
	ReviewUtil
} from '../../shared'
import config from '../../config.json'
import history from '../../history'
import mainStyles from '../../assets/css/App.module.scss'
import formStyles from '../../assets/css/partials/Form.module.scss'
import styles from './Program.module.scss'

const difficultyData = [1, 2, 3, 4, 5].map(item => {
	return {
		label: item,
		value: item
	}
})

export class Program extends React.PureComponent {
	state = {
		knowledgeAreaData: [],
		reviewData: [],
		inputs: {
			name: '',
			shortDescription: '',
			longDescription: '',
			keypoints: [],
			knowledgeAreaId: '',
			cover: [],
			logo: [],
			reviews: [],
			difficulty: ''
		},
		isSaving: false
	}

	_currentProgramData = {}

	_getIsSaving = () => {
		return this.state.isSaving
	}

	_setIsSaving = (status, callback) => {
		this.setState(
			{
				isSaving: status
			},
			callback
		)
	}

	_handleSave = () => {
		if (!this._getIsSaving() && this.form.valid()) {
			let sequence = 1
			const data = {
				coverHash: this._coverHash || null,
				logoHash: this._logoHash || null,
				id: this.getProgramId(),
				knowledgeAreaId: this.state.inputs.knowledgeArea.value,
				difficulty: this.state.inputs.difficulty.value,
				programReview: this.state.inputs.reviews.map(item => ({
					review: {
						id: item.value,
						sequence: sequence++
					}
				})),
				name: this.state.inputs.name,
				shortDescription: this.state.inputs.shortDescription,
				longDescription: this.state.inputs.longDescription,
				keypoints: this.state.inputs.keypoints.map(item => item.label)
			}

			if (data.programReview.length) {
				let hasErrors = false
				if (!data.shortDescription) {
					toastMessage('Short description is required')
					this.form.setErrorFlag('shortDescription')
					hasErrors = true
				}

				if (!data.longDescription) {
					toastMessage('Long description is required')
					this.form.setErrorFlag('longDescription')
					hasErrors = true
				}

				if (!data.keypoints.length) {
					toastMessage('Keypoints is required')
					this.form.setErrorFlag('keypoint')
					hasErrors = true
				}

				if (hasErrors) return
			}

			this._setIsSaving(true)
			this._program
				.save(data)
				.then(response => {
					if ([200, 201].includes(response.status)) {
						toastMessage('Program saved!')
						this._setIsSaving(false, () => {
							history.push('/program/list')
						})
					} else if (response.status === 409) {
						toastMessage('Name is in use by another program')
						console.log(response, data)
						this._setIsSaving(false)
					} else {
						toastMessage('Oops, something went wrong...', 'error')
						console.log(response, data)
						this._setIsSaving(false)
					}
				})
				.catch(error => {
					this._setIsSaving(false)
					toastMessage('Save program failed.')
					console.log(error, data)
				})
		}
	}

	_cleanUpText = text => {
		return text
			.replace(/\n\s*\n\s*\n/g, '\n\n') // remove triplicated line breaks
			.replace(/[ ]+/g, ' ') // remove duplicated spaces
	}

	_changeHandler = (id, value, callback) => {
		const inputs = { ...this.state.inputs }

		inputs[id] = value
		this.setState(
			{
				inputs: inputs
			},
			callback
		)
	}

	_validateName = value => {
		let valid = true

		if (value.length < 2) {
			valid = false
			toastMessage('Name is too short. Use at least 2 chars.')
		}

		return valid
	}

	_validateShortDescription = value => {
		let valid = true

		if (value.length < 30) {
			valid = false
			toastMessage('ShortDescription is too short. Use at least 30 chars.')
		} else if (value.length > 115) {
			valid = false
			toastMessage('ShortDescription is too long. Use at most 115 chars.')
		}

		return valid
	}

	_validateLongDescription = value => {
		let valid = true

		if (value.split(' ').length < 100) {
			valid = false
			toastMessage('Long Description should have a minimum of 100 words.')
		}

		return valid
	}

	getProgramId = () => {
		const { route } = this.props
		const id = route.match.params.id || this._id

		return !isNaN(id) ? parseInt(id) : null
	}

	_capitalize = text => {
		return text
			.split(' ')
			.map((word, index) =>
				!index || word.length > 2
					? word.charAt(0).toUpperCase() + word.substring(1)
					: word
			)
			.join(' ')
	}

	_preProcessKeypoint = text => {
		return text.map(item => {
			return {
				label: this._capitalize(item.label).replace(/ e /g, ' & '),
				value: this._capitalize(item.label).replace(/ e /g, ' & ')
			}
		})
	}

	_getForm = () => {
		const inputs = {
			name: {
				label: 'Name',
				required: true,
				valid: input => {
					return this._validateName(input)
				},
				props: {
					type: 'text',
					value: this.state.inputs.name,
					onBlur: e => {
						this._changeHandler('name', e.target.value)
					},
					onChange: e => {
						this._changeHandler('name', e.target.value)
					}
				}
			},
			shortDescription: {
				label: 'Short description',
				required: false,
				valid: input => {
					return this._validateShortDescription(input)
				},
				props: {
					type: 'textarea',
					value: this.state.inputs.shortDescription,
					onBlur: e => {
						this._changeHandler('shortDescription', e.target.value)
					},
					onChange: e => {
						this._changeHandler(
							'shortDescription',
							this._cleanUpText(e.target.value)
						)
					}
				}
			},
			longDescription: {
				label: 'Long description',
				required: false,
				valid: input => {
					return this._validateLongDescription(input)
				},
				props: {
					type: 'textarea',
					value: this.state.inputs.longDescription,
					onBlur: e => {
						this._changeHandler(
							'longDescription',
							this._cleanUpText(e.target.value)
						)
					},
					onChange: e => {
						this._changeHandler(
							'longDescription',
							this._cleanUpText(e.target.value)
						)
					}
				}
			},
			knowledgeArea: {
				label: 'Knowledge Area',
				required: [true, 'Choose a knowledge area.'],
				props: {
					type: 'select',
					value: this.state.inputs.knowledgeArea,
					onChange: value => {
						this._changeHandler('knowledgeArea', value)
					}
				},
				options: this.state.knowledgeAreaData
			},
			difficulty: {
				label: 'Difficulty',
				required: [true, 'Choose a difficulty level.'],
				props: {
					type: 'select',
					value: this.state.inputs.difficulty,
					onChange: value => {
						this._changeHandler('difficulty', value)
					}
				},
				options: difficultyData
			},
			keypoint: {
				label: 'Keypoints',
				required: false,
				props: {
					type: 'creatableSelect',
					placeholder: 'type to create a keypoint',
					isMulti: true,
					value: this.state.inputs.keypoints,
					onChange: value => {
						this._changeHandler('keypoints', this._preProcessKeypoint(value))
					}
				},
				options: []
			},
			review: {
				label: 'Books',
				required: false,
				props: {
					type: 'select',
					isMulti: true,
					value: this.state.inputs.reviews,
					onChange: value => {
						this._changeHandler('reviews', value)
					}
				},
				options: this.state.reviewData
			},
			cover: {
				label: 'Cover',
				required: [true, 'Program cover is missing.'],
				props: {
					type: 'file',
					files: this.state.inputs.cover,
					server: {
						load: {
							url: `${config.apiBaseUrl}/program/cover?manager=true&programId=`,
							method: 'GET',
							withCredentials: false,
							headers: this._coverGetHeaders,
							timeout: 20000
						},
						process: {
							url: config.filePondBaseUrl,
							onload: response => {
								if (response) this._coverHash = response
							},
							method: 'POST',
							timeout: 20000
						}
					},
					allowFilesSync: false,
					allowMultiple: false,
					maxFiles: 1,
					onupdatefiles: files => {
						const state = { ...this.state }

						this.setState({
							inputs: {
								...state.inputs,
								cover: [...files]
							}
						})
					},
					allowReplace: true,
					instantUpload: true,
					allowRevert: true,
					allowFileTypeValidation: true,
					acceptedFileTypes: ['image/jpeg'],
					allowImageResize: false,
					allowImageTransform: false,
					allowImageCrop: false,
					allowFileSizeValidation: true,
					maxFileSize: '10MB',
					maxTotalFileSize: '10MB',
					allowImageValidateSize: true,
					imageValidateSizeMinWidth: 1200,
					imageValidateSizeMaxWidth: 1200,
					imageValidateSizeMinHeight: 900,
					imageValidateSizeMaxHeight: 900,
					imageValidateSizeLabelImageSizeTooSmall: 'Incorrect format',
					imageValidateSizeLabelExpectedMinSize: 'Accepted only: 1200 x 900',
					imageValidateSizeLabelExpectedMaxSize: 'Accepted only: 1200 x 900'
				}
			},
			logo: {
				label: 'Logo',
				required: [true, 'Program logo is missing.'],
				props: {
					type: 'file',
					files: this.state.inputs.logo,
					server: {
						load: {
							url: `${config.apiBaseUrl}/program/logo?manager=true&programId=`,
							method: 'GET',
							withCredentials: false,
							headers: this._coverGetHeaders,
							timeout: 20000
						},
						process: {
							url: config.filePondBaseUrl,
							onload: response => {
								if (response) this._logoHash = response
							},
							method: 'POST',
							timeout: 20000
						}
					},
					allowFilesSync: false,
					allowMultiple: false,
					maxFiles: 1,
					onupdatefiles: files => {
						const state = { ...this.state }

						this.setState({
							inputs: {
								...state.inputs,
								logo: [...files]
							}
						})
					},
					allowReplace: true,
					instantUpload: true,
					allowRevert: true,
					allowFileTypeValidation: true,
					acceptedFileTypes: ['image/png'],
					allowImageResize: false,
					allowImageTransform: false,
					allowImageCrop: false,
					allowFileSizeValidation: true,
					maxFileSize: '10MB',
					maxTotalFileSize: '10MB',
					allowImageValidateSize: true,
					imageValidateSizeMinWidth: 800,
					imageValidateSizeMaxWidth: 800,
					imageValidateSizeMinHeight: 1,
					imageValidateSizeMaxHeight: 300,
					imageValidateSizeLabelExpectedMinSize: 'Accepted: 800 x 1~300',
					imageValidateSizeLabelExpectedMaxSize: 'Accepted: 800 x 1~300'
				}
			},
			save: {
				label: 'Save',
				props: {
					type: 'button',
					onClick: this._handleSave
				}
			}
		}

		return new FormComponent(inputs)
	}

	_preFetch = async (data, settings) => {
		const isEditing = !!this.getProgramId()
		const knowledgeArea = new KnowledgeAreaUtil()
		const review = new ReviewUtil()
		const knowledgeAreaData = await knowledgeArea
			.getData({}, settings.request)
			.catch(() =>
				toastMessage('Oops, something went wrong getting knowledgeArea data!', 'error')
			)
		const reviewData = await review
			.getData({}, settings.request)
			.catch(() =>
				toastMessage('Oops, something went wrong getting review data!', 'error')
			)
		const returnData = {
			knowledgeAreaData: knowledgeAreaData.map(item => ({
				value: item.id,
				label: item.name
			})),
			reviewData: reviewData.map(item => ({
				value: item.id,
				label: item.title
			}))
		}

		this._coverGetHeaders = await RequestUtil.getHeaders(
			RequestUtil.GET,
			'/program/cover',
			false
		)
		this._program = new ProgramUtil(this.getProgramId())

		if (isEditing) {
			const programData = await this._program
				.getInfo({}, settings.request)
				.then(result => result.data)
				.catch(() =>
					toastMessage('Oops, something went wrong getting program data!', 'error')
				)

			returnData.programData = programData
		}

		return returnData
	}

	_postFetch = data => {
		const { fetchedData } = data
		const state = { ...this.state }
		const isEditing = !!this.getProgramId()
		const newState = {}

		newState.knowledgeAreaData = fetchedData.knowledgeAreaData
		newState.reviewData = fetchedData.reviewData

		if (isEditing) {
			newState.inputs = { ...state.inputs }
			newState.inputs.name = fetchedData.programData.name
			newState.inputs.shortDescription =
				fetchedData.programData.shortDescription
			newState.inputs.longDescription = fetchedData.programData.longDescription
			newState.inputs.knowledgeArea = newState.knowledgeAreaData.find(
				item => item.value === fetchedData.programData.knowledgeAreaId
			)
			newState.inputs.difficulty = difficultyData.find(
				item => item.value === fetchedData.programData.difficulty
			)
			newState.inputs.keypoints = (fetchedData.programData.keypoints ?? []).map(
				item => ({
					value: item,
					label: item
				})
			)

			this._currentProgramData = fetchedData.programData

			newState.inputs.cover = [
				{
					source: this.getProgramId().toString(),
					options: {
						type: 'local'
					}
				}
			]

			newState.inputs.logo = [
				{
					source: this.getProgramId().toString(),
					options: {
						type: 'local'
					}
				}
			]

			const programReviews = fetchedData.programData.review.map(item => item.id)
			newState.inputs.reviews = newState.reviewData.filter(item =>
				programReviews.includes(item.value)
			)
		}

		this.setState({ ...newState })
	}

	_renderDisabledTextInput = ({ label, value }) => {
		return (
			<div className={formStyles.inputWrapper}>
				<label>
					<p>{label}</p>
					<div>{value}</div>
				</label>
			</div>
		)
	}

	render = () => {
		return (
			<Page
				preFetch={this._preFetch}
				postFetch={this._postFetch}
				render={({ authData }) => {
					const textFieldsWrapperClasses = [styles.textFieldsWrapper]
					const textFieldsToggleButtonClasses = []
					const isEditing = !!this.getProgramId()

					if (
						!authData.account.permissions?.includes('programList') ||
						(!isEditing &&
							!authData.account.permissions?.includes('programManagement'))
					) {
						return <Forbidden />
					}

					if (this.state.displayTextFields) {
						textFieldsWrapperClasses.push(styles.visible)
						textFieldsToggleButtonClasses.push(formStyles.active)
					}

					this.form = this._getForm()

					return (
						<>
							<div className={mainStyles.container}>
								<div className={mainStyles.wrapper}>
									<h3>
										{isEditing
											? `Edit Program #${this.getProgramId()}: ${this.state.inputs.name
											}`
											: this.state.inputs.name
												? `Creating: ${this.state.inputs.name}`
												: 'Create a New Program'}
									</h3>

									<div className={styles.headingWrapper}>
										<div className={styles.leftSide}>
											{this.form.renderInput('name')}
											{this.form.renderInput('shortDescription')}
											{this.form.renderInput('longDescription')}
											{this.form.renderInput('knowledgeArea')}
											{this.form.renderInput('difficulty')}
											{this.form.renderInput('keypoint')}
											{this.form.renderInput('review')}
										</div>

										<div className={styles.rightSide}>
											{this.form.renderInput('cover')}
											{this.form.renderInput('logo')}
											{isEditing
												? this._renderDisabledTextInput({
													label: 'Created At',
													value: FormUtil.formatDate(
														new Date(this._currentProgramData.createdAt)
													)
												})
												: null}
											{isEditing
												? this._renderDisabledTextInput({
													label: 'Updated At',
													value: FormUtil.formatDate(
														new Date(this._currentProgramData.updatedAt)
													)
												})
												: null}
										</div>
									</div>

									{authData.account.permissions?.includes(
										'programManagement'
									) &&
										this.form.renderButton('save', {
											loading: this.state.isSaving
										})}
								</div>
							</div>
						</>
					)
				}}
			/>
		)
	}
}
