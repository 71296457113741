import React, { useState } from 'react'
import mainStyles from '../../assets/css/App.module.scss'
import crmStyles from './Crm.module.scss'
import { Forbidden, Page } from '../../components'
import { CrmOptions } from './CrmOptions'
import { CrmList } from './CrmList'
import { CrmBatch } from './CrmBatch'
import { useLeadDispatch, useLeadState } from '../../contexts/lead'

const Crm = () => {
	const [tab, setTab] = useState('crm')

	const leadState = useLeadState()
	const leadDispatch = useLeadDispatch()

	return (
		<Page
			render={({ authData }) => {
				const hasCrmLeadsPermission =
					authData.account.permissions?.includes('crmLeads')
				const hasCrmEventsTriggeringPermission =
					authData.account.permissions?.includes('crmEventsTriggering')

				if (!hasCrmLeadsPermission && !hasCrmEventsTriggeringPermission) {
					return <Forbidden />
				}

				if (tab === 'crm' && !hasCrmLeadsPermission) {
					if (hasCrmEventsTriggeringPermission) {
						setTab('eventTriggering')
					}
				}

				return (
					<div className={mainStyles.container}>
						<div className={crmStyles.wrapper}>
							<h3>CRM</h3>
							<CrmOptions
								handleChange={event => {
									leadDispatch({
										type: 'update',
										payload: {
											data: {
												...leadState,
												segment: null
											}
										}
									})

									setTab(event)
								}}
								permissions={authData.account.permissions}
								selectedTab={tab}
							/>
							{tab === 'crm' ? (
								<CrmList forbidden={!hasCrmLeadsPermission} />
							) : (
								<CrmBatch forbidden={!hasCrmEventsTriggeringPermission} />
							)}
						</div>
					</div>
				)
			}}
		></Page>
	)
}

export default Crm
