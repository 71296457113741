import { escapeRegExp } from 'lodash'
import { toastMessage } from '../../components'

const HOTMART_CHECKOUT_BASE_URL = 'https://pay.hotmart.com'
const GURU_CHECKOUT_BASE_URL = 'https://clkdmg.site/'
export const VALID_ID_REGEX = /^[0-9]+$/
export const GO_BASE_URL = 'https://go.idiomus.com'
export const LANDING_PAGE_LINK_PREFIX = `${GO_BASE_URL}/app`
export const LINK_TYPE_LIST = [
	{
		id: 'whatsapp',
		label: 'WhatsApp'
	},
	{
		id: 'getresponse',
		label: 'GetResponse'
	},
	{
		id: 'customer-support',
		label: 'Customer Support'
	},
	{
		id: 'traffic-sources',
		label: 'Traffic Sources'
	},
	{
		id: 'funnel-pages',
		label: 'Funnel Pages'
	}
]
export const ACCOUNT_ADD_OPTION_LIST = [
	{ id: 'single', label: 'Single Account', path: '/account/save' },
	{ id: 'bulk', label: 'Bulk Import', path: '/account/bulk-import' }
]
export function copyStringToClipboard(
	str,
	toastMessageText = 'Copied to clipboard!',
	toastMessageType = 'success'
) {
	function copyToClipboard(str) {
		const el = document.createElement('textarea')
		try {
			el.value = str
			el.contentEditable = true // specific to iOS
			el.readOnly = false
			document.body.appendChild(el)
			copyNodeContentsToClipboard(el)
		} catch (e) {
			console.log(e)
		} finally {
			document.body.removeChild(el)
		}
	}

	function copyNodeContentsToClipboard(el) {
		const range = document.createRange()
		const selection = window.getSelection()
		range.selectNodeContents(el)
		selection.removeAllRanges()
		selection.addRange(range)
		el.setSelectionRange(0, 999999)
		document.execCommand('copy')
	}

	try {
		if (navigator.clipboard) {
			try {
				navigator.clipboard.writeText(str)
			} catch {
				//

			}
		} else if (window.clipboardData) {
			// IE
			window.clipboardData.setData('text', str)
		} else {
			// other browsers, iOS, Mac OS
			copyToClipboard(str)
		}
		toastMessage(toastMessageText, toastMessageType)
	} catch {
		//
	}
}

export const VALID_SOURCE_REGEX = /^[a-z]+([a-z0-9-]+)?$/
export function isValidSource(source) {
	return source?.match(VALID_SOURCE_REGEX) && source?.length >= 3
}
export function getTSLandingPageURL(linkState) {
	return `${GO_BASE_URL}${linkState.data?.goUrl.replace(GO_BASE_URL, '')}/${linkState.data?.trafficSource?.value
		}`
}
export function isValidGoUrl(url) {
	return url?.replace(GO_BASE_URL, '').match(/^\/[a-z0-9-/]+[^/]+$/)
}
export function isValidCheckoutUrl(url) {
	const regex = new RegExp(
		`^(${escapeRegExp(HOTMART_CHECKOUT_BASE_URL)}|${escapeRegExp(
			GURU_CHECKOUT_BASE_URL
		)})`
	)

	if (!url?.match(regex)) return false

	const guruRegex = new RegExp(`^${escapeRegExp(GURU_CHECKOUT_BASE_URL)}`)

	return !!url?.match(guruRegex) || !url?.match(/xcod=|bid=/)
}
export function isGoCheckoutUrl(url) {
	return (
		isValidGoUrl(url?.replace(/\/checkout$/, '')) &&
		url?.match(/\/[a-z0-9-/]+\/checkout$/)
	)
}
export function isValidId(value) {
	return value?.match(VALID_ID_REGEX)
}
